$box-shadow-cta: 6px 4px 10px rgba(0, 0, 0, 0.5);

.floating-action-bar {
    display: flex;
    position: fixed;
    width: auto;
    bottom: -100px;
    right: $standard-side-margin;
    left: $standard-side-margin;
    height: auto;
    justify-content: center;
    z-index: 10000;
    transition: bottom 0.3s;
    box-shadow: none;

    @media (min-width: $bp-min-small) {
        right: 1.675rem;
        left: initial;
        justify-content: end;
    }

    &.fab-drop-shadow .floating-action-bar-buttons {
        box-shadow: $box-shadow-cta;
        transition: box-shadow $standard-transition;
    }

    .floating-action-bar-buttons {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: end;
        opacity: 1;
        z-index: 1000;

        .btn--fab {
            padding: 10px 12px;

            @media (min-width: $bp-min-small) {
                padding: 12px 26px;
            }

            .btn-content {
                justify-content: start;
            }

            &:has(.btn-text) {
                padding: 10px 12px;

                @media (min-width: $bp-min-small) {
                    padding: 11px 26px 11px 20px;
                }
            }
        }

        .btn-text {
            font-size: 1rem;
            width: auto;
            overflow: hidden;
            letter-spacing: 1px;
            font-weight: bold;
            font-family: $futura-bold;
            text-align: left;
        }

        .icon-link__icon {
            height: 24px;
            transition: color .3s;
            width: 40px;
        }

        .btn-text--hidden {
            overflow: hidden;
            //transition: all 0.3s;
            width: 0;
        }
    }

    .floating-action-button__drawer-content-inner .btn-content {
        text-align: left;
    }

}

.btn--fab.btn--primary {
    background: linear-gradient(135deg, #ffc600 0%, #ff8f1c 100%);
    border: none;
}

.floating-action-bar__button {
    display: block;
    opacity: 0;

    &.floating-action-button__left {

        .btn--primary {
            background: $drexel-blue;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: $bp-max-xsmall) {
                padding: 0;
            }


            .icon-link__icon {
                color: $white;
                margin-right: 0;
                transform: rotate(0deg);
                transition: transform 0.3s;

                &.icon-link-expanded {
                    transition: transform 0.3s;
                    transform: rotate(-180deg);
                }
            }

            &:before {
                color: $drexel-navy;
            }

            &:hover {
                background: $drexel-navy;
                color: $drexel-gold;
            }
        }
    }


    .btn--primary {
        font-family: $stevie;

        .icon-link__icon {
            color: $drexel-navy;
            margin-bottom: 0;

            &.icon-link__icon--hidden {
                margin: auto;
                //transition: all 0.3s;
            }
        }

        &:hover,
        &:focus,
        &:active {
            border: none;
            color: $white;
            transition: all 0.3s;

            .icon-link__icon {
                color: $white;
            }

            &:before {
                //width: 100%;
                transition: all 0.3s;
                z-index: -1;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        &:before {
            background: linear-gradient(154deg, #6CACE4 0%, #006298 70%);
            content: "";
            border: none;
            transition: all 0.3s;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

    }
}

.floating-action-bar__drawer {
    display: block;
    position: fixed;
    bottom: -400px;

    right: 0;
    background-color: #043963;
    height: fit-content;
    width: 100%;
    transition: all 0.3s;
    z-index: 1000;
    box-shadow: $box-shadow-cta;

    @media (min-width: $bp-min-xsmall) {
        width: 400px;
    }

    .floating-action-bar__drawer-close {
        right: 0;
        display: block;
        position: relative;
        float: right;
        background: none;
        border: none;
        color: white;
    }


    .floating-action-bar__drawer-buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;
        row-gap: 1rem;
        .icon-link__icon svg{
            width: 30px;


        .icon-link__icon {
            min-width: 40px;
            max-width: 40px;

            svg {
                width: auto;
            }
        }
        }

        .btn {
            padding: 10px 18px;
        }

        .btn--fab {

            .btn-content {
                justify-content: start;
            }

            .icon-link__icon {
                color: $drexel-navy;
                transition: color 0.3s;
            }

            .btn-text {
                letter-spacing: 1px;
                text-align: left;
                font-size: 1rem;
            }
        }

        .btn--fab:hover,
        .btn--fab:focus,
        .btn--fab:active {
            color: $white;
            transition: all 0.3s;

            .icon-link__icon {
                color: $white;
            }

            &:before {
                background: linear-gradient(154deg, #6CACE4 0%, #006298 70%);
                color: $white;
                transition: all 0.3s;
            }
        }

        .btn--fab::before {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            color: $white;

            &:hover {
                transition: all 0.3s;
            }
        }

        .btn--secondary {
            background-color: $drexel-blue;
            font-family: $stevie;
            letter-spacing: 2px;
            border: none;

            &:hover,
            &:focus,
            &:active {
                color: $drexel-gold;

                .btn-content,
                .icon-link__icon {
                    color: $drexel-gold;
                    transition: all 0.3s;
                }
            }

            .btn-content {
                justify-content: start;
            }

            .icon-link__icon {
                color: $white;
                transition: all 0.3s;

            }


            &:before {
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background: none;

                &:hover {
                    border: none;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    color: $drexel-gold;


                }
            }

            .icon-link__icon {
                color: $white;
            }
        }
    }

    a:hover {
        color: $drexel-gold;
    }

    .floating-action-button__drawer-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem;

        .floating-action-bar__close {
            color: $white;
            right: 0;
            cursor: pointer;

            svg {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }
}