.utility-navigation {
  @include font(stevie, regular);
  align-items: center;
  display: flex;
  font-size: rem(15);
  line-height: 1.667;

  &:first-child {
    border-left: none;
  }

  button {
    @include font(stevie, regular);
    font-size: rem(15);
  }

  a {
    border-bottom: none;
    font-weight: 500;
  }
}

.utility-navigation__items {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;

  @media (min-width: $bp-min-small-tablet) and (max-width: $bp-max-large) {
    .header--office & {
      align-items: baseline;
      justify-content: flex-end;
      position: relative;
    }
  }
}

.utility-navigation__item {
  border-left-style: solid;
  border-left-width: 1px;
  margin: 0;
  padding: 0 $standard-side-margin;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    padding-right: 0;
  }
}

.utility-navigation__search-flyout {
  display: none;
}
