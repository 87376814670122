.bleed-hero {
  @include componentize-wide();
  @include component-normalize();
  position: relative;

  picture {
    background-color: $black; /* Allow for black bars in IE */
    width: 100%;
  }

  img {
    display: block;
    height: rem(315);
    max-width: none;
    width: auto;
    margin: auto;

    @media (min-width: $bp-min-small) {
      height: rem(399);
    }

    @media (min-width: $bp-min-small-tablet) {
      height: rem(530);
    }

    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    &:after {
      display: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.bleed-hero--administrative {
  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 1s;
    width: rem(161);
    z-index: $stack-internal;

    @media (min-width: $bp-min-xxlarge) {
      width: calc(((100% - #{$max-width-rems}) / 2) + #{rem(140)});
    }
  }

  &.in-play-mode::after {
    width: 0;

    @media (min-width: $bp-min-xxlarge) {
      width: 0;
    }
  }

  &.in-play-mode {
    z-index: $stack-overlaps; /* Don't let things overlap me when in play mode */
  }
}

.bleed-hero--with-color-stripe {
  padding-bottom: rem(54);

  @media (min-width: $bp-min-small-tablet) {
    padding-bottom: rem(54);
  }

  &::after {
    content: "";
    display: block;
    height: rem(98);
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 1s;
    width: 100%;
    z-index: $stack-internal;

    @media (min-width: $bp-min-small) {
      height: rem(230);
    }
  }

  &.in-play-mode::after {
    height: calc(100% - #{rem(260)});

    @media (min-width: $bp-min-small) {
      height: calc(100% - #{rem(399)});
    }

    @media (min-width: $bp-min-small-tablet) {
      height: calc(100% - #{rem(530)});
    }
  }

  @media print {
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */

    &::after {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.bleed-hero__media {
  height: rem(260);
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: $bp-min-small) {
    height: rem(399);

    .bleed-hero--no-image & {
      height: rem(350);
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    height: rem(530);

    .bleed-hero--no-image & {
      height: rem(350);
    }
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.bleed-hero__image {
  background-size: cover;
  background-position: center center;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  transition: height 1s;
  width: 100%;
  z-index: $stack-internal;
}

.bleed-hero__body {
  background-color: $base-box-bg-color;
  padding: $standard-side-margin;
  position: relative;

  display: grid;
  grid-template:
    "top" auto
    "bottom" 1fr /
    1fr;

  text-align: center;
  z-index: $stack-overlaps;

  .bleed-hero--no-image & {
    background-color: transparent;
    margin: auto;
    padding: 0;
    min-height: rem(180); /* same size as tall notch */
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: calc(100% - (#{$standard-side-margin} * 2));
  }

  .page-header--no-notch & {
    min-height: 0;
  }

  .bleed-hero--administrative & {
    bottom: 0;
  }

  @media (min-width: $bp-min-small) {
    .bleed-hero--no-image & {
      left: $standard-side-margin;
      text-align: left;
      transform: translateX(0);
    }

    .bleed-hero--with-color-stripe & {
      background-color: transparent;
    }

    .bleed-hero--administrative & {
      background-color: transparent;
      padding: 0 0 rem(76) 0;
      position: absolute;
      left: $standard-side-margin; /* Needs to match margin */
      text-align: left;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    background-color: transparent;
    padding: 0;

    margin: auto;
    min-height: rem(180); // same size as tall notch
    position: absolute;
    width: auto;
    left: calc(
      (((100% - #{$double-side-margin}) * 33 / 100) - (#{$promo-spacer} / 2)) +
        #{$standard-side-margin} + #{$promo-spacer}
    ); // Needs to match alignment values on page-header
    bottom: 0;
    transform: translateX(0);
    text-align: left;

    .bleed-hero--no-image & {
      width: auto;
    }

    .bleed-hero--with-color-stripe & {
      bottom: rem(54);
    }

    .page-header--no-notch & {
      left: rem(126); // Needs to match alignment values on page-header
    }

    .bleed-hero--administrative & {
      padding-bottom: rem(76);
      left: $standard-side-margin; // Needs to match margin
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    left: calc(
      ((100% - #{$max-width-rems}) / 2) + #{rem(477)}
    ); /* Needs to match alignment values on page-header */

    .page-header--no-notch & {
      left: calc(
        (100% - #{rem(930)}) / 2
      ); /* Needs to match alignment values on page-header */
    }

    .bleed-hero--administrative & {
      left: calc((100% - #{$max-width-rems}) / 2); /* Needs to match margin */
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    min-height: 0 !important;
    padding: 0 !important;
    position: static !important;
    text-align: left !important;
  }
  /* stylelint-enable declaration-no-important */
}

.bleed-hero__body-top {
  grid-area: top;
  position: relative;
  margin-top: rem(-48);

  .bleed-hero--no-image & {
    margin-top: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-top: 0;
  }

  @media print {
    margin-top: 0;
  }
}

.bleed-hero__body-bottom {
  grid-area: bottom;
  align-self: end;
  position: relative;

  // Pull up to overlap if there is no body-top
  &:first-child:last-child {
    margin-top: rem(-48);

    @media (min-width: $bp-min-small-tablet) {
      margin-top: 0;
    }
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.bleed-hero__button {
  @include font("futurab", bold);

  background-color: $black; /* Accessibile placeholders; override in theme file */
  border: none;
  color: $white;
  display: flex;
  font-size: rem(13);
  font-weight: 700;
  letter-spacing: 1.08px;
  line-height: 1;
  margin: rem(29) auto 0;
  padding: rem(11) rem(15);
  text-transform: uppercase;
  transition: background-color 0.3s;
  width: auto;
  z-index: $stack-overlaps;

  .bleed-hero--no-image & {
    transform: translateY(50%);
  }

  .bleed-hero__body-bottom:first-child:last-child & {
    margin: 0 auto;
  }

  .bleed-hero--administrative & {
    margin-top: rem(11);
  }

  @media (min-width: $bp-min-small) {
    margin: rem(29) 0 0;

    .bleed-hero--administrative & {
      transform: translateY(50%);
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    margin: rem(29) 0 0;
    transform: translateY(50%);
  }
}

a.bleed-hero__button {
  display: inline-block;

  &:hover {
    color: $white; /* Accessibile placeholders; override in theme file */
  }
}

.bleed-hero__button-text {
  position: relative;
  top: 1px; /* Account for the fact that allcaps always LOOK off-center, even though they're not */
}

.bleed-hero__title,
.bleed-hero__subtitle {
  background-color: $white; /* Accessibile placeholders; override in theme file */
  color: $black;
}

.bleed-hero__subtitle {
  display: inline-block;
  padding: rem(7) rem(15);

  .wf-loading & {
    @include font("futura-fallback", light);
    font-size: rem(13);
    line-height: 1.67;
    letter-spacing: rem(-0.15);
    word-spacing: rem(0.4);
  }

  .wf-active & {
    @include font("futura", light);
    font-size: rem(17);
    line-height: 1.263;
  }

  @media (min-width: $bp-min-xsmall) {
    .wf-loading & {
      font-size: rem(17);
      line-height: 1.58;
      letter-spacing: rem(-0.5);
      word-spacing: rem(-0.6);
    }

    .wf-active & {
      font-size: rem(21);
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    .wf-loading & {
      font-size: rem(30);
      letter-spacing: rem(-0.85);
      word-spacing: rem(-0.9);
    }

    .wf-active & {
      font-size: rem(38);
    }
  }

  @media print {
    padding: 0;
  }
}

.bleed-hero__title {
  margin-bottom: rem(8);
  padding: rem(7) rem(15);

  &:last-child {
    margin-bottom: 0;
  }

  .wf-loading & {
    @include font("stevie-fallback", bold);
    font-size: rem(25);
    line-height: 1.309;
    letter-spacing: 0;
  }

  .wf-active & {
    @include font("stevie", bold);
    font-size: rem(25);
    line-height: 1.309;
  }

  @media (min-width: $bp-min-xsmall) {
    .wf-loading & {
      font-size: rem(32);
    }

    .wf-active & {
      font-size: rem(32);
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-right: $standard-side-margin;

    .wf-loading & {
      font-size: rem(55);
      line-height: 1.309;
      letter-spacing: 0;
    }

    .wf-active & {
      font-size: rem(55);
    }
  }

  @media print {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

.bleed-hero__video-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  iframe {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Animated Play Button */
.bleed-hero__play {
  display: block;
  flex-shrink: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border-radius: 50%;
  position: relative;
  margin-right: rem(11);
  transition: transform 0.3s;
}

.bleed-hero__play::after,
.bleed-hero__play::before,
.bleed-hero__play .bleed-hero__line {
  transition: height 0.3s, width 0.3s, border-width 0.3s, top 0.3s, left 0.3s,
    transform 0.3s;

  background-color: $black;
  border-radius: 3px;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 8px;
  width: 2px;
  position: absolute;
  top: 4px;
  left: 5px;
  transition: background-color 0.3s;
}

.bleed-hero__play .bleed-hero__triangle {
  border-style: solid;
  border-width: 5px 0 5px 9px;
  border-color: transparent transparent transparent $black;

  opacity: 1;
  position: absolute;
  top: 3px;
  left: 5px;
  transition: opacity 0.2s;
}

.bleed-hero__play::after {
  left: 8px;
  top: 3px;
  transform: rotate(120deg);
}

.bleed-hero__play::before {
  left: 8px;
  top: 6px;
  transform: rotate(60deg);
}

// Video play mode
.bleed-hero.in-play-mode {
  .bleed-hero__play {
    transform: rotate(45deg);
  }
  .bleed-hero__line {
    height: 12px;
    left: 7px;
    top: 2px;
  }
  .bleed-hero__play::after {
    height: 6px;
    transform: rotate(90deg);
    left: 4px;
    top: 5px;
  }
  .bleed-hero__play::before {
    height: 6px;
    transform: rotate(270deg);
    left: 10px;
    top: 5px;
  }
  .bleed-hero__triangle {
    opacity: 0;
  }
}
