.greyblock-callout {
  @include component-normalize();
  @include componentize-wide();

  &:after {
    clear: both;
    content: "";
    display: block;
    height: 0.5px;
  }
}

.greyblock-callout__greyblock {
  background-color: $drexel-grey-pale;

  @media (min-width: $bp-min-small-tablet) {
    padding-right: $standard-side-margin;
    margin-left: calc(
      ((100% - #{$double-side-margin}) * 33 / 100) + (#{$promo-spacer} / 2) + #{$standard-side-margin} -
        #{$promo-spacer}
    );
    margin-right: 0;
  }

  @media (min-width: $bp-min-xxlarge) {
    margin-left: calc(((100% - #{$max-width-rems}) / 2) + #{rem(288)});
  }
}

.greyblock-callout__inner {
  background-color: $drexel-grey-pale;
  padding: $base-component-whitespace $standard-side-margin;

  @media (min-width: $bp-min-small-tablet) {
    padding: $base-component-whitespace $standard-side-margin
      $base-component-whitespace #{$promo-spacer};
  }

  @media (min-width: $bp-min-xxlarge) {
    padding: $base-component-whitespace calc(100% - #{rem(1070)})
      $base-component-whitespace $standard-column-gap-width;
  }
}

.greyblock-callout__title {
  text-transform: uppercase;
  margin-bottom: rem(36);

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(17);
    line-height: 1.698;
    letter-spacing: rem(1.25);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: 1.6px;
  }
}

.greyblock-callout__quote {
  margin-bottom: $standard-side-margin;

  @media (min-width: $bp-min-small-tablet) {
    float: right;
    margin: 0 0 $standard-side-margin $standard-side-margin;
    max-width: rem(450);
    width: 60%;
  }
}

.greyblock-callout__body {
  max-width: rem(810);
}

.greyblock-callout__sidebar {
  margin-bottom: $base-component-mobile-whitespace;

  @media (min-width: $bp-min-small-tablet) {
    float: left;
    margin-bottom: 0;
    margin-left: $standard-side-margin;
    max-width: $standard-sidebar-width;
    width: calc(
      ((100% - #{$double-side-margin}) * 33 / 100) - (#{$promo-spacer} / 2)
    );
  }

  @media (min-width: $bp-min-xxlarge) {
    margin-left: calc((100% - #{$max-width-rems}) / 2);
    width: $standard-sidebar-width;
  }
}
