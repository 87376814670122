.promo-slider {
  overflow: hidden;

  .slick-list {
    padding-left: rem(105);
    padding-right: rem(84);

    @media (min-width: $bp-min-large) {
      padding-left: 0;
      padding-right: 33%;
    }
  }
}

.promo-slider__slide {
  margin-right: $promo-spacer;

  &:last-child {
    margin-right: 0;
  }
}

.promo-slider__slides {
  margin-bottom: rem(42);
  position: relative;

  &:after {
    background-image: linear-gradient(
      to left,
      $white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: -2px; /* Prevent rounding issues*/
    top: 0;
    width: rem(105);
    z-index: $stack-internal;

    @media (min-width: $bp-min-large) {
      width: 33%;
    }
  }

  &:before {
    background-image: linear-gradient(
      to right,
      $white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    left: -2px; /* Prevent rounding issues*/
    top: 0;
    width: rem(105);
    z-index: $stack-internal;

    @media (min-width: $bp-min-large) {
      display: none;
    }
  }

  .container--greyband &:after {
    background-image: linear-gradient(
      to left,
      $greyband-color 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .container--greyband &:before {
    background-image: linear-gradient(
      to right,
      $greyband-color 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .container--navyband &:after {
    background-image: linear-gradient(
      to left,
      $navyband-color 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .container--navyband &:before {
    background-image: linear-gradient(
      to right,
      $navyband-color 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .slick-next,
  .slick-prev {
    color: currentColor;
    height: 100%;
    opacity: 1;
    position: absolute;
    top: 0;
    left: rem(11);
    transform: translate(0);
    transition: opacity 0.3s;
    width: rem(54);
    z-index: $stack-overlaps;

    &:hover,
    &:focus {
      /* Accessible placeholder; override in theme file */
      color: rgba($black, 0.6);
    }

    &.slick-disabled {
      cursor: default;
      opacity: 0;
    }

    &:before {
      display: none;
    }
  }

  .slick-next {
    left: auto;
    right: rem(11);

    svg {
      transform: rotate(180deg);
    }
  }

  .slick-next span,
  .slick-prev span {
    border: 2px solid currentColor;
    border-radius: 50%;
    display: block;
    height: rem(54);
    opacity: 1;
    position: absolute;
    top: calc(50% - #{rem(27)});
    left: 0;
    transition: opacity 0.5s;
    width: rem(54);
    z-index: $stack-overlaps;

    svg {
      display: inline-block;
      height: rem(42);
      position: absolute;
      top: 4px;
      left: 4px;
      width: rem(42);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    &:before,
    &:after {
      display: none;
    }

    .slick-list {
      padding: 0 !important;
      transform: none !important;
      overflow: visible !important;
    }

    .slick-track {
      width: 100% !important;
      opacity: 1 !important;
      transform: none !important;
    }

    .promo-slider__slide {
      width: 100% !important;
      margin-right: 0 !important;
      float: none !important;
      height: auto !important;
      margin-bottom: $print-standard-component-whitespace;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.promo-slider__summary {
  font-size: rem(18);
  line-height: 1.722;
  margin-bottom: rem(39);
}

.promo-slider__button {
  text-align: center;

  @media print {
    text-align: left;
  }
}
