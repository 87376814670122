.secondary-navigation {
  border: 1px solid currentColor;
  border-left: none;
  border-right: none;
  font-size: rem(15);
  line-height: 1.19;
  margin-bottom: rem(27);
  margin-top: rem(21);

  @media (min-width: $bp-min-small-tablet) {
    border: none;
    margin-top: rem(21);
    margin-bottom: 0;
    max-width: rem(250);
  }

  // &.secondary-navigation--tertiary,
  // &.secondary-navigation--subpage {
  //   margin-top: 0;  
  // }

  .skip-link {
    display: inline-block;
    margin-bottom: rem(21);
  }

  a {
    @include font(stevie, light);

    @media (min-width: $bp-min-small-tablet) {
      border-bottom: rem(4) solid transparent;
    }

    &:hover,
    &:focus,
    &.is-active {
      font-weight: 700;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.secondary-navigation__list,
.secondary-navigation__subitems {
  @include zero-list();
}

.secondary-navigation__subitems {
  margin-left: rem(18);

  @media (min-width: $bp-min-small-tablet) {
    margin-top: 0;
  }
}

.secondary-navigation__list {
  @media (min-width: $bp-min-small-tablet) {
    display: block !important;
  }
  .secondary-navigation--subpage & {
    @media (min-width: $bp-min-small-tablet) {
      margin-left: rem(18);
    }
  }
}

.secondary-navigation__item {
  margin: 0;

  a {
    border-bottom: none;
    display: block;
    padding: rem(15) 0; /* Tap space */
  }
  &:last-child {
    margin-bottom: 1rem;
}

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: rem(36);

    a {
      border-bottom: rem(4) solid transparent;
      display: inline-block;
      padding: 0;
    }

    .secondary-navigation--tertiary &,
    .secondary-navigation--subpage & {
      margin-top: 0;  
      margin-bottom: rem(28);
    }

    
  }
}

.secondary-navigation__title {
  display: none;
  margin-bottom: rem(21);

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }

  a {
    display: inline-block;
  }
}

.secondary-navigation__mobile-trigger {
  @include font(stevie, bold);
  background-color: transparent;
  border: none;
  font-size: rem(15);
  line-height: 1.733;
  padding: rem(11) 0;
  text-align: left;
  width: 100%;
  color: $drexel-navy;

  &:after {
    @include verticaltriangle(rem(6), rem(5));

    display: inline-block;
    margin-left: rem(11);
    position: relative;
    top: -2px;
    transform: rotateX(180deg);
    transition: transform 0.3s;
  }

  &.is-open:after {
    transform: rotateX(0deg);
  }

  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }
}
