.event-promo {
  margin: rem(15) 0 rem(50) 0;

  .promo-grid__promos > & {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin: rem(15) 0 0 0;
  }

  picture,
  img {
    height: auto;
    width: 100%;
  }
}

.event-promo__body {
  margin: 0 rem(24);

  .event-promo--uplap & {
    @media (min-width: $bp-min-small-tablet) {
      @include overlap(up, rem(-77));

      margin: 0 0 rem(-77) rem(46);
      padding: rem(21) rem(70) 0 rem(46);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.event-promo__date,
.event-promo__time {
  display: block;
}

.event-promo__date-card {
  @include font(stevie, light);

  font-size: rem(14);
  line-height: 1.571;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: rem(17);
  padding-left: rem(21);
  position: relative;

  &:before {
    content: "";
    display: block;
    height: calc(100% + #{rem(42)});
    position: absolute;
    top: rem(-42);
    left: 0;
    width: 2px;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    padding-left: 0;
    color: $black !important;
    margin-bottom: 0;

    &:before {
      display: none;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.event-promo__title {
  @include font(stevie, semibold);

  display: block;
  font-size: rem(20);
  line-height: 1.5;
  margin-bottom: rem(14);

  &:last-child {
    margin-bottom: 0;
  }

  .event-promo--uplap & {
    @media (min-width: $bp-min-small-tablet) {
      font-size: rem(36);
      line-height: 1.194;
    }
  }

  a {
    border-bottom-color: transparent;
    font-weight: 600;
  }

  @media print {
    a:after {
      display: block;
    }
  }
}

.event-promo__summary {
  @include font(stevie, book);

  font-size: rem(15);
  line-height: 1.733;

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    span {
      font-family: inherit !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.event-promo__location {
  @include font(stevie, light);

  font-size: rem(14);
  line-height: 1.571;
  margin-bottom: rem(14);
  margin-top: rem(-7); /* Offset the title margin */

  &:last-child {
    margin-bottom: 0;
  }
}

.event-promo__image-wrapper {
  border-bottom: rem(4) solid transparent;
  display: block;
  margin-bottom: rem(21);
  overflow: hidden;
  position: relative;
  transition: border-color $standard-transition;

  img {
    display: block;
    transition: transform 0.5s;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover & {
    border-bottom-color: $drexel-gold;

    img {
      transform: scale(1.1, 1.1);
    }
  }

  @media print {
    display: none;
  }
}
