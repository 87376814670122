.color-stripe-promo {
  @include component-normalize();

  @include componentize();

  color: inherit;
  transition: margin 0.2s;
  border-bottom: none;

  img {
    height: auto;
    width: 100%;
  }

  .promo-grid &,
  .stat-mosaic & {
    margin: 0; // prevent weirdness in the CSS grid
  }

  .stat-mosaic & {
    max-width: 100%;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  &.clickable-card--whole-card:hover {
    .color-stripe-promo__image-wrapper {
      border-bottom-color: $drexel-gold;

      img {
        transform: scale(1.1, 1.1);
      }
    }

    .color-stripe-promo__link a {
      color: $drexel-blue;
      border-bottom-color: rgba($drexel-blue, 0.5);
    }
  }
}

.color-stripe-promo__body {
  @media (min-width: $bp-min-small-tablet) {
    @include overlap(margin-up, rem(-63));

    background-color: $base-background-color;

    .container--greyband & {
      background-color: $greyband-color;
    }

    .container--navyband & {
      background-color: $navyband-color;
    }

    @supports (
      clip-path:
        polygon(
          0 0,
          0 100%,
          rem(56) 100%,
          rem(56) calc(100% - #{rem(63)}),
          100% calc(100% - #{rem(63)}),
          100% 0
        )
    ) {
      background-color: transparent;

      .container--greyband &,
      .container--navyband & {
        background-color: transparent;
      }
    }

    padding: rem(32) 0 0 rem(32);
    margin-left: auto;
    width: calc(100% - #{rem(56)});
  }
}

.color-stripe-promo__image-wrapper {
  border-bottom-color: transparent;
  display: block;
  overflow: hidden;
  margin-bottom: rem(21);
  position: relative;

  img {
    transition: transform 0.5s;
  }

  @media (min-width: $bp-min-small-tablet) {
    @supports (
      clip-path:
        polygon(
          0 0,
          0 100%,
          rem(56) 100%,
          rem(56) calc(100% - #{rem(63)}),
          100% calc(100% - #{rem(63)}),
          100% 0
        )
    ) {
      clip-path: polygon(
        0 0,
        0 100%,
        rem(56) 100%,
        rem(56) calc(100% - #{rem(63)}),
        100% calc(100% - #{rem(63)}),
        100% 0
      );
    }
    margin-bottom: 0;
  }

  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: rem(59);

    @media (min-width: $bp-min-small-tablet) {
      width: rem(119);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    max-width: 50%;
    margin-bottom: $print-standard-text-whitespace !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &::after {
      display: none;
    }

    & + .color-stripe-promo__body {
      break-before: avoid-page;
      page-break-before: avoid;
    }

    img {
      transform: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.color-stripe-promo__video-icon svg {
  height: rem(70);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: rem(70);
  z-index: $stack-overlaps;

  @media (min-width: $bp-min-small-tablet) {
    left: rem(24);
    top: rem(18);
    transform: translate(0, 0);
  }
}

.color-stripe-promo__title {
  @extend .txt-h2;
  margin-bottom: rem(9);

  /* stylelint-disable declaration-no-important */
  @media print {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
    color: $black !important;

    & + .color-stripe-promo__summary {
      break-before: avoid-page;
      page-break-before: avoid;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.color-stripe-promo__summary {
  @include font(stevie, book);

  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: rem(21);

  @media print {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin-bottom: $print-standard-text-whitespace;

    & + .color-stripe-promo__link {
      break-before: avoid-page;
      page-break-before: avoid;
    }

    br {
      display: none;
    }
  }
}

.color-stripe-promo__link {
  @include font(stevie, regular);

  font-size: rem(17);
  line-height: 1.471;

  a {
    border-bottom: rem(4) solid transparent;
    color: $base-link-color; /* Rare use of specific color here because this does need to be clearly deliminated from the text; override in theme when necessary */
    font-weight: 700;
    transition: color $standard-transition, border-color $standard-transition;
  }
}
