.animated-hero {
  @include componentize-wide();

  overflow: hidden;
  position: relative;

  img {
    height: auto;
    width: 100%;
  }

  @media print {
    max-width: 100% !important;
    height: 25vh !important;
  }
}

.animated-hero__video {
  padding-bottom: 78.79%;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (min-width: $bp-min-medium) {
    padding-bottom: 45.29%;
  }

  video {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

.animated-hero__video-controls {
  display: inline-block;
  position: absolute;
  top: rem(21);
  right: rem(21);
  z-index: $stack-overlaps;

  @media (min-width: $bp-min-xxlarge) {
    right: calc((100% - #{$max-width}) / 2);
  }
}

.animated-hero__pause {
  background: none;
  border: none;
  opacity: 0.75;

  &.is-paused:after {
    display: none;
  }

  &.is-paused:before {
    background-color: transparent;
    border-style: solid;
    border-width: 20px 0 20px 25px;
    border-color: transparent transparent transparent $white;
    height: 0;
    width: 0;
  }

  &:hover,
  &:focus {
    &:before,
    &:after {
      background-color: $drexel-gold;
      border-color: $drexel-gold;
    }
  }

  &.is-paused:hover:before,
  &.is-paused:focus:before {
    background-color: transparent;
    border-color: transparent transparent transparent $drexel-gold;
  }

  &:before,
  &:after {
    background-color: $white;
    content: "";
    display: inline-block;
    height: rem(42);
    width: rem(14);
  }

  &:after {
    margin-left: rem(7);
  }
}

.animated-hero__overlay {
  color: $base-background-color;
  height: 100%;
  position: absolute;
  bottom: 0%;
  left: 50%;
  opacity: 1;
  transform: translate(-50%, 0);
  width: 100%;

  svg {
    height: 40%; /* IE 11, because IE can't handle auto heights on SVGs */
    /* IE 11 also doesn't do @supports, so anything can go here */
    @supports (height: auto) {
      height: auto; /* All the modern browsers */
    }
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 80%;
    transform: translate(-50%, 0);

    @media print {
      width: 50% !important;
    }

    @media (min-width: $bp-min-small) {
      bottom: -10px;
    }

    @media (min-width: $bp-min-small-tablet) {
      bottom: -15px;
    }
  }
}
