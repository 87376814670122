/* Alerts are a little different from other componets, as color has meaning here, so instead of breaking color definitions out into theme files, we will define them here */

.alert {
  &.alert--warning {
    background-color: $drexel-gold;
    color: $black;

    .btn--primary {
      background-color: $drexel-navy;
      border-color: $drexel-navy;
      color: $white;

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }

  &.alert--danger {
    background-color: $drexel-red-light;
    color: $white;

    .btn--primary {
      background-color: $drexel-navy;
      border-color: $drexel-navy;
      color: $white;

      &:hover,
      &:focus {
        color: $black;
      }
    }
  }
}

.alert__inner {
  @include componentize();
  @include component-normalize();

  margin-bottom: 0;
  padding: rem(60) 0 rem(30);

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
    margin-bottom: 0;
    padding: rem(30) 0;
  }
}

.alert__icon {
  float: left;
  margin-right: rem(12);
  width: rem(36);

  @media (min-width: $bp-min-small-tablet) {
    float: none;
  }

  svg {
    height: rem(36);
    width: rem(36);
  }
}

.alert__title {
  color: inherit;
  margin: rem(3) 0 rem(11);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futurab-fallback, demi);
    font-size: rem(18);
    line-height: 1.6;
    letter-spacing: rem(1.55);
  }

  .wf-active & {
    @include font(futurab, demi);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: rem(1.6);
  }
}

.alert__body {
  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: $base-component-mobile-whitespace;
  max-width: rem(740);

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }
}

.alert__content {
  @media (min-width: $bp-min-small-tablet) {
    border-right: 1px solid $white;
    margin-right: rem(42);
    padding-right: rem(42);
  }
}

.alert__cta {
  align-self: center;

  .btn {
    white-space: nowrap;
  }
}

.alert__close {
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: rem(14);
  padding-left: rem(32);
  position: absolute;
  top: $base-component-mobile-whitespace;
  right: $base-component-mobile-whitespace;
  text-transform: uppercase;

  .wf-loading & {
    @include font(futurab-fallback, demi);
    letter-spacing: rem(0.65);
  }

  .wf-active & {
    @include font(futurab, demi);
    letter-spacing: rem(1.5);
  }

  @media (min-width: $bp-min-small-tablet) {
    position: relative;
    top: auto;
    right: auto;
  }

  &:hover,
  &:focus {
    opacity: 0.75;
  }

  &:before,
  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: rem(21);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:before {
    transform: rotate(45deg);
  }
}

.alert__controls {
  margin-left: rem(42);

  @media (min-width: $bp-min-xlarge) {
    margin-left: auto;
  }
}
