// image-block.scss

// base
.image-block {
  @include component-normalize();

  &.stop-max-width{
    @include componentize();
  }

  picture {
    overflow: hidden;
  }

  .zoom-button {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $white;
    display: none;
    height: rem(63);
    justify-content: center;
    padding: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    width: rem(63);
    transition: color $standard-transition;

    &:before,
    &:after {
      background-color: $white;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      left: rem(20);
      top: rem(26);
      width: rem(16);
      transition: background-color $standard-transition;
    }

    &:before {
      transform: rotate(90deg);
    }

    svg {
      height: rem(42);
      width: rem(42);
    }

    @media (min-width: $bp-min-small) {
      display: flex;
    }
  }

  &.is-zoomed .zoom-button:before {
    transform: rotate(540deg);
  }

  &.image-block--pale-image .zoom-button {
    color: $black;

    &:before,
    &:after {
      background-color: $black;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-text-whitespace !important;
  }
  /* stylelint-enable declaration-no-important */
}

/* These should only occur inside a rich text, so enforce that */
.image-block--float-right,
.image-block--float-left {
  .rich-text & {
    margin-bottom: rem(32);
    transition: margin 0.5s, width 0.5s;
    width: 100%;

    @media (min-width: $bp-min-small) {
      width: 50%;
    }

    /* stylelint-disable declaration-no-important */
    @media print {
      float: none !important;
      max-width: 50%;
      margin-bottom: $print-standard-text-whitespace !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    /* stylelint-enable declaration-no-important */
  }
}

.image-block--float-right {
  .rich-text & {
    @media (min-width: $bp-min-small) {
      float: right;
      margin: 0 0 rem(32) rem(32);
    }

    /* And in the case of page segments, we've got to play some margin games */
    @supports (display: grid) {
      @media (min-width: $overflow-max-width-with-gutters) {
        .container .page-segment & {
          margin-right: rem(-120);
        }
      }
    }
  }
}

.image-block--float-left {
  .rich-text & {
    @media (min-width: $bp-min-small) {
      float: left;
      margin: 0 rem(32) rem(32) 0;
    }

    /* And in the case of page segments, we've got to play some margin games */
    @supports (display: grid) {
      @media (min-width: $bp-min-large) {
        .container--stripe .page-segment & {
          margin-left: -#{$base-component-whitespace};
        }
      }

      @media (min-width: $bp-min-xxlarge) {
        .container .page-segment & {
          margin-left: rem(-120);
        }
      }
    }
  }
}

.image-block--float-left.is-zoomed,
.image-block--float-right.is-zoomed {
  .rich-text & {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    @media (min-width: $bp-min-large) {
      .container--stripe .page-segment & {
        margin-left: -#{$base-component-whitespace};
        margin-right: -#{$base-component-whitespace};
        width: calc(100% + (2 * #{$base-component-whitespace}));
      }
    }

    @media (min-width: $bp-min-xxlarge) {
      .container .page-segment & {
        margin-left: rem(-120);
        margin-right: rem(-120);
        width: calc(100% + #{rem(240)});
      }
    }
  }
}

.image-block__image-wrapper {
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  .page-segment__sidebar .rich-text & {
    margin-right: rem(-60);
  }

  @media print {
    &,
    & ~ .image-block__title,
    & ~ .image-block__caption {
      max-width: 50%;
    }
  }
}

.image-block__zoom-button {
  background: transparent;
  border: 1px solid transparent;
  color: $white;
  display: none; /* We don't offer zoom when we're in single-column mode because the image is already at screen width */
  height: rem(42);
  position: absolute;
  bottom: 0;
  right: 0;
  width: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }

  &.image-block__zoom-button--dark {
    color: $black;
  }

  &:hover,
  &:focus {
    border: 1px dashed currentColor;
  }
}

.image-block__video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.in-play-mode iframe {
    display: block;
  }

  @media print {
    display: none;
  }
}

.image-block__video-overlay {
  background-size: cover;
  background-position: center center;
  border: none;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &:hover svg,
  &:focus svg {
    color: rgba($drexel-grey-text, 0.7);
  }
}

.image-block__video-icon {
  height: rem(57);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: rem(57);

  svg {
    color: rgba($base-background-color, 0.7);
    height: 100%;
    width: 100%;
  }
}

.image-block__caption {
  font-size: rem(14);
  margin-top: rem(17);

  .image-block__title + & {
    margin-top: rem(4);
  }

  @media print {
    font-size: inherit;
    margin-top: $print-standard-text-whitespace;
  }
}

.image-block__title {
  @include font(stevie, regular);

  font-size: rem(17);
  margin-top: rem(17);

  /* stylelint-disable declaration-no-important */
  @media print {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
    font-family: futura-pt, "Lucida Grande", "Lucida Sans Unicode", sans-serif !important;
    font-size: rem(34.83648);
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: calc(2px + 2ex + 2px) !important;
    margin-bottom: 0.65em !important;
    margin-top: $print-standard-component-whitespace !important;
  }
  /* stylelint-enable declaration-no-important */
}
