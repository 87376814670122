.footer-cta {
  border-left-style: solid;
  border-left-width: 1px;
  margin: auto;
  padding-left: rem(11);
  width: rem(120);

  @media (min-width: $bp-footer-min-medium) {
    margin: 0;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    float: none;
    width: auto;
  }

  a {
    border-bottom: none;
  }

  svg {
    height: auto;
    width: rem(110);

    @media (min-width: $bp-footer-min-xlarge) {
      width: rem(170);
    }
  }
}
