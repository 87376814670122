.search-item {
  border-bottom: 1px solid $drexel-grey-soft;
  margin-bottom: rem(39);
  padding-bottom: rem(39);

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media (min-width: $bp-min-small) {
    display: flex;
    justify-content: flex-start;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-text-whitespace !important;
    padding-bottom: $print-standard-text-whitespace !important;
  }
  /* stylelint-enable declaration-no-important */
}

.search-item__content {
  a {
    border-bottom-color: transparent;
    &:hover {
      border-bottom-color: initial;
    }
  }
}

.search-item__title {
  display: inline;
  align-items: baseline;
  font-size: 1.25rem;
  line-height: 0;
  font-weight: 600;

  p {
    margin-bottom: 0;
  }

  br {
    display: none;
  }

  a {
    @include font(stevie, regular);

    border-bottom-color: transparent;
    color: $drexel-grey-headline;
    font-size: rem(20);
    line-height: 1.5;

    &:focus,
    &:hover {
      border-bottom-color: rgba($drexel-grey-text, 0.5);
    }
  }

  @media print {
    margin-top: $print-standard-listitem-whitespace;

    a:after {
      display: block;
    }
  }
}

.search-item__title_bold {
  font-weight: 600;

}

.search-item__eyebrow {
  color: $drexel-grey-light;
  font-size: rem(14);
  letter-spacing: 0.7px;
  line-height: 1.571;
  margin-bottom: .375rem;
  text-transform: uppercase;
  
  &.publication {
    margin-top: .375rem;
  }
}

.search-item__icon {
  width: 1rem;
  display: inline-block;
  align-items: flex-start;
  margin-left: .5rem;
  svg {
    fill:none;
    stroke: $drexel-blue;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:45px;
    path {
      fill: none;
    }
  }
}

.search-item__eyebrow-item {
  border-right: 1px solid $drexel-grey-soft;
  display: inline-block;
  margin-right: 11px;
  padding-right: 11px;

  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.publication a {
  background-color: $drexel-blue;
  display: block;
  width: fit-content;
  margin: .5rem 0;
  padding: 0 .5rem;
  color: white;
  font-weight: bold;
  border-bottom: 0;
}

.search-item__description,
.search-item__url {
  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: rem(11);

  &:last-child {
    margin-bottom: 0;
  }

  a {
    border-bottom-color: transparent;

    &:focus,
    &:hover {
      border-bottom-color: currentColor;
    }
  }

  @media print {
    font-size: inherit;
    line-height: inherit;
  }
}

.search-item__media {
  margin-right: 32px;
  min-width: rem(206);
  width: rem(206);

  @media (max-width: $bp-min-small) {
    width: 100%;
    margin-bottom: 1rem; // added margin for mobile
  }
}

// Featured search items
// Default padding applies if there are only 1 or 2
.search-item--featured {
  background-color: $base-box-bg-color;
  background-color: #f4f4f4;
  border-bottom: none;
  margin-bottom: 5px;
  padding: $standard-side-margin;

  &:last-child {
    border-bottom: none;
    margin-bottom: 5px;
    padding-bottom: 40px;
  }

  @media (min-width: $bp-min-small) {
    padding: $double-side-margin;
  }
}

// Three or more featured search items
.search-item--featured:has(~ .search-item--featured:nth-of-type(3)),
.search-item--featured:nth-of-type(3),
.search-item--featured:nth-of-type(3) ~ .search-item--featured {
  padding: $standard-side-margin;
}

// Adjust spacing back up after the last featured item
.search-item--featured:has(+ *:not(.search-item--featured)) {
  margin-bottom: 45px;
}

// Bigger font size for short descriptions
.search-item__description--short {
  font-size: rem(18);
}

// Smaller spacing between titles and short descriptions
.search-item__title:has(~ .search-item__description--short) {
  margin-bottom: 0;
}
