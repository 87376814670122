.intro-paragraph {
  font-size: rem(20);
  line-height: 1.6;
  margin-bottom: rem(56);

  .wf-loading & {
    @include font(stevie-fallback, light);
    letter-spacing: rem(0.5);
    word-spacing: rem(-1.8);
  }

  .wf-active & {
    @include font(stevie, light);
  }

  @media (min-width: $bp-min-small-tablet) {
    font-size: rem(27);
    line-height: 1.556;

    .wf-loading & {
      letter-spacing: rem(0.65);
      word-spacing: rem(-2.05);
    }
  }

  /* When there's a cta sidebar immediately prior, chop the entire component instead of letting it wrap */
  .floating-cta-sidebar + & {
    @media (min-width: $bp-min-small) {
      max-width: calc(100% - #{rem(282)});
    }

    @media (min-width: $bp-min-small-tablet) {
      max-width: 100%;
    }

    @media (min-width: $bp-min-large) {
      max-width: calc(100% - #{rem(282)});
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: rem(18) !important;
    margin-bottom: $print-standard-text-whitespace;

    &,
    .floating-cta-sidebar + & {
      max-width: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}
