.expert-promo-bio {
  @include componentize();
  @include component-normalize();

  .promo-grid & {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .expert-promo--small + & {
    // Special case to handle single-up expert promo in promo grid
    margin-top: rem(21); 

    & > * {
      max-width: rem(570);
    }
  }

  a {
    border-bottom-color: transparent;

    &:hover,
    &:focus {
      border-bottom-color: rgba($drexel-grey-text, 0.8);
    }
  }
}

.expert-promo-bio__teaser,
.expert-promo-bio__faculty-info {
  font-size: rem(15);
  line-height: rem(29);
}

.expert-promo-bio__faculty-info {
  font-style: italic;
}
