.single-page-hero {
  @include componentize-wide();
  margin-bottom: 0;
  overflow: clip;
  position: sticky;
  z-index: 5;
  width: 100%;
  max-height: 80vh;

  img {
    height: auto;
    width: 100%;
    @media (max-width: $bp-min-medium) {
      //display: none;    
    }
  }

  .single-page-hero__arrow {
    position: absolute;
    z-index: 1;
    bottom: 5%;
    pointer-events: none;
    width: 30px;
    height: 30px;
    right: 8%;
    border: solid white;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    animation: bounce2 5s ease infinite;

  @media (max-width: $bp-min-medium) {
    //right: 5%;
  }
  }
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0) rotate(45deg); }
	40% {transform: translateY(-30px) rotate(45deg);}
	60% {transform: translateY(-15px) rotate(45deg);}
}

.single-page-hero__image {
  height: 100vh;
  display: none;
  background-attachment: fixed;
  @media (max-width: $bp-min-medium) {
    //padding-bottom: 3.75rem;
  }
  img{
    height: 100vh;
  }
}

.single-page-hero__video-wrapper {
  
  height: inherit;
  margin-top:3.5rem;


  @media (min-width: $bp-min-small-tablet) {
    height: 55vh;
    margin-top: auto;
  }

  .single-page-hero__video {
    height: 55vh;
    position: relative;
    overflow: hidden;
    width: 100%;

    @media (min-width: $bp-min-small-tablet) {
      height: 75vh;
    }

    @media (max-width: $bp-min-medium) {
      padding-bottom: 3.75rem;
    }

    video {
      height: 55vh;
      object-fit: cover;
      position: absolute;
      width: 100%;
      @media (min-width: $bp-min-small-tablet) {
        height: 75vh;
      }
    }
  }
}

.single-page-hero__video-controls {
  display: inline-block;
  position: absolute;
  top: 4rem;
  right: rem(21);
  z-index: 200;

  @media (min-width: $bp-min-xxlarge) {
   
    right: calc((100% - #{$max-width}) / 2);
  }
  @media (min-width: $bp-min-small-tablet) {
    top: rem(21);
  }
}

.single-page-hero__pause {
  background: none;
  border: none;
  opacity: 0.75;

  &.is-paused:after {
    display: none;
  }

  &.is-paused:before {
    background-color: transparent;
    border-style: solid;
    border-width: 20px 0 20px 25px;
    border-color: transparent transparent transparent $white;
    height: 0;
    width: 0;
  }

  &:hover,
  &:focus {
    &:before,
    &:after {
      background-color: $drexel-gold;
      border-color: $drexel-gold;
    }
  }

  &.is-paused:hover:before,
  &.is-paused:focus:before {
    background-color: transparent;
    border-color: transparent transparent transparent $drexel-gold;
  }

  &:before,
  &:after {
    background-color: $white;
    content: "";
    display: inline-block;
    height: rem(42);
    width: rem(14);
  }

  &:after {
    margin-left: rem(7);
  }
}

.single-page-hero__solid-background {
  background-color: $drexel-blue;
  height: 50rem;
  @media (max-width: $bp-min-medium) {
    height: fit-content;
  }
}

.single-page-hero__background {
  height: 100%;
  background-attachment: fixed;
  background-size: cover;
  background-position-x: center;

  @media (max-width: $bp-min-small-tablet) {
    background-attachment: initial;
    position: relative;

  }

}

.single-page-hero-sticky {
  display: none;

  &.js-sticky-scroll  {
    @include componentize-fixed();
    @media (max-width: $bp-min-xxlarge) {
      padding: 0 $standard-side-margin;
      margin: 0;
    }
    display: block;
    top: 5.25rem;
    width: 100% !important;
    max-width: 100% !important;
    position: fixed !important;
    background-color: $white;
    z-index: 1;

    @media (max-width: $bp-min-medium) {
      height: auto;
      z-index: 999;
    }

    h1 {
      @include font(stevie, bold);
      text-transform: uppercase;
      color: $drexel-navy;
      margin-bottom: 0;
      padding: 0.8rem 0;
      @media (max-width: $bp-min-medium) {
        font-size: 1.55rem;
      }
    }
  }
}

.single-page-hero__overlay {
  color: $base-background-color;
  height: 100%;
  position: absolute;
  bottom: 0%;
  right: 0;
  opacity: 1;
  width: 100%;
  z-index: 100;


  @media (min-width: $bp-min-small-tablet) {
    position: relative;
  }

  .overlay-background {
    background-color: rgba(255,255,255,.8);
    width: 60%;
    padding: 1rem;
    padding-left: calc((100% - #{$max-width}) / 2);
    position: fixed;
    left: 0;

    @media (max-width: $bp-max-xlarge) {
      padding-left: 1rem;
      width: 75%;
      position: absolute;
      bottom: 30vh;
    }
    @media (max-width: $bp-min-small) {
      top: 85px;
      height: fit-content;
      width: 100%;
      background-color: rgb(255, 255, 255);
    }

    .sps-heading-primary {
      position: relative;
    }

    h1 {
      @include font(stevie, bold);
      font-size: 1.2rem;
      margin-bottom: 0;
      text-transform: uppercase;
      color: $drexel-navy;
      text-align: left;

      @media (min-width: $bp-min-small-tablet) {
        font-size: 2.8rem;
      }

      @media (min-width: $bp-min-medium) {
        font-size: 4rem;
      }

      // @media (min-width: $bp-min-small) {
      //   text-align: left;
      // }
    }

    .sps_heading_secondary span {
      @include font(stevie, bold);
      font-size: 38px;
      font-weight: 700;
      color: $drexel-navy;
      margin-bottom: 0;
      display: block;

      @media (max-width: $bp-min-small-tablet) {
        font-size: 1.5rem;
      }

      @media (max-width: $bp-min-small) {
        display: none;
      }
    }
  }

  svg {
    height: 40%; /* IE 11, because IE can't handle auto heights on SVGs */
    /* IE 11 also doesn't do @supports, so anything can go here */
    @supports (height: auto) {
      height: auto; /* All the modern browsers */
    }
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 80%;
    transform: translate(-50%, 0);

    @media (min-width: $bp-min-small) {
      bottom: -10px;
    }

    @media (min-width: $bp-min-small-tablet) {
      bottom: -15px;
    }
  }
}

.mobile-overlay-background {

  display: flex;
  height: fit-content;
  //background: linear-gradient(154deg, rgba(7, 41, 77, 0.8) 0%, rgba(0, 99, 152, 0.8) 70%);
  background-color: rgba(255,255,255,.8);
    padding: .5rem $standard-side-margin;
  position: absolute;
  top:  0;
    width: 100%;

  .sub-heading {
    @include font(stevie, bold);
    font-weight: 500;
    color: $drexel-navy;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.3;
    text-align: left;
  }

  @media (min-width: $bp-min-small-tablet) {
    display: none;


  }
}