.search-box {
  padding: 0 rem(15);
  position: relative;
  margin-bottom: rem(50); // to make room for the clearing X on mobile

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }

  @media print {
    margin-bottom: $print-standard-text-whitespace;
  }
}

.search-box__button {
  background-color: $base-background-color;
  border: none;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  &:active,
  &:focus {
    box-shadow: none;
  }
}

.search-box__input {
  font-size: rem(15);
  height: rem(50);
  line-height: rem(50);
  position: relative;

  button {
    outline: none;
  }

  label {
    @include font(stevie, light);

    font-size: rem(15);
    letter-spacing: normal;
    line-height: rem(50);
    margin-left: 0;
    position: absolute;
    top: 0;
    left: rem(11);
    text-transform: none;
    transition: color 0.5s, top 0.5s;
  }

  input {
    background-color: $base-background-color;
    border: none;
    display: block;
    height: rem(50);
    line-height: rem(50);
    outline: none;
    padding: 0 rem(11);
    width: 100%;
    border-radius: 0;

    &:active,
    &:focus {
      box-shadow: none;
    }

    // To remove the "X" from the field in IE11 on focus
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }

  &.is-dirty label,
  button:focus ~ label,
  input:focus ~ label {
    color: currentColor;
    top: rem(-50);

    @media print {
      display: none;
    }
  }
}

.search-box__clear {
  background-color: transparent;
  border: none;
  display: block;
  font-size: rem(42);
  height: rem(50);
  line-height: rem(50);
  min-width: 1.05em;
  padding: 0;
  text-align: center;
  position: absolute;
  top: 100%;
  right: rem(12);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s;

  .is-dirty ~ & {
    opacity: 1;
    visibility: visible;

    @media print {
      display: none;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    height: rem(50);
    line-height: rem(50);
    margin-left: 0.25em;
    top: 0;
    left: 100%;
    right: auto;
  }
}
