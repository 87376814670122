.page-title {
  @include componentize();

  // If there are only breadcrumbs, add breathing room so the title doesn't sit right beanath
  .breadcrumbs + & {
    margin-top: $base-component-mobile-whitespace;
  }

  /* If this is the first child of the first container, or if only share (and go-back) precedes it, we need to give it breathing space up top, because the header doesn't have any otherwise */
  .container:first-child > &:first-child,
  .container:first-child .share + &,
  .breadcrumbs + .share + & {
    margin-top: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: $base-component-whitespace;
    }
  }

  // If it follows a share, give it padding on the right so it won't collide
  .share + &,
  .share + .go-back + & {
    padding-right: $share-component-spacer;
  }

  .container:first-child .share + .go-back + & {
    margin-top: 0;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: $base-component-whitespace;
    }
  }

  &.page-title--with-image {
    @include componentize-wide();

    margin-bottom: calc(#{$base-component-whitespace} + #{rem(21)});
    margin-top: -#{$base-component-mobile-whitespace};
    position: relative;

    @media (min-width: $bp-min-xsmall) {
      margin-bottom: calc(#{$base-component-whitespace} + #{rem(42)});
    }

    @media (min-width: $bp-min-small-tablet) {
      margin-top: -#{$base-component-whitespace};
      margin-bottom: calc(#{$base-component-whitespace} + #{rem(63)});
    }

    &:after {
      content: "";
      display: block;
      height: rem(32);
      position: absolute;
      bottom: rem(-21);
      left: 0;
      width: 100%;

      @media (min-width: $bp-min-xsmall) {
        bottom: rem(-42);
        height: rem(63);
      }

      @media (min-width: $bp-min-small-tablet) {
        bottom: rem(-63);
        height: rem(180);
      }
    }

    picture {
      background-color: $black; /* Allow for black bars in IE */
      width: 100%;
    }

    img {
      display: block;
      height: rem(315);
      max-width: none;
      width: auto;
      margin: auto;

      @media (min-width: $bp-min-small-tablet) {
        height: rem(530);
      }

      @supports (object-fit: cover) {
        object-fit: cover;
        width: 100%;
      }
    }

    h1 {
      @include overlap(down, rem(-42));
      background-color: white;
      display: inline-block;
      margin-bottom: 0;
      padding: 0 rem(123) rem(36) 0;
      position: relative;

      @media (min-width: $bp-min-xxlarge) {
        padding-left: calc((100% - 1400px) / 2);
      }

      span.page-title__title-wrapper {
        display: block;
        padding-left: rem(42);
        position: relative;
        width: auto;

        &:before {
          content: "";
          display: block;
          height: calc(100% + #{rem(42)});
          position: absolute;
          left: rem(20);
          top: rem(14);
          width: rem(4);

          @media (min-width: $bp-min-xxlarge) {
            left: 0;
          }
        }
      }
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    // .breadcrumbs + & {
    //   margin-top: $print-standard-text-whitespace;
    // }

    margin-top: 0 !important;

    &.visually-hidden {
      position: static !important;
      clip: auto;
      overflow: visible;
      height: auto;
      width: auto;
    }

    .page-title__image,
    &:after {
      display: none !important;
    }

    // Has to be separate or Firefox will ignore the other rules
    & h1:has(.page-title__title-wrapper:empty) {
      display: none !important;
    }

    h1 {
      bottom: auto !important;
      margin-bottom: 0 !important;
    }

    .page-title__title-wrapper {
      padding-left: 0 !important;

      &:before {
        display: none !important;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.page-title__intro {
  max-width: rem(810);
  font-size: rem(27);
  line-height: 1.556;

  @media print {
    font-size: rem(18);
    max-width: none;
  }
}
