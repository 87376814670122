.program-listing {
  @include componentize();

  position: relative;
}

.program-listing__app {
  max-width: 100%;
}

.program-listing__rule {
  margin-top: rem(25);
  padding-bottom: rem(44);
  position: relative;

  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.program-listing__facets {
  margin-bottom: rem(28);
  position: sticky;
  top: rem(21);
  z-index: $stack-overlaps;

  & > div {
    // Another Reacty div wrapper here.
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: $bp-min-medium) {
    margin-bottom: 0;
    max-height: none;
    position: static;
    z-index: $stack-reset;
  }
}
