.slideshow-video {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slideshow-video__container {
  background-color: black;
  height: rem(315);
  width: 100%;

  @media(min-width: $bp-min-small-tablet) {
    height: rem(530);
  }

  picture {
    width: 100%;
  }

  img {
    display: block;
    height: rem(315);
    max-width: none;
    width: auto;
    margin: auto;

    @media(min-width: $bp-min-small-tablet) {
      height: rem(530);
    }

    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }
  }
}

.slideshow-video__caption {
  @include component-normalize();
  @include componentize();

  font-size: rem(14);
  line-height: 1.714;

  margin-bottom: rem(21);
  margin-top: rem(21);
}

.slideshow-video__video-wrapper {
  height: rem(315);
  position: absolute;
    top: 0;
    left: 0;
  width: 100%;

  @media(min-width: $bp-min-small-tablet) {
    height: rem(530);
  }

  iframe {
    display: none;
    position: absolute;
      top: 0;
      left: 0;
    width: 100%;
    height: rem(315);

    @media(min-width: $bp-min-small-tablet) {
      height: rem(530);
    }
  }
}

.slideshow-video__image {
  position: relative;
   z-index: $stack-overlaps;
}

.slideshow-video__button {
  background-color: transparent;
  border: none;
  color: rgba($white, 0.8); /* Accessibile placeholder; override in theme file */
  align-items: center;
  height: rem(315);
  display: flex;
  justify-content: center;
  position: absolute;
    top: 0;
    left: 0;
  width: 100%;
  z-index: $stack-overlaps;

  @media(min-width: $bp-min-small-tablet) {
   height: rem(530);
  }

  svg {
    height: rem(120);
    width: rem(120);
  }
}
