.floating-cta-sidebar {
  float: right;
  font-size: rem(15);
  margin-left: rem(42);
  width: 100%;

  @media (min-width: $bp-min-small) {
    max-width: rem(240);
  }

  @media (min-width: $bp-min-small-tablet) {
    max-width: 100%;
  }

  @media (min-width: $bp-min-large) {
    max-width: rem(240);
  }

  p {
    font-size: rem(15);
    margin-bottom: rem(11);

    &:last-child {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-bottom: rem(21);
    margin-right: rem(5);
  }

  @media print {
    float: none;
    font-size: inherit;
    margin-left: 0;
    margin-bottom: $print-standard-text-whitespace;
    max-width: none;

    p {
      font-size: inherit;
      margin-bottom: 0 !important;
    }
  }
}
