.sticky-footer {
  align-items: center;
  display: flex;
  height: $footer-height-mobile;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: $stack-menu;

  &.sticky-footer--small {
    display: inline-block;
    width: auto;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    height: $footer-height;
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  a {
    border-bottom: none;
  }
}

.sticky-footer__buttons {
  padding: rem(50) rem(15) rem(15);
  width: 100%;

  @media (min-width: $bp-footer-min-small) {
    align-items: center;
    display: flex;
    height: $footer-height-mobile;
    justify-content: space-between;
    padding: 0;
  }

  @media (min-width: $bp-footer-min-medium) {
    margin-left: auto;
    width: auto;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    height: $footer-height;
  }

  .icon-link {
    display: block;
    flex-grow: 3;
    flex-shrink: 0.5;
    font-size: rem(13);
    margin: rem(15) auto;
    padding: rem(15);

    @media (min-width: $bp-footer-min-small) {
      align-items: center;
      border-width: 1px;
      border-style: solid;
      display: flex;
      height: $footer-height-mobile;
      justify-content: center;
      margin: 0;
      padding: rem(14);
    }
    @media (min-width: $bp-footer-min-medium) {
      width: auto;
    }

    @media (min-width: $bp-footer-min-xlarge) {
      height: $footer-height;
    }
  }

  .icon-link__text {
    line-height: 1.2;
  }

  .icon-link__icon,
  .icon-link__icon svg {
    height: rem(21);
    width: rem(21);

    /* Hide the icons */
    @media (min-width: $bp-footer-min-small) {
      display: none;
      height: rem(24);
      width: rem(24);
    }

    @media (min-width: $bp-footer-min-medium) {
      display: block;
    }
  }
}

.sticky-footer__buttons,
.sticky-footer__give {
  @include font(stevie, regular);

  font-size: rem(13);
  line-height: 2.0625;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}

.sticky-footer__connect {
  align-items: center;
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  height: $footer-height-mobile;
  justify-content: space-around;
  letter-spacing: 1.5px;
  padding: 0 rem(11);
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  .sticky-footer--small & {
    border-right: none;
  }

  @media (min-width: $bp-footer-min-small) {
    border-right: none;
    min-width: rem(134);
    width: auto;
  }

  @media (min-width: $bp-footer-min-medium) {
    width: auto;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    border-right: none;
    height: $footer-height;
    min-width: rem(84);
  }

  .icon-link {
    transition: none;
    display: flex;
    width: 100%;
    justify-content: center;


    .wf-loading & {
      @include font(futurac-fallback, book);
      font-size: rem(11);
      line-height: 2.145;
      letter-spacing: rem(1.05);
    }

    .wf-active & {
      @include font(futurac, book);
      font-size: rem(13);
    }

    @media (min-width: $bp-footer-min-medium) {
      .wf-loading & {
        font-size: rem(14);
        line-height: 2.458;
        letter-spacing: rem(1.3);
      }

      .wf-active & {
        font-size: rem(18);
      }
    }

    @media (min-width: $bp-footer-min-xlarge) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
    }
  }

  .icon-link__icon,
  .icon-link__text {
    @media (min-width: $bp-footer-min-xlarge) {
      display: block;
      margin: 0;
      text-align: center;
    }
  }

  .icon-link__icon,
  .icon-link__icon svg {
    height: rem(21);
    width: rem(21);

    @media (min-width: $bp-footer-min-medium) {
      height: rem(24);
      width: rem(24);
    }

    @media (min-width: $bp-footer-min-xlarge) {
      height: rem(30);
      width: rem(30);
    }
  }

  .icon-link__icon svg {
    @media (min-width: $bp-footer-min-xlarge) {
      margin-bottom: rem(4);
    }
  }
}

.sticky-footer__give {
  align-items: center;
  display: flex;
  height: $footer-height-mobile;
  width: 100%;

  @media (min-width: $bp-footer-min-small) {
    min-width: rem(153);
    width: auto;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    height: $footer-height;
    min-width: rem(174);
  }

  .icon-link {
    align-items: center;
    border-width: 1px;
    border-style: solid;
    display: flex;
    font-size: rem(13);
    height: $footer-height-mobile;
    line-height: 1.2;
    justify-content: center;
    margin: 0;
    padding: rem(14);
    width: 100%;
    transition: none;

    @media (min-width: $bp-footer-min-xlarge) {
      height: $footer-height;
    }
  }

  .icon-link__text {
    line-height: 1.2;
  }

  .icon-link__icon,
  .icon-link__icon svg {
    height: rem(21);
    width: rem(21);

    @media (min-width: $bp-footer-min-xlarge) {
      height: rem(30);
      width: rem(30);
    }
  }
}

.sticky-footer__menu {
  min-width: rem(126);
  width: 100%;
}

.sticky-footer__menu-body {
  box-shadow: 0 -2px 9px rgba(0, 0, 0, 0.25);
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;

  @media (min-width: $bp-footer-min-small) {
    align-items: center;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    height: $footer-height-mobile;
    position: static;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    height: $footer-height;
  }
}

.sticky-footer__menu-close {
  @include font(futura, demi);

  border: none;
  font-size: rem(14);
  letter-spacing: 1.49px;
  outline: none;
  position: absolute;
  top: rem(16);
  right: rem(17);
  text-transform: uppercase;

  @media (min-width: $bp-footer-min-small) {
    display: none;
  }

  svg {
    display: inline-block;
    position: relative;
    top: 7px;
  }
}

.sticky-footer__menu-trigger {
  align-items: center;
  background-color: transparent;
  border: none;
  display: flex;
  height: $footer-height-mobile;
  justify-content: center;
  padding: 0 rem(11);
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  .wf-loading & {
    @include font(futurac-fallback, book);
    font-size: rem(11);
    line-height: 1.331;
    letter-spacing: rem(0.95);
  }

  .wf-active & {
    @include font(futurac, book);
    font-size: rem(13);
    line-height: 1.1;
    letter-spacing: rem(1.5);
  }

  @media (min-width: $bp-footer-min-small) {
    display: none;
  }

  @media (min-width: $bp-footer-min-medium) {
    font-size: rem(18);
  }

  @media (min-width: $bp-footer-min-xlarge) {
    height: $footer-height;
  }

  &.is-open {
    .sticky-footer__menu-trigger-icon {
      transform: rotateX(180deg);
    }
  }

  .sticky-footer__menu-trigger-icon {
    margin-right: rem(14);
    transition: transform 0.5s;
  }
}
