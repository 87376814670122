// parallax-scroll-cta.scss

// base
.parallax-scroll-cta {
  @include component-normalize();

    picture {
      overflow: hidden;
    }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-text-whitespace !important;
  }
  .parallax-scroll-cta__image-wrapper img{
    display: none;
  }
  /* stylelint-enable declaration-no-important */
}

.parallax-scroll-cta__text-block {
  display: flex;
  width: 60%;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .btn--primary {
    color: $white;
    margin: 2rem 0;
  }
  @media (max-width: $bp-min-small-tablet) {
    width: 85%;
  }
}

.parallax-scroll-cta__image-wrapper {
  //background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://fastly.picsum.photos/id/10/2500/1667.jpg?hmac=J04WWC_ebchx3WwzbM-Z4_KC_LeLBWr5LZMaAkWkF68);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg);
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  min-height: 600px;
  background-position: 50%;
  background-size: cover;
  color: #fff;
  background-repeat: no-repeat;
  padding: 3.5rem 0;

  img, .parallax-scroll-cta_image-for-parallax{
    display: none;
  }

  .page-segment__sidebar .rich-text & {
    margin-right: rem(-60);
  }

  @media print {
    &,
    & ~ .parallax-scroll-cta__title,
    & ~ .parallax-scroll-cta__caption {
      max-width: 50%;
    }
  }
}

.parallax-scroll-cta__caption {
  font-family: $stevie;
  font-size: 1.5rem;
  padding: 0;
  text-align: center;
  color: $white;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
  font-weight: 500;
  .parallax-scroll-cta__title + & {
    margin-top: rem(4);
  }

  @media print {
    font-size: inherit;
    margin-top: $print-standard-text-whitespace;
  }
  @media (max-width: $bp-min-small-tablet) {
    margin-top: 0;
  }
}

.dividing-line {
  width: inherit;
  border-bottom: 3px solid $white;
  margin: 2rem 0;
}

.parallax-scroll-cta__title {
  font-family: $futura-bold;
  font-size: 3rem;
  line-height: 3rem;
  padding: 0 2rem 0 2rem;
  width: fit-content;
  color: $white;
  text-align: center;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
  @media (max-width: $bp-min-small-tablet) {
    line-height: 1;
    font-size: 2.5rem;
    padding-top: 0;
    margin-top: 0;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
    font-family: futura-pt, "Lucida Grande", "Lucida Sans Unicode", sans-serif !important;
    font-size: rem(34.83648);
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: calc(2px + 2ex + 2px) !important;
    margin-bottom: 0.65em !important;
    margin-top: $print-standard-component-whitespace !important;
  }
  /* stylelint-enable declaration-no-important */
}
//Sitecore Editor Styles
.is-page-editor .parallax-scroll-cta__image-wrapper .parallax-scroll-cta_image-for-parallax,
.parallax-scroll-cta__image-wrapper img {
  display: none !important;
}