.related-event {
  color: inherit;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(32);
  transition: color 0s;

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
    min-height: rem(250);
  }

  :not(.layout-columns) > & {
    border-bottom: none;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  &.clickable-card--whole-card:hover {
    color: inherit;

    :not(.layout-columns) > & {
      border: 1px solid currentColor; /* For accessibility purposes; turn off in theme if you add other hover states */
    }
  }

  @media print {
    display: block;
    min-height: 0;
    padding: 0;
    margin-bottom: $print-standard-component-whitespace;
  }
}

.related-event__date,
.related-event__time,
.related-event__date-card,
.related-event__title {
  display: block;
}

.related-event__date-card {
  @include font(stevie, light);

  font-size: rem(14);
  line-height: 1.571;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: rem(32);
  padding-left: $standard-side-margin;
  position: relative;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: calc(100% + #{rem(32)});
    position: absolute;
    top: rem(-32);
    left: 0;
    width: 2px;
  }

  @media print {
    border-left: none;
    line-height: inherit;
    padding-left: 0;
    margin-bottom: 0;
    width: auto;

    &:before {
      display: none;
    }
  }
}

.related-event__title {
  margin-top: auto;
}

.related-event__link {
  @include font(stevie, regular);
  border-bottom: rem(4) solid transparent;
  font-weight: 600;
  font-size: rem(20);
  line-height: 1.5;
  color: inherit;

  // Only add these hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card &:hover {
    color: inherit;
    border-bottom: rem(4) solid transparent;
  }

  @media print {
    &:after {
      display: block;
    }
  }
}

.related-event__location {
  @include font(stevie, light);

  font-size: rem(14);
  line-height: 1.571;
  margin-top: 0;
}
