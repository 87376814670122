@import "drexel/base/_functions.scss";

.dynamic-video-grid {
  @include containerize();
  @include component-normalize();

  .search-listing__app {
    display: block;
  }

  .search-listing__facets {
    padding: rem(21);
    background-color: $drexel-grey-pale;

    @media (min-width: $bp-min-large) {
      padding: 0;
      width: rem(206);
      background-color: transparent;
    }

    &:has(.search-listing__facets--no-results) {
      display: none;
    }
  }

  .search-listing__rule {
    padding-bottom: rem(18);
    margin-top: rem(40);

    @media (min-width: $bp-min-large) {
      margin-top: 0;
    }

    &::after {
      @media (min-width: $bp-min-medium) {
        width: 100%;
      }
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    .search-results__items {
      margin-bottom: 0;
    }
  }

  @media print {
    display: none;
  }
}
