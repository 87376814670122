.effect-flash-in {
  animation: flashin 750ms 1;
  transform-origin: top center;
}

@keyframes flashin {
  0% {
    transform: scale(0.95);
    opacity: 0.1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
