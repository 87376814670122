// Default slideshow, for use as a full-bleed design element
.slideshow {
  @include componentize-wide();
  @include component-normalize();

  overflow: hidden;
  position: relative;
  width: 100%;
}

.slideshow__slider {
  max-width: 100%;
  width: 100%;
}

.slideshow__slide {
  width: 100%;
}

.slideshow__nav {
  background-color: $white; /* Accessible placeholder; override in theme file */
  padding: rem(21) rem(21) 0;
  text-align: center;

  @media (min-width: $bp-min-small) {
    position: absolute;
    /* Needs to match height set on images and videos + 1px to avoid rounding errors */
    top: calc(#{rem(315)} + 1px);
    right: 0;
    transform: translate(0, -100%);
    padding: rem(21) rem(63) 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    /* Needs to match height set on images and videos + 1px to avoid rounding errors */
    top: calc(#{rem(530)} + 1px);
  }

  @media (min-width: $bp-min-xxlarge) {
    padding: rem(21) calc((100% - #{$max-width-rems}) / 2) 0 rem(63); /* right padding should match calculation for margins elsewhere */
  }

  & > * {
    color: $black; /* Accessible placeholder; override in theme file */
    position: static;
    transform: none;

    &:before,
    &:after {
      display: none;
    }
  }

  .slick-next,
  .slick-prev {
    border: 2px solid currentColor;
    border-radius: 50%;
    display: inline-block;
    height: rem(54);
    position: relative;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: rem(54);
    transition: color $standard-transition;

    &:hover,
    &:focus {
      /* Accessible placeholder; override in theme file */
      color: rgba($black, 0.6);
    }

    svg {
      display: inline-block;
      height: rem(42);
      position: absolute;
      top: 4px;
      left: 4px;
      width: rem(42);
    }
  }

  .slick-dots {
    @include zero-list();

    display: none;
    margin: 0 rem(16);
    width: calc(100% - 140px);

    @media (min-width: $bp-min-small) {
      display: inline-block;
      height: rem(54);
      width: auto;
    }

    li {
      @include zero();

      height: auto;
      position: static;
      width: auto;
    }

    button {
      align-items: center;
      /* Accessible placeholder; override in theme file */
      background-color: rgba($black, 0.5);
      border-radius: 50%;
      height: rem(16);
      margin: rem(19) rem(16);
      padding: 0;
      position: static;
      width: rem(16);
      transition: background-color $standard-transition;

      &:before {
        display: none;
      }

      &:hover,
      &:focus {
        /* Accessible placeholder; override in theme file */
        background-color: rgba($black, 0.75);
      }
    }

    .slick-active button {
      background-color: $black; /* Accessible placeholder; override in theme file */

      &:hover,
      &:focus {
        /* Accessible placeholder; override in theme file */
        background-color: rgba($black, 0.75);
      }
    }
  }

  .slick-prev {
    float: left;
  }

  .slick-next {
    float: right;
    transform: rotate(180deg);
  }
}

// Constrained version, for use in columns
// TODO: Create a demo of how you could customize the aspect-ratio from content authoring
.slideshow--constrained {
  --aspect-ratio: 16 / 9;

  @include componentize;

  .layout-columns & {
    max-width: 100%;
  }

  .slideshow-video__container {
    position: relative;
  }

  .slideshow-video__container,
  .slideshow-image__container,
  .slideshow-video__video-wrapper {
    height: auto;
  }

  .slideshow-image__container img,
  .slideshow-video__container,
  .slideshow-video__container img,
  .slideshow-video__container .slideshow-video__button,
  .slideshow-video__container iframe,
  .slideshow-video__video-wrapper {
    height: auto;
    max-width: 100%;
    width: 100%;
    aspect-ratio: var(--aspect-ratio, 16 / 9);
  }

  .slideshow-image__container img,
  .slideshow-video__container img {
    object-position: 50% 50%; // Center the image, in case of any unexpected cropping
  }

  .slideshow-video__image {
    height: 100%; // needed for the video curtain effect
  }

  .slideshow__nav {
    padding: rem(21) 21px 0;

    @media print {
      display: none;
    }
  }

  .slideshow-image__caption,
  .slideshow-video__caption {
    max-width: 80ch;
    margin-left: 0;
  }

  @media (min-width: $bp-min-small) {
    // New styles for overlapping the slideshow nav in constrained slideshows
    // Default for 100% width column
    .slideshow__nav-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      pointer-events: none;

      // Since we may allow for different image ratios to be set,
      // we need a different way to position this relative to the image height.
      // Using a pseudo-element for positioning:
      &:before {
        display: block;
        content: "";
        border-bottom: 1px solid transparent; // To avoid pixel rounding gaps
        aspect-ratio: var(--aspect-ratio, 16 / 9);
      }
    }

    .slideshow__nav {
      padding: rem(21) 2px 0 23px;
      top: auto;
      bottom: 0;
      transform: none;
      pointer-events: auto;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    // Undo the overlap styles if we're in a 70% column, as it's too narrow.
    // Lots of repetition, but no way to avoid it unfortunately.
    .layout-columns--30-70 & {
      .slideshow__nav-wrapper {
        position: static;

        &:before {
          display: none;
        }
      }

      .slideshow__nav {
        /* Needs to match height set on images and videos + 1px to avoid rounding errors */
        position: static;
        transform: none;
        padding: rem(21) 21px 0;
      }

      .slick-dotted {
        margin-bottom: 0; // TODO: may need to reinstate this to 30px again wider?
      }
    }
  }

  @media (min-width: $bp-min-medium) {
    // Redo the overlap styles
    .layout-columns--30-70 & {
      .slideshow__nav-wrapper {
        position: absolute;

        &:before {
          display: block;
        }
      }

      .slideshow__nav {
        position: absolute;
        padding: rem(21) 2px 0 23px;
      }

      .slick-dots {
        display: inline-block;
      }
    }
  }
}
