.hero {
  @include componentize-wide();
  @include component-normalize();

  position: relative;

  img {
    height: auto;
    width: 100%;
  }

  &.hero--with-notch {
    @media (min-width: $bp-min-small-tablet) {
      margin-top: -#{$base-component-whitespace};
    }
  }

  &.hero--hide-mobile {
    display: none;

    @media (min-width: $bp-min-small-tablet) {
      display: block;
    }
  }

  &.hero--height-locked {
    width: 100%;

    picture {
      background-color: $black; /* Allow for black bars in IE */
      width: 100%;
    }

    img {
      display: block;
      height: rem(315);
      max-width: none;
      width: auto;
      margin: auto;

      @media (min-width: $bp-min-small-tablet) {
        height: rem(530);
      }

      @supports (object-fit: cover) {
        object-fit: cover;
        width: 100%;
      }
    }
  }
}

.hero--with-color-stripe {
  &:after {
    content: "";
    display: block;
    height: rem(180);
    position: absolute;
    bottom: rem(-63);
    left: 0;
    width: 100%;
  }
}

.hero__image {
  .hero.hero--with-notch & {
    @media (min-width: $bp-min-small-tablet) {
      @supports (
        clip-path:
          polygon(
            calc(#{$standard-side-margin} + (67% - (#{$promo-spacer} / 2))) 0,
            calc(#{$standard-side-margin} + (67% - (#{$promo-spacer} / 2))) #{$base-component-whitespace},
            0 #{$base-component-whitespace},
            0 100%,
            100% 100%,
            100% 0
          )
      ) {
        clip-path: polygon(
          calc(#{$standard-side-margin} + (67% - (#{$promo-spacer} / 2))) 0,
          calc(#{$standard-side-margin} + (67% - (#{$promo-spacer} / 2))) #{$base-component-whitespace},
          0 #{$base-component-whitespace},
          0 100%,
          100% 100%,
          100% 0
        );
      }
    }

    @media (min-width: $bp-min-xxlarge) {
      @supports (
        clip-path:
          polygon(
            calc(((100% - #{$max-width}) / 2) + #{rem(924)} + #{$promo-spacer})
              0,
            calc(((100% - #{$max-width}) / 2) + #{rem(924)} + #{$promo-spacer})
              #{$base-component-whitespace},
            0 #{$base-component-whitespace},
            0 100%,
            100% 100%,
            100% 0
          )
      ) {
        clip-path: polygon(
          calc(((100% - #{$max-width}) / 2) + #{rem(924)} + #{$promo-spacer}) 0,
          calc(((100% - #{$max-width}) / 2) + #{rem(924)} + #{$promo-spacer}) #{$base-component-whitespace},
          0 #{$base-component-whitespace},
          0 100%,
          100% 100%,
          100% 0
        );
      }
    }
  }
}

.hero__title {
  position: absolute;
  left: 0;
  bottom: rem(105);
  width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    bottom: rem(168);
  }

  h1 {
    @include componentize();

    div {
      padding-bottom: rem(7);

      &:nth-child(2) span {
        .wf-loading & {
          @include font(futura-fallback, light);
          font-size: rem(30);
          line-height: 1.302;
          letter-spacing: rem(-1.9);
          word-spacing: rem(0.35);
        }

        .wf-active & {
          @include font(futura, light);
          font-size: rem(35);
          line-height: 1.133;
        }

        @media (min-width: $bp-min-small-tablet) {
          .wf-loading & {
            font-size: rem(41);
            line-height: 1.331;
            letter-spacing: rem(-2);
            word-spacing: rem(-2);
          }

          .wf-active & {
            font-size: rem(48);
          }
        }
      }
    }

    span {
      background-color: $white; /* Accessibile placeholders; override in theme file */
      color: $black;
      padding: 0 rem(7);
    }
  }
}
