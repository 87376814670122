// The width calculations here are very specific to this
// table, as we're trying to line up the columns with
// a CSS-column setup on the same page.
// See the Area of Interest page for example.

.contact-grid__item {
  margin-top: rem(35);

  &:first-child {
    margin-top: 0;
  }

  .rich-text {
    max-width: none;
  }
}

@media (min-width: $bp-min-small) {
  .contact-grid {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-grid__item {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
    max-width: 323px;

    // First two items, no top margin
    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    > * {
      margin-right: rem(42);
    }

    &:nth-child(even) {
      > * {
        margin-right: 0;
      }
    }
  }
}

@media (min-width: $bp-min-large) {
  .contact-grid__item {
    width: 34.8494%;

    // First three items, no top margin
    &:nth-child(-n + 3) {
      margin-top: 0;
    }

    > * {
      margin-right: rem(42);
    }

    &:nth-child(even) {
      > * {
        margin-right: rem(42);
      }
    }

    &:nth-child(3n + 3) {
      width: 30.299%;

      > * {
        margin-right: 0;
      }
    }
  }
}
/* stylelint-disable declaration-no-important */
@media print {
  .contact-grid {
    display: block !important;
  }
  .contact-grid__item {
    width: auto !important;
    max-width: none !important;
  }
}
/* stylelint-enable declaration-no-important */
