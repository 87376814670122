.event-list-item__manual-cta {

  //padding: 1rem 1rem 1rem 0;
  padding: 1rem;
  margin: 0;
  transition: padding $standard-transition;

  @media (min-width: $bp-min-small-tablet) {
    padding: 1rem 0 1rem 1rem;
    margin-left:2.625rem;
    margin-bottom: 0rem;
    transition: padding $standard-transition;
  }

  .event-list-item__title{
    transition: all $standard-transition;
    text-decoration-color: rgba(51, 51, 51, .5);
    text-decoration-thickness: 0px;
    line-height: 1.5;
    margin-top: -1px;
  }

  .event-list-item__time {
    margin-top: 2px;
  }

  &:is(span){
    &:hover{
      .event-list-item__date-month-day {
        transform: none;
      }
    }
  }
  
  &:is(a) {

    .event-list-item__title{
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */ 
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-thickness: 4px;
        text-underline-offset: .2em;
        text-decoration-color: rgba(51, 51, 51, .5);
      }
    }

    &:hover{
      background-color: $drexel-grey-pale;
      color: $drexel-grey-text;
      //padding: 1rem 0 1rem 1rem;
      border-bottom-color: rgba(197, 197, 197, 0.5);

      .event-list-item__title{
        text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-thickness: 4px;
          text-underline-offset: .2em;
          text-decoration-color: rgba(51, 51, 51, .5);
          border-bottom-color: $drexel-grey-text;
          transition: all $standard-transition;
          color: $drexel-blue-dark;

      }

      
    }
}

  .event-list-item__summary {
    flex-direction: column;
    gap: 1rem;

    .event-list-item__title{
      font-weight: bold;
      margin-bottom: .5rem;
      @media (min-width: $bp-min-small-tablet) {
        margin-bottom: 0;
      }

    }

    .event-list-item__description{
      font-size: 1rem;
    }
  }
}

.event-list-item-manual-cta-listing{
  @include component-normalize();
  @include componentize();

  max-width: 100%;
}

//Sitecore Specific Editor Styles
.is-page-editor .event-list-item-manual-cta-listing .scLooseFrameZone {
  display: block;
  width: 100%;
}