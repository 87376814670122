.contact-card {
  margin-bottom: rem(32);

  @media (min-width: $bp-min-small) {
    width: 50%;

    &:last-child,
    &:nth-last-child(2):not(:nth-child(2)) {
      margin-bottom: 0;
    }
  }

  &:nth-child(2n + 1) {
    @media (min-width: $bp-min-small) {
      padding-right: rem(42);
    }
  }

  &:nth-child(2n) {
    @media (min-width: $bp-min-small) {
      border-left: 1px solid currentColor;
      padding-left: rem(42);
    }
  }
}

.contact-card__info {
  .icon-link {
    @include font(stevie, regular);

    display: block;
    font-size: rem(15);
    line-height: 1.733;

    & + .icon-link {
      margin-left: 0;
    }
  }

  .icon-link__icon {
    margin-right: rem(4);
    top: 6px;
  }

  .icon-lnk__icon,
  .icon-link svg {
    height: rem(17);
    width: rem(17);
  }
}

.contact-card__name {
  --link-color: $drexel-gray-headline;
  --link-border: transparent;
  @include font(stevie, bold);

  color: $drexel-grey-headline;
  font-size: rem(18);
  line-height: 1.7222;
  margin-bottom: rem(12);
}
