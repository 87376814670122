.hamburger {
  align-items: center;
  background-color: transparent;
  border: none;
  color: currentColor;
  display: flex;
  padding: 0 rem(15);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(13);
    line-height: 1.278;
    letter-spacing: rem(-0.15);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(14);
    line-height: 1.2;
  }

  @media (max-width: $bp-min-small-tablet) {
    &.touch-focus {
      outline: none;
      box-shadow: none;
    }
  } 
}

// Icon style and animation code cribbed from
// http://codepen.io/pedrocampos/pen/gufrx/
.hamburger__toggle-icon {
  cursor: pointer;
  display: inline-block;
  height: rem(42);
  margin-right: rem(6);
  position: relative;
  width: rem(21);

  span {
    margin: 0 auto;
    position: relative;
    top: calc(50% - #{rem(2)});
    transition-duration: 0s;

    &::before,
    &::after {
      position: absolute;
      content: "";
      transition-property: margin, transform;
      transition-duration: 0.2s;
    }

    &,
    &::before,
    &::after {
      display: block;
      width: rem(21);
      height: rem(3);
      background-color: currentColor;
    }

    &::before {
      margin-top: rem(-6);
    }

    &::after {
      margin-top: rem(6);
    }
  }
}
