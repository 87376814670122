.return-to-top {
  align-items: center;
  display: flex;
  height: 0px;
  justify-content: center;
  position: fixed;
  top: calc(100% - #{rem(168)});
  left: calc(100% - #{rem(32)});
  width: rem(25);
  z-index: $stack-menu;

  @media (min-width: $bp-max-small) {
    width: rem(36);
  }

  @media (min-width: $bp-max-medium) {
    left: calc(100% - #{rem(63)});
  }

  /* IE can't do stickies, but use it for modern browsers */
  @supports (position: sticky) {
    bottom: rem(32);
    position: sticky;
    top: auto;

    @media (min-width: $bp-max-medium) {
      bottom: rem(84);
    }
  }

  @media print {
    display: none;
  }
}

.return-to-top__link {
  border: 2px solid currentColor;
  align-items: center;
  display: flex;
  height: rem(25);
  justify-content: center;
  position: absolute;
  bottom: $standard-side-margin;
  transform: rotate(90deg);
  width: rem(25);

  @media (min-width: $bp-max-small) {
    height: rem(36);
    width: rem(36);
  }

  svg {
    height: rem(19);
    width: rem(19);

    @media (min-width: $bp-max-small) {
      height: rem(29);
      width: rem(29);
    }
  }
}
