// video-grid-block.scss

// base
.video-grid-block {
  padding-bottom: 0;
  transition: padding-bottom 350ms ease;

  @media (max-width: $bp-min-small-tablet) {
    margin-bottom: 2rem;
  }

}

.video-grid-block__video-wrapper {
  position: relative;
  padding-bottom: 75%; /* 4:3 */
  height: 0;
  width: 100%;
  overflow: hidden;

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none;
  }
  /* stylelint-enable declaration-no-important */
}

.video-grid-block__video-overlay {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  z-index: $stack-reset;
  cursor: pointer;

  img {
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s;
  }

  &:hover img,
  &:focus img {
    transform: scale(1.1, 1.1);
  }

  &:disabled {
    cursor: auto;

    img {
      transform: none;
    }
  }
}

.video-grid-block__video-icon {
  height: rem(57);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: rem(57);

  svg {
    height: 100%;
    width: 100%;
    transition: color $standard-transition;
  }

  .video-grid-block__play-overlay--active + & {
    display: none;
  }
}

.video-grid-block__play-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $stack-overlaps;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  font-size: 0;
}

.video-grid-block__play-overlay--active {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  @include font(futura, regular);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: rem(26);
  line-height: 1;

  // Different treatment for 4-up layout
  .layout-columns--4-up & {
    font-size: rem(20);
  }
}

.video-grid-block__caption {
  font-size: rem(15);
  line-height: 1.7333;
  margin-top: rem(17);

  .video-grid-block__title + & {
    margin-top: rem(8);
  }

  @media print {
    font-size: inherit;

    .video-grid-block__title + & {
      break-before: avoid-page;
      page-break-before: avoid;
      margin-top: 0;
    }
  }
}

.video-grid-block__title {
  margin-top: 1rem;
  margin-bottom: 0;
  display: inline-block;

  .wf-loading & {
    @include font(futura-fallback, regular);
    font-size: rem(24);
    line-height: 1.542;
    letter-spacing: rem(-0.15);
    word-spacing: rem(0.6);
  }

  .wf-active & {
    @include font(futura, regular);
    font-size: rem(30);
    line-height: 1.2666;
  }

  // Different treatment for 4-up layout
  .layout-columns--4-up & {
    .wf-loading & {
      @include font(futura-fallback, regular);
      font-size: rem(22);
      line-height: 1.605;
      letter-spacing: rem(-0.85);
    }

    .wf-active & {
      @include font(futura, regular);
      font-size: rem(26);
      line-height: 1.346;
    }
  }

  button {
    border: none;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: inherit;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
    line-height: inherit;
    transition: border-bottom-color $standard-transition;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
    font-family: futura-pt, "Lucida Grande", "Lucida Sans Unicode", sans-serif !important;
    // font-size: rem(34.83648) !important;
    font-weight: 400 !important;
    letter-spacing: 0 !important;
    line-height: calc(2px + 2ex + 2px) !important;
    margin-bottom: 0.65em !important;
    margin-top: $print-standard-component-whitespace !important;

    // This is ugly but needed to prevent extra space

    .container > .video-grid:first-child .video-grid-block:first-child & {
      margin-top: $print-standard-text-whitespace !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

// Improved focus state for keyboard Users
.video-grid-block__title--active button:focus {
  outline: 1px dotted $drexel-blue;
}

.video-grid-block__expanding-panel {
  // make full bleed
  display: none;
  position: absolute;
  overflow: hidden;
  left: 50%;
  right: 50%;
  margin-left: -50%;
  margin-right: -50%;
  height: 0;
  margin-top: 25px;
  transition: all 350ms ease;

  .video-grid-block__expanding-panel-inner {
    padding: rem(56) rem(21) rem(42);
  }
}

.video-grid-block__video-close {
  border: none;
  outline: none;
  display: block;
  position: absolute;
  top: rem(21);
  left: calc(100% + #{rem(16)});

  svg {
    transition: color 0.5s;
  }
}

.video-grid-block__video-outer-wrapper {
  width: 75%;
  max-width: 1048px;
  margin: 0 auto;
}

.video-grid-block__video-inner-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $stack-overlaps;
  }
}

// Nicer version of the underline, for browsers that support it:
@supports (text-underline-offset: 0.1em) {
  .video-grid-block__title button,
  .video-grid-block__title--active button,
  .video-grid-block__title button:focus {
    border: none;
  }
  .video-grid-block__title button {
    transition: text-decoration-color $standard-transition;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: 4px;
    text-underline-offset: 0.2em;
  }
}

// Hover state
.video-grid-block:hover {
  .video-grid-block__video-overlay img {
    transform: scale(1.1, 1.1);
  }
  .video-grid-block__play-overlay {
    border-bottom-width: 4px;
    border-bottom-style: solid;
  }

  @supports (text-underline-offset: 0.1em) {
    .video-grid-block__title button {
      border: none;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-thickness: 4px;
      text-underline-offset: 0.2em;
    }
  }
}

// Reduced motion
@media (prefers-reduced-motion: reduce) {
  .video-grid-block,
  .video-grid-block__expanding-panel {
    transition: none !important;
  }
}
