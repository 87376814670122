// Policy Search
.policy-search-box {
    border: 1px solid $black;
    margin-bottom: $standard-vertical-margin;

    @media (max-width: $bp-min-small-tablet) {
        margin-left: 0;
    }

    form.search-box {
        @media (max-width: $bp-min-small-tablet) {
            margin-bottom: 0;
        }
    }
}

#noResultsMessage {
    display: block;
    position: absolute;
    color: $drexel-red;

    &.hidden {
        display: none;
    }
}

.policy-anchor__header {
    margin-top: 3rem;

    h2, .wf-active h2, .wf-active .txt-h2 {
        font-size: 2rem;
    }
}

// Policy Anchor
.hidden {
    display: none;
}

.policy-anchors {
    margin-top: 1rem;

    &.hidden {
        display: none;
    }

    .letter-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 1.25rem;

        a {
            @include font(stevie);
            color: $drexel-blue;
            font-weight: 700;
            padding: 0;
            border: none;

            &:hover {
                color: $drexel-blue-light;
            }
        }
    }

    ul.anchor-links {

        &.a-z {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            margin-left:0;
            margin-right: 0;

            @media (min-width: $bp-min-xsmall) {
                grid-template-columns: repeat(9, 1fr);
              }

            @media (min-width: $bp-min-small-tablet) {
                grid-template-columns: repeat(12, 1fr);
              }

            @media (min-width: $bp-min-large){
                display: flex;
                justify-content: space-around;
                margin-left: 0;
            }


            

            .anchor-item {
                opacity: .65;
                color: $grey-500;

                @media (max-width: $bp-max-medium) {
                    width: 38px;
                    height: 38px;
                    margin-right: .5rem;
                    display: grid;
                    align-content: center;
                    justify-content: center;
                    background-color: $drexel-grey-pale;
                }

                &:has(a) {
                    opacity: 1;
                }

                a {
                    font-size: 1.25rem;

                    @media (max-width: $bp-max-medium) {
                        width: 38px;
                        height: 38px;
                        display: grid;
                        align-content: center;
                        justify-content: center;
                        background-color: $drexel-grey-pale;

                        &:hover,
                        &:focus,
                        &:active {
                            background-color: $grey-300;
                        }
                    }
                }
            }
        }

        &.sponsoring-unit {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;

            li.anchor-item {
                list-style-type: inherit;
                font-size: 1rem;
            }

            @media (max-width: $bp-min-small-tablet) {
                columns: 1;
                -webkit-columns: 1;
                -moz-columns: 1;
            }
        }

        li.anchor-item {
            list-style-type: none;
            font-size: 1.25rem;

            &::marker {
                display: none;
            }

            a {
                border-bottom: none;
            }
        }
    }
}

//Policy Grid
.policy-grid-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    transition: all $standard-transition;
    grid-gap: 1rem;
    margin: 0 1rem 3.75rem 0;

    @media (max-width: $bp-min-small) {
        display: block;
        transition: all $standard-transition;
        width: 100%;
        margin: 1.5rem 0;
    }
    
    .policy-grid__link {
        border: none;
    }

    .policy-grid {
        background: $drexel-grey-pale;
        display: inline-grid;
        align-items: flex-end;
        width: 17rem;
        height: 10rem;
        padding: 1.5rem 2rem;
        grid-template-rows: 1fr auto;
        align-items: start;

        @media (max-width: $bp-min-small) {
            width: 100%;
            margin-bottom: 1.5rem;
        }

        &:last-child {
            margin-right: 0;
        }

        .policy-grid__header {
            height: auto;
            text-align: center;

            span {
                @include font(stevie);
                color: $drexel-blue;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 1rem;
            }
        }


        .policy-grid__amount {
            display: flex;
            justify-content: space-between;
            align-items: end;
            padding-top: .5rem;
            border-top: 3px solid $grey-300;

            .text {
                display: flex;
                color: $drexel-grey-text;
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 0;
            }

            .number {
                display: flex;
                @include font(futura-pt-bold);
                color: $drexel-grey-text;
                font-size: 18px;
                font-weight: 900;
                line-height: 1.25;
            }
        }
    }
}


// Policy Accordion
.policy-accordion-group {

    &.hidden { 
        display: none; 
    }

    .policy-accordion {
        border-bottom: 1px solid $grey-200;

        
        @media (min-width: $bp-min-small-tablet) {
            margin-left: 2.625rem;
        }

        &:last-child {
            border-bottom: 0px;
        }
        &.hidden { 
            display: none; 
        }
    
    }

    @media print {
        margin-bottom: $print-standard-component-whitespace;
    }
}

.policy-accordion-group--secondary {
    margin-bottom: $standard-vertical-margin;

    @media (min-width: $bp-min-small-tablet) {
        margin-bottom: $standard-vertical-margin;
    }

    @media print {
        margin-bottom: $print-standard-component-whitespace;
    }
}

.policy-accordion-group__accordions {
    padding-left: 0;

    @media (min-width: $bp-min-small-tablet) {

        // Secondary variant (seen in Area of Study)
        .policy-accordion-group--secondary & {
            padding-left: 0;
        }
    }
    .policy-accordion-group__header {
        width: 100%;
        display: block;

        .policy-accordion-group__title {
            position: relative; 

            &::after {
                background-color: $drexel-gold;
                content: "";
                display: block;
                height: rem(4);
                position: absolute;
                bottom: -5px;
                left: 0;
                min-width: 100%;
              }
            
              @media (min-width: $bp-min-xsmall) {
                display: inline-block;
            
                &::after {
                  min-width: rem(300);
                }
            }
        }

        h3 {
            display: block;
            width: 100%;
            padding-top: 1.5rem;
            margin-bottom: 1rem;
            font-size: 2.375rem;

            @media (max-width: $bp-min-small-tablet) {
                margin-bottom: .75rem;
            }
        }

        @media (max-width: $bp-min-small-tablet) {
            display: block;
        }
    }
}

.policy-accordion__body {
    display: none;
    margin-top: rem(21);
    padding-left: rem(53);
    padding-bottom: $standard-vertical-margin;

    .policy-table {
        width: 100%;
        margin-bottom: 1.5rem;

        tbody  {
            th {
                padding-left: .875rem;
            }
        
            tr {
            border-bottom: none;
        
                &:nth-child(even) {
                background-color: $drexel-grey-pale;
                }
            }
        }
    }

    .policy-accordion__link {
        margin-top: 1.5rem;
        width: fit-content;
    
        @media (max-width: $bp-min-small-tablet) {
            width: 100%;
        }
    
        svg {
            display: inline;
            height: 20px;
        }
    
        .btn-subtext {
            font-size: .85rem;
            text-align: center;
        }
    
        @media print {
            @include print-list;
        }
    }

    .accordion-group .policy-accordion:last-child & {
        padding-bottom: 0;
    }

    // Secondary variant (seen in Area of Study)
    .policy-accordion--secondary & {
        margin-top: rem(11);
        padding-left: 0;

        a:not(.btn) {
            border-bottom-color: transparent;
            border-bottom-width: 2px;

            &:hover,
            &:focus {
                border-bottom-color: currentColor;
            }
        }
    }

    /* stylelint-disable declaration-no-important */
    @media print {
        display: block !important;
        padding: 0 !important;
    }

    /* stylelint-enable declaration-no-important */
}

.policy-accordion__category {
    font-family: "stevie-sans", Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1rem;
    color: #333333;
    text-align: left;
    display: block;
    align-items: center;
    width: auto;
    margin: .5rem 3rem 0 0;
    min-width: 6rem;
    justify-content: end;
}

.policy-accordion__trigger {
    @extend .txt-h3;

    border: none;
    background-color: transparent;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding: rem(32) 0 rem(32) rem(53);
    position: relative;
    text-align: left;
    transition: background-color 0.3s;
    width: 100%;
    text-transform: capitalize;

    .policy-accordion__policy-title {
        padding-right: 6rem;
    }

    @media (max-width: $bp-min-small-tablet) {
        display: block;
    }

    &:hover,
    &:focus {
        background-color: $grey-50;
        /* Accessible placeholder; override in theme file */
    }

    // Secondary variant (seen in Area of Study)
    .policy-accordion--secondary & {
        padding: rem(4) 0 rem(4) rem(35);

        .wf-loading & {
            @include font(stevie-fallback, bold);
            font-size: rem(18);
            line-height: 1.267;
            letter-spacing: 0;
        }

        .wf-active & {
            @include font(stevie, bold);
            font-size: rem(18);
            line-height: 1.267;
        }
    }

    @media print {
        .accordion-group__title+.accordion-group__accordions .policy-accordion:first-child & {
            margin-top: $print-standard-text-whitespace !important;
        }
    }
}

.policy-accordion__plus-sign {
    display: block;
    height: rem(42);
    float: left;
    position: absolute;
    left: 0;
    top: rem(32);
    width: rem(42);

    // Secondary variant (seen in Area of Study)
    .policy-accordion--secondary & {
        height: rem(30);
        top: 0;
        width: rem(15);
    }

    &::after,
    &::before {
        background-color: $drexel-blue-light;
        content: "";
        display: block;
        height: rem(3);
        width: rem(15);
        position: absolute;
        left: calc(50% - #{rem(7)});
        top: calc(50% - #{rem(2)});
    }

    &::before {
        transform: rotate(270deg);
        transition: transform 0.3s ease-out;
    }

    .policy-accordion__trigger.is-open &::before {
        transform: rotate(0deg);
    }

    /* stylelint-disable declaration-no-important */
    @media print {
        display: none !important;
    }

    /* stylelint-enable declaration-no-important */
}