.search-flyout {
  min-width: rem(84);
  position: relative;
  text-align: right;
  display: flex;
  align-items: center;
  height: 25px;

  // Header structure is a little different, for offices
  .header--office & {
    top: auto;

    @media (min-width: $bp-min-small-tablet) {
      min-width: rem(100);
      // padding: .9375rem 0;
    }
  }

  .search-box,
  .search-box__input,
  .search-box input {
    height: rem(31);
    line-height: rem(31);
    padding: 0;
  }

  .search-box input {
    padding: rem(6) rem(6) rem(6) rem(67);
  }

  .search-box label {
    @include font(stevie, light);

    font-size: rem(14);
    line-height: rem(31);
    margin-left: 0;
    text-transform: none;
  }

  .search-box button:focus ~ label,
  .search-box input:focus ~ label {
    top: 0;
  }
}

.search-flyout__search-box {
  display: inline-block;
  height: rem(31);
  margin: rem(-6) 0;
  overflow: hidden;
  position: relative;
  top: rem(2);
  transition: width 0.5s;
  width: 0;

  &.is-open {
    width: rem(315);

    * {
      display: block;
    }
  }

  * {
    display: none;
  }
}

.search-flyout__trigger {
  position: absolute;
  right: 0;
  width: 100%;

  @media (min-width: $bp-min-small-tablet) and (max-width: $bp-max-large) {
    .header--office & {
      display: flex;
      justify-content: flex-end;
    }
  }
}
