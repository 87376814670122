.more-like-this {
  @include component-normalize();
  @include componentize();

  margin-bottom: 3.75rem;

  .secondary-navigation + & {
    margin-top: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: $base-component-whitespace;
    }
  }

  @media print {
    display: none;
  }
}

.more-like-this__title {
  border-bottom: rem(4) solid currentColor;
  margin-bottom: rem(32);
  padding-bottom: rem(11);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(19);
    line-height: 1.516;
    letter-spacing: rem(0.4);
    word-spacing: rem(0.4);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: 1.6px;
  }
}

.more-like-this__image {
  width: 100%;

  img {
    display: block;
    height: auto;
    margin-bottom: rem(18);
    width: 100%;
  }
}

.more-like-this__list {
  @include zero-list();

  @media (min-width: $bp-min-small-tablet) {
    padding-right: rem(70);
  }
}

.more-like-this__item {
  @include zero();

  border-bottom: 1px solid currentColor;
  padding-bottom: rem(11);
  margin-bottom: rem(11);

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  a {
    @include font(stevie, regular);

    border-top: 4px solid transparent; /* Balance the center */
    border-bottom-color: transparent;
    font-size: rem(16);
    line-height: 1.625;
  }

  /* If there's an image, the first item goes with it */
  .more-like-this__image + .more-like-this__list &:first-child a {
    font-size: rem(20);
  }
}

// Boxed variants

[class*="more-like-this--boxed"] {
  padding: rem(30) 30px;
}

.more-like-this--boxed-light {
  background-color: $base-box-bg-color;

  .more-like-this__item {
    border-bottom-color: rgba($black, 0.5);
  }
}

.more-like-this--boxed-dark {
  @include font-smoothing(on);
  background-color: $drexel-grey-text;
  color: $white;

  .more-like-this__item {
    border-bottom-color: rgba($white, 0.5);
  }
}
