.color-block-promo {
  display: flex;
  transition: margin, 0.2s;

  a {
    color: currentColor;

    &:focus {
      border-bottom-color: currentColor;
    }
  }

  .layout-columns > & {
    @media (min-width: $bp-min-small-tablet) {
      display: flex; /* deal with the cascade because promo-grid plays some display games */
      margin-bottom: 0;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    color: $black !important;
    background-color: none !important;
    margin-bottom: $print-standard-component-whitespace;

    &:last-child {
      margin-bottom: 0;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.color-block-promo__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: rem(294);
  padding: rem(27) rem(42);
  width: 100%;

  @media print {
    display: block;
    padding: 0;
    min-height: 0;
  }
}

.color-block-promo--has-image .color-block-promo__body {
  @media (min-width: $bp-min-small-tablet) {
    width: calc(50% - #{$promo-spacer});
  }
}

.color-block-promo__image-wrapper {
  min-height: rem(294);
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    width: calc(50% + #{$promo-spacer});
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.color-block-promo__image {
  background-position: center center;
  background-size: cover;
  height: 100%;
  position: absolute;
  transition: transform 0.25s;
  width: 100%;

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover & {
    transform: scale(1.1, 1.1);
  }
}

.color-block-promo__title {
  margin-bottom: rem(5);

  .wf-loading & {
    @include font(futura-fallback, regular);
    font-size: rem(24);
    line-height: 1.6;
    letter-spacing: rem(0.15);
  }

  .wf-active & {
    @include font(futura, regular);
    font-size: rem(30);
    line-height: 1.267;
  }

  a {
    border-bottom: rem(4) solid transparent;
  }

  span {
    color: currentColor;
    font-weight: 700;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: 0.65em;
    font-size: 1.25rem !important;

    a:after {
      display: block !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.color-block-promo__label {
  font-size: rem(16);
  line-height: 1.688;
  margin-bottom: auto;
  opacity: 0.7;
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    letter-spacing: rem(-0.3);
  }

  .wf-active & {
    @include font(futura, demi);
    letter-spacing: rem(1);
  }

  a {
    border-bottom: rem(4) solid transparent;
  }

  @media print {
    opacity: 1;
  }
}

.color-block-promo__summary {
  @include font(stevie, book);

  font-size: rem(15);
  line-height: 1.733;

  @media print {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}
