.search-button-bar {
  padding-bottom: rem(16);
}

.search-button-bar__items {
  @include zero-list();
}

.search-button-bar__item {
  @include zero-list();

  display: inline-block;
}

a.search-button-bar__link {
  @include font(stevie, regular);

  border: none;
  display: inline-block;
  font-size: rem(17);
  line-height: 1.471;
  margin: 0;
  padding: rem(6) rem(11);
  transition: color 0.3s, background-color 0.3s;

  &:hover,
  &:focus,
  &.is-active {
    background-color: $black; /* accessible placeholder, override in theme */
    color: $white;
  }
}
