.staff-search-listing {
  @include componentize();

  position: relative;
}

.staff-search-listing__app {
  max-width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    display: grid;
    grid-template-columns: calc(100% - #{rem(476)}) rem(140) rem(336);
    grid-template-rows: auto rem(42) auto;
  }
}

.staff-search-listing__rule {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 2;
  grid-row-end: span 1;
  margin-top: rem(25);
  padding-bottom: rem(44);
  position: relative;

  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.staff-search-listing__facets {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 1;
  grid-row-end: span 1;
  margin-bottom: rem(28);
  position: sticky;
  top: rem(21);
  z-index: $stack-overlaps;

  & > div {
    // Another Reacty div wrapper here.
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
    max-height: none;
    position: static;
    z-index: $stack-overlaps;
  }
}

.staff-search-listing__results {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 3;
  grid-row-end: span 1;
}

.staff-search-listing__sidebar {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 3;
  grid-row-end: span 1;
  margin-top: $base-component-mobile-whitespace;
}
