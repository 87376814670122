.footer-hours {
    font-size: rem(17);
    line-height: 1.588;
    margin: rem(21) rem(21) rem(25);
    padding-bottom: rem(25);
  
    @media (min-width: $bp-footer-min-medium) {
      margin: 0;
      padding-bottom: 0;
      text-align: left;
    }
  
    .footer-hours__icon {
      @media (max-width: $bp-min-small-tablet) {
        margin: 0 auto;
        width: fit-content;
        margin-bottom: .5rem;
      }
      span {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
      }
      h3 {
        @include font(stevie, regular);
        font-size: rem(16);
        font-weight: 700;
        line-height: 1.837;
        letter-spacing: rem(-0.2);
        margin-bottom: 0;
        margin-left: .5rem;
      }
    }
  
    .footer-hours__info {
      text-align: left;
  
      @media (max-width: $bp-min-small-tablet) {
        text-align: center;
      }
  
      .footer-hours__date-time {
        margin-bottom: .5rem;
        display: block;
      }  
    }
  
    .footer-hours__paragraph {
      margin-top: 1rem;
  
      @media (max-width: $bp-min-small-tablet) {
        text-align: center;
      }
    }
  }