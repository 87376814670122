$greyband-color: $drexel-grey-pale;
$navyband-color: $drexel-navy;

.container {
  @include containerize();

  &.container--wide {
    padding-left: $standard-side-margin;
    padding-right: $standard-side-margin;

    @media (min-width: $bp-min-xxlarge) {
      padding-right: calc((100% - #{$max-width}) / 2);
      padding-left: calc((100% - #{$max-width}) / 2);
    }
  }

  &.container--greyband,
  &.container--navyband,
  &.container--subfooter {
    padding-top: $base-component-whitespace;
    padding-bottom: $base-component-whitespace;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.container--navyband + &.container--greyband,
  &.container--greyband + &.container--navyband {
    margin-top: -#{$base-component-mobile-whitespace};

    @media (min-width: $bp-min-small-tablet) {
      margin-top: -#{$base-component-whitespace};
    }
  }

  &.container--greyband {
    // These are used cross-site, hence the color definitions here
    background-color: $greyband-color;
  }

  &.container--subfooter {
    // These are used cross-site, hence the color definitions here
    background-color: rgba($greyband-color, 0.5);
    // This is the one container that should never touch the container above it
    margin-top: rem(21);
    overflow: hidden;

    /* stylelint-disable declaration-no-important */
    @media print {
      margin-bottom: 0 !important;
      margin-top: $print-standard-component-whitespace !important;
    }
    /* stylelint-enable declaration-no-important */
  }

  &.container--stripe {
    padding-top: rem(11);
    padding-bottom: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      background: linear-gradient(
        90deg,
        $greyband-color 0%,
        $greyband-color rem(301),
        $white rem(301),
        $white 100%
      );
      padding-top: $base-component-whitespace;
      padding-bottom: $base-component-whitespace;

      main &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: $bp-min-xxlarge) {
      background-image: linear-gradient(
          90deg,
          $greyband-color rem(280),
          $white rem(280)
        ),
        linear-gradient(90deg, $greyband-color 50%, transparent 50%);
      background-position: center center, center center;
      background-size: $max-width-rems 100%, 100% 100%;
      background-repeat: no-repeat no-repeat;
    }

    &.container--bump-up {
      @media (min-width: $bp-min-small-tablet) {
        @include overlap(margin-up, -#{$base-component-whitespace});
      }

      @media (min-width: $bp-min-xxlarge) {
        // Overcome the margin of the previous component and also overlap by the same amount
        @include overlap(margin-up, -#{$base-double-component-whitespace});
      }
    }

    &.container--hr {
      @media (min-width: $bp-min-small-tablet) {
        border-bottom: 1px solid $drexel-grey-soft;
      }
    }
  }

  &.container--navyband {
    // These are used cross-site, hence the color definitions here
    background-color: $navyband-color;
  }

  &.container--no-margin-bottom {
    margin-bottom: 0;
  }


  /* stylelint-disable declaration-no-important */
  @media print {
    padding: 0 !important;
    background-image: none !important;
    margin-top: 0 !important;

    &:has(.highlight-text:last-child):has(+ .container .stat-grid:first-child) {
      margin-bottom: $print-standard-text-whitespace !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

// Remove vertical space between stripe and subfooter
.container--stripe + .container--subfooter {
  margin-top: -$base-component-mobile-whitespace;

  @media (min-width: $bp-min-small-tablet) {
    margin-top: -$base-component-whitespace;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-top: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}
