.email-card {
  font-size: rem(15);
  line-height: 1.733;

  .subfooter__column > & {
    width: 100%;
    text-align: center;

    @media (min-width: $bp-footer-min-medium) {
      text-align: left;
    }
  }

  .icon-link {
    font-size: rem(15);
    line-height: 1.733;
    display: flex;

    .subfooter__column & {
      justify-content: center;

      @media (min-width: $bp-footer-min-medium) {
        justify-content: flex-start;
      }
    }
  }

  .icon-link__icon,
  .icon-link__icon svg {
    height: rem(17);
    width: rem(17);
  }

  .icon-link__icon {
    top: 6px;
    margin-right: rem(5);
  }
}

.email-card__summary {
  margin-left: rem(22);

  .subfooter__column & {
    margin-left: 0;

    @media (min-width: $bp-footer-min-medium) {
      margin-left: rem(22);
    }
  }
}
