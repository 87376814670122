.sidebar {
  border-left: 4px solid currentColor;
  font-size: rem(17);
  padding-left: 40px;

  @media (min-width: $bp-min-large) {
    width: rem(360);
  }

  h2 {
    text-transform: uppercase;

    .wf-loading & {
      @include font(futura-fallback, demi);
      font-size: rem(19);
      line-height: 1.498;
      letter-spacing: rem(0.35);
      word-spacing: rem(0.4);
    }

    .wf-active & {
      @include font(futura, demi);
      font-size: rem(20);
      line-height: 1.4;
      letter-spacing: rem(1.6);
    }
  }

  h3 {
    margin-bottom: rem(5);
    margin-top: rem(42); /* H3s are used as section dividers in this space. */

    .wf-loading & {
      @include font(stevie-fallback, bold);
      font-size: rem(17);
      line-height: 1.722;
      letter-spacing: 0;
    }

    .wf-active & {
      @include font(stevie, bold);
      font-size: rem(17);
      line-height: 1.722;
    }
  }

  p {
    font-size: rem(17);
    margin-bottom: rem(11);
  }

  .icon-link {
    display: block;
    margin-left: 0;
    margin-bottom: rem(5);
  }

  .rich-text {
    margin-bottom: rem(11);
  }

  @media print {
    border-left: none;
    font-size: inherit;
    padding-left: 0;
  }
}
