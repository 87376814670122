.stat-mosaic {
  @include componentize();

  @media (min-width: $bp-min-small-tablet) {
    display: grid;
    gap: rem(30);
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    grid-template-rows: rem(30) rem(210) auto rem(210) rem(30);
    max-width: rem(1170);
    padding: 0 rem(21);

    & + .stat-mosaic {
      margin-top: calc(-#{$base-component-whitespace} - #{rem(30)});
    }

    // We'll need to remove the last two grid-template-rows
    // in the case of one or two items being displayed
    &.stat-mosaic--3,
    &.stat-mosaic--2,
    &.stat-mosaic--1 {
      grid-template-rows: rem(30) rem(210) auto;
    }

    /* Close up containers with fewer stats */
    &.stats--3 {
      grid-template-rows: rem(30) rem(210) auto 0;
    }

    &.stats--2 {
      grid-template-rows: rem(30) rem(210) auto;
    }
  }
}

.stat-mosaic__promo {
  margin-bottom: $base-component-mobile-whitespace; /* Contained components will always be last child and lose their own margin bottom from componentize */
  position: relative;
  top: 0;
  transition: top 1s;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }

  &.is-shifted {
    top: rem(42);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    top: auto !important;
    margin-bottom: $print-standard-component-whitespace !important;
    transition: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.stat-mosaic__promo--1,
.stat-mosaic__promo--3 {
  /* IE can't do grid gaps, so fake it... */
  @media (min-width: $bp-min-small-tablet) {
    padding-bottom: 60px; /* Why 60? Not sure; IE is overlapping by 30px for some reason. */
    padding-top: 60px;
  }

  /* And reset it for real browsers */
  @supports (gap: 1px) {
    @media (min-width: $bp-min-small-tablet) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

.stat-mosaic__promo--1,
.stat-mosaic__promo--4 {
  /* IE can't do grid gaps, so fake it... */
  @media (min-width: $bp-min-small-tablet) {
    margin-right: 15px;
  }

  /* And reset it for real browsers */
  @supports (gap: 1px) {
    @media (min-width: $bp-min-small-tablet) {
      margin-right: 0;
    }
  }
}

.stat-mosaic__promo--2,
.stat-mosaic__promo--3 {
  /* IE can't do grid gaps, so fake it... */
  @media (min-width: $bp-min-small-tablet) {
    margin-left: 15px;
  }

  /* And reset it for real browsers */
  @supports (gap: 1px) {
    @media (min-width: $bp-min-small-tablet) {
      margin-left: 0;
    }
  }
}

/* There will ALWAYS be between 1 and 6 of these things and they fall into an extremely strict position to make this work at all */
.stat-mosaic__promo--1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: span 2;
}

.stat-mosaic__promo--2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: span 2;
}

.stat-mosaic__promo--3 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: span 2;

  // When there are 3 items in the mosaic,
  // the third item needs to span 1 to prevent an empty space below it
  .stat-mosaic--3 & {
    grid-row-end: span 1;
  }
}

.stat-mosaic__promo--4 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 4;
  grid-row-end: span 2;
}
