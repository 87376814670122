.degrees-table {
  @include component-normalize;
  font-size: rem(18);

  caption {
    text-align: left;
    white-space: nowrap; // Can undo if it causes problems later
  }
}

.degrees-table__scroller {
  overflow: auto;
}

.degrees-table__table {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 0;
}

.degrees-table__row {
  border: none;
}

// The width calculations here are very specific to this
// table, as we're trying to line up the columns with
// a CSS-column setup below it.
// See the Area of Interest page for example.

.degrees-table__header,
.degrees-table__cell {
  padding: rem(14) 0;
  width: 34.8494%;

  &:last-child {
    width: 30.299%;
  }
}

.degrees-table__header {
  color: inherit;
  padding: 0 0 rem(7);
  text-align: left;

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(24);
    line-height: 1.592;
    letter-spacing: rem(-0.3);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(30);
    line-height: 1.267;
  }

  @media print {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
  }
}

.degrees-table__cell {
  text-align: left;
  vertical-align: top;
  border: none;
  font-size: rem(18);

  // Don't display the pseudo headers for our desktop table
  // They'll only display on mobile
  &::before {
    display: none;
  }
}

.degrees-table__cell-content {
  border-left: 4px solid $drexel-grey-light;
  padding-left: rem(14);
  margin-right: rem(42);
}

.degrees-table__cell:last-child .degrees-table__cell-content {
  margin-right: 0;
}

// Non-table layout for mobile
@media screen and (max-width: $bp-min-small), print and (max-width: 5in) {
  .degrees-table__scroller {
    overflow: hidden;
  }

  .degrees-table__h2 {
    margin-bottom: rem(19);
  }

  .degrees-table__table,
  .degrees-table__row,
  .degrees-table__cell {
    display: block;
  }

  .degrees-table__header {
    display: none;
  }

  .degrees-table__row:not(.degrees-table__row--headers) {
    border-top: 1px solid $drexel-grey-soft;
  }

  .degrees-table__header,
  .degrees-table__cell {
    font-size: rem(18);
    width: auto;

    &:last-child {
      width: auto;
      padding-bottom: rem(27);
    }
  }

  // Pseudo-headers for mobile testing
  .degrees-table__cell::before {
    display: block;
    // h3 styling
    color: $drexel-grey-headline;
    margin-bottom: rem(19);

    .wf-loading & {
      @include font(futura-fallback, book);
      font-size: rem(24);
      line-height: 1.592;
      letter-spacing: rem(-0.3);
    }

    .wf-active & {
      @include font(futura, book);
      font-size: rem(30);
      line-height: 1.267;
    }
  }
}

// A few print styles
@media print {
  .degrees-table__scroller {
    overflow: visible;
  }

  .degrees-table__table {
    break-inside: auto;
    page-break-inside: auto;
  }

  .degrees-table__row {
    break-inside: avoid-page;
    page-break-inside: avoid;
  }
}
