.radio-facets {
  margin-bottom: $promo-spacer-narrow;
  position: relative;
  margin-right: -(rem(21));
}

.radio-facets__list {
  @include zero-list();
}

.radio-facets__list-item {
  display: inline-block;
  vertical-align: middle;

  label {
    margin-left: 0;
  }

  @media (min-width: $bp-min-xsmall) {
    margin-right: rem(19);
  }
}
