.page-segment {
  @include componentize();
  @include component-normalize();

  /* if two are neighbors, nix bottom margin, but leave paragraph's margin of space */
  & + & {
    margin-top: calc(#{rem(56)} - #{$base-component-mobile-whitespace});
  }

  @media (min-width: $bp-min-small-tablet) {
    /* if two are neighbors, nix bottom margin, but leave paragraph's margin of space */
    & + & {
      margin-top: calc(#{rem(56)} - #{$base-component-whitespace});
    }
  }
}

.page-segment__sidebar {
  & > div:not(.go-back),
  & > section {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  & > .rich-text {
    padding-right: rem(60);
  }

  @media (min-width: $bp-min-small-tablet) {
    // Striped container
    .container--stripe & {
      width: $standard-sidebar-width;
    }

    // Limit the width of rich text elements within it
    .rich-text {
      max-width: rem(260);
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    width: $standard-sidebar-width;
  }
}

.page-segment__body {
  margin-bottom: $base-component-mobile-whitespace;

  & > div:not(.floating-cta-sidebar):not(.quote-promo--centered),
  & > section {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
    position: relative; // for share positioning
  }

  /* stylelint-disable declaration-no-important */
  // @media print {
  //   .page-segment__sidebar + & {
  //     margin-top: $print-standard-text-whitespace;
  //   }
  // }
  /* stylelint-enable declaration-no-important */
}

// In mobile view, force sidebar to appear after body if sidebar leads
@media (max-width: $bp-max-small) {
  .page-segment--sidebar-leads {
    display: flex;
    flex-direction: column-reverse;
  }

  @supports (display: grid) {
    .page-segment--sidebar-leads {
      display: grid;
    }

    .page-segment__sidebar {
      order: 2;
    }
  }
}

/* stylelint-disable declaration-no-important */
@media print {
  .page-segment--sidebar-leads {
    .page-segment__sidebar > * {
      margin-bottom: $print-standard-component-whitespace !important;
    }
  }
}
/* stylelint-enable declaration-no-important */
