.article-title-card {
  @include component-normalize();

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-xxlarge) {
    max-width: $max-width;
  }

  // If breadcrumb precedes it, we need to give it breathing space up top, because the header doesn't have any otherwise
  .breadcrumbs + &,
  .breadcrumbs + .share + & {
    margin-top: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: $base-component-whitespace;
    }
  }
}

.article-title-card__title {
  margin-bottom: rem(21);
  margin-right: $share-component-spacer; // assuming that the share button is always there

  @media (min-width: 99.4375em) {
    // 1591
    margin-right: 0;

    // Different share spacing needed for Office Articles, due to HTML structure
    .share + .article-title-card & {
      margin-right: $share-component-spacer;
    }
  }
}

.article-title-card__subtitle {
  margin-bottom: rem(21);
  margin-right: $share-component-spacer; // assuming that the share button is always there, to match the title

  @media (min-width: 99.4375em) {
    // 1591
    margin-right: 0;
  }

  .wf-loading & {
    @include font(futura-fallback, light);
    font-size: rem(28);
    line-height: 1.416;
    letter-spacing: rem(-1.45);
  }

  .wf-active & {
    @include font(futura, light);
    font-size: rem(32);
    line-height: 1.235;
  }

  @media (min-width: $bp-min-small-tablet) {
    .wf-loading & {
      font-size: rem(31);
      line-height: 1.446;
      letter-spacing: rem(-0.7);
    }

    .wf-active & {
      font-size: rem(38);
      line-height: 1.184;
    }
  }
}

.article-title-card__author {
  align-items: center;
  display: flex;
  font-size: rem(16);
  line-height: 1.625;

  a {
    border-bottom-color: transparent;
    border-top: 4px solid transparent; /* easy way to vertically balance for centering */
    font-weight: 300;

    &:hover,
    &:focus {
      border-bottom-color: currentColor;
    }
  }

  img {
    border-radius: 50%;
    display: block;
    height: rem(80);
    margin-right: $standard-side-margin;
    width: rem(80);
  }

  @media print {
    display: block;
  }
}

.article-title-card__date,
.article-title-card__info {
  font-size: rem(14);
  padding-left: rem(38);
  position: relative;
  text-transform: uppercase;

  a {
    border-bottom-color: transparent;
    border-top: 4px solid transparent; /* easy way to vertically balance for centering */
    font-weight: 300;

    &:hover,
    &:focus {
      border-bottom-color: currentColor;
    }
  }

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: rem(21);
    position: absolute;
    left: rem(19);
    top: calc(50% - #{rem(11)});
    width: 1px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:first-child:before {
    display: none;
  }
}

.article-title-card__dateline {
  align-items: center;
  display: flex;

  @media print {
    display: block;
  }
}

.article-title-card__date {
  @media print {
    color: $black;
    padding-left: 0;

    &:before {
      display: none;
    }
  }
}
