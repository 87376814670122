.event-list-item {
  border-bottom: 1px solid currentColor;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-weight: 300;
  margin-bottom: rem(39);
  padding-bottom: rem(39);

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: $bp-min-small-tablet) {
    flex-wrap: nowrap;
  }

  @media print {
    display: block;
    margin-bottom: $print-standard-text-whitespace;
    padding-bottom: $print-standard-text-whitespace;

    break-inside: avoid-page;
    page-break-inside: avoid;
  }
}

.event-list-item__date,
.event-list-item__time {
  font-size: rem(14);
  text-transform: uppercase;
  letter-spacing: 0.7px;

  /* stylelint-disable declaration-no-important */
  @media print {
    width: auto !important;
    padding-right: 0 !important;
    margin-bottom: 0 !important;
    color: inherit !important;

    & > span {
      display: inline-block !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      margin-right: 0.5ch;
      width: auto !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.event-list-item__date {
  border-right: 2px solid currentColor;
  line-height: 1.571;
  padding-right: rem(11);
  margin-bottom: rem(21);
  margin-right: $standard-side-margin;
  min-width: rem(109);
  width: rem(109);

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }

  & > span {
    display: inline-block;
    width: 100%;
  }

  @media print {
    border-right: none;
    line-height: inherit;
    padding-right: 0;
    margin-bottom: 0;
    width: auto;
  }
}

.event-list-item__time {
  line-height: 1.2;
  padding-right: rem(32);
  min-width: rem(120);
  width: rem(120);
}

.event-list-item__time-entry {
  display: inline-block;
  width: 100%;
}

.event-list-item__summary {
  @media (min-width: $bp-min-small-tablet) {
    display: flex;
    justify-content: space-between;
    max-width: 60ch;
  }
}

.event-list-item__body {
  display: block;

  @media (min-width: $bp-min-small-tablet) {
    display: inline-block;
  }
}

.event-list-item__image {
  display: block;
  margin-bottom: rem(21);
  margin-right: rem(31);
  width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
    width: rem(210);
  }

  img {
    display: block;
  }

  @media print {
    display: none;
  }
}

.event-list-item__date-month-day {
  @include font(stevie, bold);

  font-size: rem(20);
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: transform 0.2s;
  transform-origin: left center;
  white-space: nowrap;

  .event-list-item:hover & {
    transform: scale(1.15, 1.15);

    @media print {
      transform: none;
    }
  }
}

.event-list-item__divider {
  @include font(stevie, bold);

  font-size: rem(20);
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.event-list-item__location {
  display: inline-block;
  font-size: rem(14);
  position: relative;
  top: rem(-7); /* Snug up to title without breaking title's line height */
  width: 100%;

  @media print {
    &:has(span:empty) {
      display: none;
    }
  }
}

.event-list-item__title {
  display: inline-block;
  width: 100%;

  a {
    font-size: rem(20);
    line-height: 1.364;
    border-bottom: rem(4) solid transparent;
    color: $drexel-grey-headline;

    .wf-loading & {
      @include font(stevie-fallback, semibold);
      letter-spacing: rem(-0.15);
    }

    .wf-active & {
      @include font(stevie, semibold);
    }

    .event-list-item:focus &,
    .event-list-item:hover &,
    &:focus,
    &:hover {
      border-bottom-color: rgba($drexel-grey-text, 0.5);
    }
  }
  @media print {
    a:after {
      display: block;
    }
  }
}

.event-list-item__full {
  @include font(stevie, regular);

  display: inline-block;
  font-size: rem(13);
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: rem(5);
  padding: rem(5);
  text-align: center;
  width: rem(42);
}
