.breadcrumbs {
  @include component-normalize();
  @include componentize();

  display: none;
  font-size: rem(15);
  line-height: 2.267;
  margin-top: rem(28);

  .page-segment & {
    margin: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    display: block;

    .page-segment & {
      margin-top: rem(-3);
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    .page-segment & {
      margin-left: rem(-117);
    }
  }

  @media print {
    display: none;
  }

  a {
    border-bottom: none;
    font-weight: 500;
  }
}

.breadcrumbs__list {
  @include zero-list;
  font-size: rem(14);
}

.breadcrumbs__item {
  display: inline-block;
  margin-bottom: 0;

  &:after {
    content: "/";
    display: inline-block;
  }

  &:last-child:after {
    content: "";
  }
}
