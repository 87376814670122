.person-contact-card {
  .expert-promo + &,
  & + & {
    border-top: 1px solid $drexel-grey-soft;
    margin-top: $base-component-mobile-whitespace;
    padding-top: $base-component-mobile-whitespace;
  }

  .contact-card-set__cards & {
    width: 100%;
  }

  @media (min-width: $bp-min-small) {
    display: flex;

    .contact-card-set__cards & {
      display: block;
      width: 50%;

      &:nth-child(2) {
        border: none;
        margin-top: 0;
        padding-top: 0;
      }

      &:nth-child(2n - 1) {
        padding-right: calc(#{$promo-spacer} / 2);
      }

      &:nth-child(2n) {
        padding-left: calc(#{$promo-spacer} / 2);
      }
    }
  }

  @media (min-width: $bp-min-medium) {
    .contact-card-set & {
      display: flex;
    }
  }

  @media print {
    display: block;
  }
}

.person-contact-card__image-wrapper {
  margin-bottom: 1.5rem;
  width: 100%;

  @media (min-width: $bp-min-small) {
    margin-bottom: 0;
    margin-right: rem(32);
    width: rem(210);

    .contact-card-set & {
      margin-bottom: 1.5rem;
      width: 100%;
    }
  }

  @media (min-width: $bp-min-medium) {
    .contact-card-set & {
      margin-bottom: 0;
      width: rem(210);
    }
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-text-whitespace !important;

    img {
      max-width: rem(210);
    }
  }
  /* stylelint-enable declaration-no-important */
}

.person-contact-card__info {
  @media (min-width: $bp-min-small-tablet) {
    width: calc(100% - #{rem(242)});
  }

  .contact-card-set & {
    @media (min-width: $bp-min-small-tablet) {
      width: 100%;
    }

    @media (min-width: $bp-min-medium) {
      width: calc(100% - #{rem(242)});
    }
  }
}

.person-contact-card__name {
  --link-color: $drexel-gray-headline;
  @include font(stevie, regular);
  color: $drexel-grey-headline;
  font-size: rem(20);
  line-height: 1.5;

  .wf-active & {
    font-weight: 600;

  }

  a {
    border-bottom: 0.25rem solid var(--link-border, rgba(0, 98, 152, 0.5));

    .wf-active & {
      color: $drexel-blue;
      border-bottom: 0.25rem solid var(--link-border, rgba(0, 98, 152, 0.5));
    }
  }

  @media print {
    margin-bottom: 0;
  }
}

.person-title-card__title {
  @include font(stevie, regular);
  font-weight: 400;
  color: $grey-800;
  font-size: rem(20);

  @media print {
    margin-bottom: 0;
  }
}

.person-contact-card__pronouns {
  color: $grey-600;
  font-weight: 400;


  .wf-loading & {
    @include font(stevie, regular);
    font-size: rem(19);
    line-height: 1.6;
    letter-spacing: rem(-0.05);
  }

  .wf-active & {
    @include font(stevie, regular);
    font-size: rem(19);
    line-height: 1.267;
    font-weight: 400; 
  }
}

.person-contact-card__contact-info {
  margin-top: rem(21);

  @media (min-width: $bp-min-xxlarge) {
    display: flex;
    justify-content: space-between;

    .contact-card-set {
      display: block;
    }
  }
}

.person-contact-card__location,
.person-contact-card__links {
  width: 100%;

  @media (min-width: $bp-min-xsmall) {
    width: calc(50% - (#{$promo-spacer} / 2));
  }

  @media (min-width: $bp-min-small) {
    width: 100%;
  }

  @media (min-width: $bp-min-xxlarge) {
    width: calc(50% - (#{$promo-spacer} / 2));
  }
}

.person-contact-card__location {
  font-size: rem(17);
  line-height: 1.588;
}

.person-contact-card__links {
  .icon-link {
    display: block;
    font-size: rem(15);
    line-height: 1.588;
    margin: 0;

    &:last-child {
      margin-bottom: rem(16);
    }

    .icon-link__icon,
    svg {
      height: rem(17);
      width: rem(17);
    }

    .icon-link__icon {
      top: 3px;
    }

    & + .icon-link {
      margin-left: 0;
    }
  }
}

.person-contact-card__bio {
  p,
  ul {
    max-width: 50ch;
  }
}
