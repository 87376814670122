.event-sidebar {
  @include componentize();

  border-left-width: 4px;
  border-left-style: solid;
  padding-left: 40px;

  @media (min-width: $bp-min-small-tablet) {
    margin: 0;
    max-width: none;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    border: none !important;
    padding-left: 0;
  }
  /* stylelint-enable declaration-no-important */
}

.event-sidebar__item {
  margin-bottom: rem(42);

  @media print {
    margin-bottom: $print-standard-text-whitespace;
  }
}

.event-sidebar__feature-title {
  @include font(futura, demi);

  font-size: rem(20);
  letter-spacing: 1.6px;
  line-height: 1.4;
  text-transform: uppercase;
}

.event-sidebar__feature-value {
  font-family: $stevie;
  font-size: rem(18);
  font-weight: 300;
  line-height: 1.722;
}
