.tour-stop {
  margin-bottom: $base-component-whitespace;
  position: relative;
  z-index: $stack-overlaps;

  &:last-child {
    margin-bottom: 0;
  }
}

.tour-stop__body {
  margin: 0 auto;
  width: calc(100% - #{$double-side-margin});

  @media (min-width: $bp-min-xxlarge) {
    margin-right: rem(170);
    max-width: rem(810);
  }

  .rich-text {
    @include zero();

    margin-bottom: rem(37);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    width: auto;

    .rich-text {
      margin-bottom: $print-standard-text-whitespace;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.tour-stop__image {
  margin-bottom: rem(42);
  width: 100%;

  picture,
  img {
    height: auto;
    width: 100%;
  }
}

.tour-stop__title {
  margin: 0 0 rem(21);
}

.tour-stop__videos {
  @include zero();

  list-style-type: none;

  @media print {
    display: none;
  }
}

.tour-stop__video {
  button {
    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    text-decoration: none;
    text-transform: uppercase;
    justify-content: flex-start;
    width: auto;

    .wf-loading & {
      @include font(futurab-fallback, demi);
      font-size: rem(13);
      line-height: 1.163;
      letter-spacing: rem(2.55);
    }

    .wf-active & {
      @include font(futurab, demi);
      font-size: rem(15);
      line-height: 1;
      letter-spacing: rem(2.25);
    }

    .tour-stop__button-text {
      border-bottom: rem(4) solid transparent;
      border-top: rem(4) solid transparent; /* bit of a hack to recenter things */
      display: inline-block;
      margin-left: rem(17);
    }
  }

  svg {
    display: block;
    height: rem(44);
    margin-right: rem(11);
    width: rem(44);
  }
}

.tour-stop__video-drawer {
  display: none;
  padding-top: rem(42);
}

.tour-stop__video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Animated Play Button */
.tour-stop__play {
  display: block;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 3px solid black;
  position: relative;
  transition: transform 0.3s;
}

.tour-stop__play:after,
.tour-stop__play:before,
.tour-stop__play .tour-stop__line {
  transition: height 0.3s, width 0.3s, border-width 0.3s, top 0.3s, left 0.3s,
    transform 0.3s;

  background-color: black;
  border-radius: 3px;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 25px;
  width: 3px;
  position: absolute;
  top: 7.5px;
  left: 12px;
}

.tour-stop__play .tour-stop__triangle {
  border-style: solid;
  border-width: 12px 0 12px 21px;
  border-color: transparent transparent transparent black;

  opacity: 1;
  position: absolute;
  top: 8px;
  left: 12px;
  transition: opacity 0.2s;
}

.tour-stop__play:after {
  left: 22px;
  top: 2px;
  transform: rotate(120deg);
}

.tour-stop__play:before {
  left: 22px;
  top: 13px;
  transform: rotate(60deg);
}

.tour-stop__play.is-open {
  transform: rotate(45deg);
}

.tour-stop__play.is-open .tour-stop__line {
  height: 31px;
  left: 19px;
  top: 4px;
}

.tour-stop__play.is-open:after {
  height: 15px;
  transform: rotate(90deg);
  left: 12px;
  top: 12px;
}

.tour-stop__play.is-open:before {
  height: 15px;
  transform: rotate(270deg);
  left: 27px;
  top: 12px;
}

.tour-stop__play.is-open .tour-stop__triangle {
  opacity: 0;
}
