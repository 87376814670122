.header-logo__left {
  &:has(.header__office-logo) {
    @media (max-width: $bp-max-small) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 $standard-side-margin;
    }
  }
  
  .header__office-text {
    text-align: left;
    color: $black;
    margin-left: 1rem; 
    font-size: 2rem;
    margin-bottom: 0;
    line-height: 1;

    @media (max-width: $bp-max-small) {
      font-size: 1.5rem;
    }

    &.futura--font {
      font-family: $futura;
    }

    &.stevie-sans--font {
      font-family: $stevie;
    }
  
    .header__office-text-bold {
      font-weight: bold;
    }
  }
}

.header__right {

  &:has(.header-office__branding) {
    .header-office__mobile-navigation {
      justify-content: end;
    }



    .header-office__utility-navigation {
      @media (min-width: $bp-min-small-tablet) {
        margin-top: 0;
      }
    }
  }

  .header-office__mobile-navigation {
    width: 100%;
    justify-content: space-between;

    .search-trigger {
      padding-right: 0;
    }

    .hamburger {
      padding-left: 0;
    }
  }
}

.header__utility-navigation {

  &.header-office__utility-navigation {
    @media (min-width: $bp-min-small-tablet) {
      margin-top: 4rem;
    }
  }
}