.search-listing {
  @include componentize();

  position: relative;
  //z-index: $stack-overlaps; // disabled because datepicker was colliding with nav

  // We need to remove position:relative for the video grid variation,
  // so that the expanding drawer will expand to the edges of the browser.
  .dynamic-video-grid & {
    position: static;
  }

  // To avoid collision with top nav on phone/tablet,
  // on search pages with breadcrumbs and no page titles (DR-640)
  .breadcrumbs + & {
    margin-top: rem(28);

    @media (min-width: $bp-min-small-tablet) {
      margin-top: 0;
    }
  }

  input[type="checkbox"] + label,
  input[type="radio"] + label {
    @include font(stevie, light);

    display: block;
    font-size: rem(17);
    letter-spacing: normal;
    line-height: 1.529;
    text-transform: none;
  }
}

.search-listing__app {
  max-width: 100%;

  @media (min-width: $bp-min-medium) {
    display: grid;
    grid-template-columns: calc(100% - #{rem(476)}) rem(140) rem(336);
    grid-template-rows: minmax(0, rem(63)) rem(42) auto;
  }
}

.search-listing__date {
  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.search-listing__date--desktop {
  align-self: end;
  display: none;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  padding-bottom: rem(11);
  position: relative;

  &.search-listing__date--wide {
    grid-column-end: 3;
  }

  @media (min-width: $bp-min-medium) {
    display: block;
  }
}

.search-listing__date--mobile {
  display: block;

  @media (min-width: $bp-min-medium) {
    display: none;
  }
}

.search-listing__facets {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: span 3;

  margin-bottom: rem(28);
  position: sticky;
  top: rem(21);
  z-index: $stack-overlaps;

  & > div {
    // Another Reacty div wrapper here.
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: $bp-min-medium) {
    margin-bottom: 0;
    max-height: none;
    position: static;
    z-index: $stack-reset;
  }
}

.search-listing__visit-facets {
  align-self: end;
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: span 1;
  padding-bottom: rem(11);
}

.search-listing__horizontal-facets {
  align-self: end;
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: 1;
  grid-row-end: span 1;
  padding-bottom: rem(11);
}

.search-listing__results {
  align-self: start;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}

.search-listing__rule {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  grid-row-end: 2;
  margin-top: rem(25);
  padding-bottom: rem(44);
  position: relative;

  &.search-listing__rule--fullwidth {
    grid-column-end: span 3;

    &::after {
      @media (min-width: $bp-min-medium) {
        width: 100%;
      }
    }
  }

  @media (min-width: $bp-min-medium) {
    margin-top: 0;
  }

  &::after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media (min-width: $bp-min-medium) {
      width: calc(100% + 30px);
    }
  }
}

.search-listing__date-quick-picks {
  display: none;
  margin: 0 rem(11) rem(4) 0;
  min-width: 460px;

  .search-listing__facets & {
    margin: 0;
    min-width: 0;
  }

  @media (min-width: $bp-min-medium) {
    display: block;
  }
}

.search-listing__date-calendar-picker {
  display: inline-block;
  font-size: rem(14);
  line-height: 1.529;
  margin: 0 0 rem(4);
  position: relative;

  & > div {
    // React datepicker adds a random div here
    display: inline-block;
  }

  .search-listing__facets & {
    min-width: 100%;
  }

  label {
    @include font(stevie, light);

    font-size: rem(14);
    line-height: 1.529;
    text-transform: none;
  }
}

.search-listing__date-calendar-picker-to {
  display: inline-block;
  font-size: rem(15);
  padding: 0 rem(11);

  .search-listing__facets & {
    margin-bottom: rem(11);
  }
}

.search-listing__picker-label {
  font-size: rem(13);
  position: absolute;
  left: rem(7);
  top: 50%;
  transform: translate(0, -50%);
}

.search-listing__pickers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search-listing__icon {
  position: absolute;
  right: rem(7);
  top: 50%;
  transform: translate(0, -50%);
}

.search-listing__date-native-picker {
  position: relative;
  display: inline-block;

  input {
    @include font(stevie, light);

    border: 1px solid $black;
    font-size: rem(14);
    height: rem(40);
    line-height: rem(38);
    margin-left: rem(44);
    margin-right: rem(32);
    width: rem(189);
  }

  .search-listing__date-calendar-picker--start input {
    margin-left: rem(50);
    margin-right: rem(32);
  }
}

.search-listing__date-toggle {
  @include zero();

  @include font(stevie, regular);

  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: rem(17);
  line-height: 1.471;
  margin-bottom: rem(11);

  &::after {
    @include triangle(rem(6), rem(5));

    content: "";
    display: inline-block;
    margin-left: rem(11);
    transform: rotate(90deg);
    transition: transform 0.3s;
  }

  &.is-open::after {
    transform: rotate(270deg);
  }
}

.search-listing__sidebar {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  margin-top: $base-component-mobile-whitespace;

  @media (min-width: $bp-min-medium) {
    margin-top: 0;
  }
}

.search-listing__searchbox {
  border-bottom: 1px solid $drexel-grey-soft;
  padding-bottom: rem(32);
  margin-bottom: rem(32);

  .search-box {
    border: 1px solid currentColor;
    max-width: rem(744);
  }

  label,
  input {
    font-size: rem(21);
  }

  @media print {
    border: none;
    padding: 0;
    margin: 0;
  }
}

.search-listing--simple {
  max-width: $max-width-rems;

  // adding some margins in case of columnar layout
  .layout-columns--70-30 &,
  .layout-columns--30-70 & {
    margin-top: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: 0;
    }
  }

  .search-box {
    @media (min-width: $bp-min-small-tablet) {
      margin-right: rem(55); // Making room for clearing X
    }
  }

  .search-listing__rule {
    display: none;

    @media (min-width: $bp-min-medium) {
      display: inline-grid;
      grid-column-end: 4;

      &::after {
        width: 100%;
      }
    }
  }

  .search-results__title-bar {
    justify-content: flex-end;
  }

  .search-results__title-bar h3 {
    display: none;
  }

  @media (min-width: $bp-min-medium) {
    .search-listing__app {
      grid-template-rows: none;
      grid-template-columns: 100% 0 0;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    .event-list-item__image {
      width: auto; // Prevents horizontal scrollbar in simple 30/70
    }
  }
}

// Not good BEM, but looking for a fairly central place to put broader search-related print styles (instead of overriding every single individual variation)
/* stylelint-disable declaration-no-important */
@media print {
  [class*="facets"],
  [class*="listing__rule"] {
    display: none !important;
  }
}
/* stylelint-enable declaration-no-important */
