.directory-list-item {
  border-bottom: 1px solid $drexel-grey-soft;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: rem(39);
  padding-bottom: rem(39);

  @media (min-width: $bp-min-large) {
    display: flex;
  }

  @media print {
    display: block;
    margin-bottom: $print-standard-text-whitespace;
    padding-bottom: $print-standard-text-whitespace;

    break-inside: avoid-page;
    page-break-inside: avoid;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

.directory-list-item__relevance {
  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: rem(14);
  white-space: nowrap;

  @media (min-width: $bp-min-large) {
    margin-bottom: 0;
    margin-right: rem(29);
    width: rem(105);
  }
}

.directory-list-item__content {
  width: 100%;

  @media (min-width: $bp-min-small) {
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
  }

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }

  @media (min-width: $bp-min-medium) {
    display: flex;
  }

  @media print {
    display: block;
  }
}

.directory-list-item__info {
  @media (min-width: $bp-min-small) {
    display: flex;
    flex-wrap: wrap;

    .directory-list-item__image + .directory-list-item__body & {
      display: block;
    }
  }

  @media (min-width: $bp-min-xlarge) {
    .directory-list-item__image + .directory-list-item__body & {
      display: flex;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
  }
  /* stylelint-enable declaration-no-important */
}

.directory-list-item__name {
  display: inline-block;
  margin-bottom: rem(21);

  a {
    border-bottom: 0.25rem solid var(--link-border, rgba(0, 98, 152, 0.5));

  }

  a,
  span {
    font-weight: 400;
    line-height: 1.364;

    .wf-loading & {
      @include font(stevie-fallback, semibold);
      letter-spacing: rem(-0.15);
    }

    .wf-active & {
      @include font(stevie, semibold);
      font-size: 1.125rem;
    }
  }

  .directory-list-item__pronouns {
    color: $grey-600;
    margin-bottom: 0;

    .wf-loading & {
      @include font(stevie-fallback, semibold);
      letter-spacing: rem(-0.15);
    }

    .wf-active & {
      @include font(stevie, semibold);
      font-weight: 400;
      font-size: 1.125rem;
    }
  }

  .directory-list-item__title {
    font-size: rem(20);
    color: $drexel-grey-headline;
    margin-bottom: 0;

    .wf-active & {
      font-weight: 300;
    }
  }

  span {
    color: $drexel-grey-headline;
  }

  a {
    color: $drexel-blue;

    .event-list-item:focus &,
    .event-list-item:hover &,
    &:focus,
    &:hover {
      color: $drexel-blue-dark;
      border-bottom-color: rgba($drexel-grey-text, 0.5);
    }
  }

  @media print {
    margin-bottom: 0;

    a:after {
      display: block;
    }
  }
}

.directory-list-item__contact {
  font-size: rem(15);
  line-height: 1.5;
  margin-bottom: rem(21);

  @media (min-width: $bp-min-small) {
    margin-bottom: 0;
    width: 60%;

    .directory-list-item__image + .directory-list-item__body & {
      margin-bottom: rem(21);
      width: 100%;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: rem(21);
    width: 100%;
  }

  @media (min-width: $bp-min-medium) {
    margin-bottom: 0;
    width: 60%;

    .directory-list-item__image + .directory-list-item__body & {
      margin-bottom: rem(21);
    }
  }

  @media (min-width: $bp-min-xlarge) {
    .directory-list-item__image + .directory-list-item__body & {
      margin-bottom: 0;
      width: 56%;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: 0 !important;
  }
  /* stylelint-enable declaration-no-important */

  .icon-link {
    font-size: rem(15);
    line-height: 1.733;
  }

  .icon-link__icon {
    height: rem(17);
    margin-right: rem(15);
    top: 4px;
    width: rem(17);

    svg {
      height: rem(17);
      width: rem(17);
    }
  }
}

.directory-list-item__location {
  font-size: rem(15);
  line-height: 1.733;

  @media (min-width: $bp-min-xlarge) {
    max-width: calc(100% - #{rem(252)});
  }
}

.directory-list-item__image {
  margin-bottom: rem(14);
  width: 100%;

  @media (min-width: $bp-min-small) {
    margin-bottom: 0;
    margin-right: rem(42);
    width: rem(210);
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: rem(14);
    margin-right: 0;
    width: 100%;
  }

  @media (min-width: $bp-min-medium) {
    margin-bottom: 0;
    margin-right: rem(42);
    width: rem(210);
  }

  img {
    height: auto;
    width: 100%;
  }

  @media print {
    width: rem(210);
    margin-bottom: $print-standard-text-whitespace;

    img {
      max-width: rem(210);
    }
  }
}

.directory-list-item__body {
  width: 100%;

  .directory-list-item__image + & {
    @media (min-width: $bp-min-small) {
      width: calc(100% - #{rem(252)});
    }

    @media (min-width: $bp-min-small-tablet) {
      width: 100%;
    }

    @media (min-width: $bp-min-medium) {
      width: calc(100% - #{rem(252)});
    }
  }
  /* stylelint-disable declaration-no-important */
  @media print {
    width: auto !important;
  }
  /* stylelint-enable declaration-no-important */
}
