.subfooter {
  @include componentize();

  a:not(.btn) {
    @include font(stevie, regular);

    border-bottom-color: transparent;
    font-size: rem(17);
    line-height: 1.471;

    &:hover,
    &:focus {
      border-bottom-color: currentColor;
    }

    &.btn {
      @include font(stevie, semibold);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    break-inside: avoid;
    page-break-inside: avoid;
    border-top: 1px solid $black;
    max-width: none;
    padding-top: $print-standard-text-whitespace;

    &,
    & *:not(h2) {
      font-family: inherit !important;
      font-size: inherit !important;
      text-align: center !important;
    }

    .subfooter__title {
      text-align: center !important;
      margin-top: 0 !important;
    }

    p > .btn {
      display: inline !important;
      margin-left: 0 !important;

      &:after {
        display: block !important;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.subfooter__columns {

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
    margin-left: -55px;
    margin-right: -55px;
  }

  @media print {
    display: block;
  }
}

.subfooter__column {
  border-bottom: none;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $base-half-component-whitespace;

  @media (max-width: $bp-min-small-tablet) {
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  }


  .subfooter--top-align & {
    justify-content: flex-start;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: $bp-min-small-tablet) {
    border-left: 1px solid rgba($drexel-grey-light, 0.2);
    margin-bottom: 0;
    padding: 0 55px;

    // Groups of 2
    &:nth-last-child(2):first-child {
      width: 40%; // 1/3 width doesn't work well with longer email addresses
    }
    &:last-child:nth-child(2) {
      width: 60%;
    }
    &:first-child:has(.footer-quick-links) {
      width: 100%; 
    }

    // Styling with 1/3 width only for groups of 3
    &:nth-last-child(3):first-child,
    &:nth-last-child(3) ~ .subfooter__column {
      width: calc(100% / 3);
    }
  }

  &:first-child {
    @media (min-width: $bp-min-small-tablet) {
      border-left: none;
    }
  }

  & > * {
    margin: 0 0 rem(25);
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    margin-bottom: 0 !important;
    border: none !important;
    width: auto !important;
    padding: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}
