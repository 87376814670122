.student-story-card {
  border-top: 1px solid $drexel-grey-soft;
  padding-top: 22px;
}

.student-story-card__name {
  font-family: $futura;
  font-size: 38px;
  line-height: 45px;
  font-weight: 300;
}

.student-story-card__class {
  font-family: $stevie;
  font-weight: 700;
  font-size: 21px;
  line-height: 34px;
  margin-top: 21px;
}

.student-story-card__info-list {
  margin-top: 21px;
}
