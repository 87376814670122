// Author social links

.author-social__link {
  display: inline-block;
  vertical-align: middle;
  margin: 20px 20px 0 0;
  font-size: 0;

  a {
    display: block;
    text-decoration: none;
    border-bottom: none;
  }

  .icon-link__icon {
    float: none;
    display: block;
    position: static;
    margin-right: 0;
    top: 0;
    height: auto;
    width: auto;
    max-width: rem(24);
  }
}
