@import "../themes/base/_variables.scss";

.stat-bar {
  font-family: $futura;
  color: #07294d;
}

@include create-gradient-custom-props($core-theme-gradient-colors, "stat-bar");

.stat-bar__bar {
  width: 100%;
  height: 13px;
  background-color: #f1f1f1;
  background-image: linear-gradient(
    to right,
    var(--start-color, #ffc501),
    var(--end-color, #ff901c)
  );
  background-size: calc(var(--percent) * 1%) 100%;
  background-repeat: no-repeat;

  .stat-bar:not(.is-shifted) & {
    animation: bar-fill 1s linear forwards;
  }

  .container--greyband & {
    background-color: $grey-400;
  }

  @media print {
    display: none;
  }
}

@keyframes bar-fill {
  0% {
    background-position: left
      calc(var(--percent) / (var(--percent) - 100) * 100%) top 0;
  }
  100% {
    background-position: left 0% top 0;
  }
}

.stat-bar__data {
  line-height: 1;
  display: flex;
  align-items: baseline;
  font-weight: 300;

  @media print {
    display: inline;
    font-weight: 700;
  }
}

.stat-bar__value {
  font-size: 115px;
  line-height: 1.287;

  @media print {
    font-size: inherit;
    line-height: inherit;
  }
}

.stat-bar__suffix {
  font-size: 69px;

  @media print {
    font-size: inherit;
    line-height: inherit;
  }
}

.stat-bar__text {
  margin-top: 17px;
  font-size: 1.75rem;
  line-height: 1.4;

  @media (min-width: $bp-min-medium) {
    font-size: 2rem;
  }

  @media print {
    display: inline;
    margin-top: 0;
    font-size: inherit;
    line-height: inherit;
  }
}
