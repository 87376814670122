.icon-link {
  @include font(stevie, regular);

  display: inline-block;
  font-size: rem(18);
  line-height: rem(24);
  text-decoration: none;

  &:not(.btn) {
    border-bottom: none;
  }

  svg {
    display: block;
    height: rem(21);
    width: rem(21);
  }

  & + .icon-link {
    margin-left: rem(43);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    text-decoration: underline !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-size: inherit;

    &[href*="map"] {
      display: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.dark_background .btn.btn--secondary .icon-link__icon,
.accent_background .btn.btn--primary .icon-link__icon,
.light_background .btn.btn--primary .icon-link__icon{
  color: $black;
}

.dark_background .btn.btn--secondary svg,
.accent_background .btn.btn--primary svg,
.light_background .btn.btn--primary svg {
  transition: color $standard-transition;
}
.dark_background .btn.btn--secondary:hover svg {
  color: $white;
  transition: all $standard-transition;
}

.accent_background .btn.btn--primary:hover svg {
  color: $white;
  transition: all $standard-transition;
}

.light_background .btn.btn--primary:hover svg {
  color: $white;
  transition: all $standard-transition;
}

.icon-link__icon {
  display: flex;
  float: left;
  height: rem(21);
  line-height: rem(21);
  margin-right: rem(14);
  position: relative;
  top: 1px;
  //width: rem(36);
  //transition: color $standard-transition;

  @media print {
    display: none;
  }
  svg {
    width: 100%;
    height: auto;
    display: flex;
    //no transition heref

    g{
      width: 100%;
      justify-content: start;
    }
  }
}

.icon-link__text {
  display: inline-block;
  line-height: rem(24);

  &.icon-link__text--long-text {
    display: none;

    @media (min-width: $bp-min-small) {
      display: inline-block;
    }
  }

  &.icon-link__text--short-text {
    display: inline-block;

    @media (min-width: $bp-min-small) {
      display: none;
    }
  }
}
