@import "drexel/base/_functions";

.video-typeahead {
  margin-bottom: rem(22);

  h4,
  .txt-h4 {
    margin-bottom: rem(9);
  }

  label {
    font-weight: 500;
    font-size: rem(16);
    line-height: 1.5;
  }

  .video-typeahead__control {
    border-radius: 0;

    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJBAMAAADJBLEBAAAAAXNSR0IB2cksfwAAABhQTFRFAAAAbKzkbKzkbKzkcK/fbKzkaqrkba3kkPGwtQAAAAh0Uk5TAKD/0BDwMGDqVCa+AAAAPklEQVR4nGMQUgIDRQZGCEOAgcEIRCszMDA4gxgmQAZLkJKSqgOQwZCqpBQGohnYlJQSwAyGInUIzcBeACQASooHcZyxOe8AAAAASUVORK5CYII=");
    background-repeat: no-repeat;
    background-position: 98% 50%;

    @media (min-width: $bp-min-large) {
      background-position: 95% 50%;
    }
  }

  .video-typeahead__value-container {
    @media (min-width: $bp-min-large) {
      padding-right: 30px;
    }
  }

  .video-typeahead__control.video-typeahead__control--is-focused {
    box-shadow: none;
    outline: none;
  }

  .video-typeahead__indicator-separator {
    display: none;
  }

  .video-typeahead__dropdown-indicator {
    display: none;
  }

  .video-typeahead__placeholder + * {
    margin: 0;
  }
}
