.content-and-sidebar {
  @include containerize();

  width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    display: grid;
    grid-template-columns: 66% 6% 28%;
    grid-template-rows: auto auto auto;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: $bp-min-xxlarge) {
    display: grid;
    grid-template-columns:
      calc((100% - #{$max-width-rems}) / 2) rem(905) #{$base-double-component-whitespace} rem(
        375
      )
      calc((100% - #{$max-width-rems}) / 2);
    grid-template-rows: auto auto auto;
  }

  /* if an image block is the first child, overlap that up */
  .rich-text .image-block:first-child {
    @media (min-width: $bp-min-small-tablet) {
      @include overlap("margin-up", rem(-63));
    }
  }

  @media print {
    display: flex;
    flex-direction: column;
  }
}

.content-and-sidebar__intro {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;

  @media (min-width: $bp-min-xxlarge) {
    grid-column-start: 2;
  }

  @media print {
    order: 1;
  }
}

.content-and-sidebar__greybox {
  background-color: $drexel-grey-pale;
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  margin-bottom: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
    padding-right: rem(42);
  }

  @media (min-width: $bp-min-xxlarge) {
    grid-column-start: 1;
    grid-column-end: span 2;
    padding-left: calc(100% - #{rem(905)});
    padding-right: $base-double-component-whitespace;
  }

  @media print {
    order: 2;
  }
}

.content-and-sidebar__sidebar {
  grid-column-start: 3;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 3;
  padding-right: $standard-side-margin;

  @media (min-width: $bp-min-xxlarge) {
    grid-column-start: 4;
    padding-right: 0;
  }

  // Make space for the share
  .share ~ .content-and-sidebar & {
    @include spaceForShare($bp-min-small-tablet, $bp-min-xxxlarge);
  }

  @media print {
    order: 3;
  }
}

.content-and-sidebar__content {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 3;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    padding-right: rem(42);
  }

  @media (min-width: $bp-min-xxlarge) {
    grid-column-start: 2;
    padding-right: $base-double-component-whitespace;
  }

  @media print {
    order: 4;
    max-width: 60ch;
  }
}
