.quote-promo {
  margin-bottom: $base-component-mobile-whitespace;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: $base-component-whitespace;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media print {
    padding: 0 1cm;
    margin-bottom: $print-standard-component-whitespace;
    display: block !important;
    position:  relative !important;
  }

  .promo-grid & {
    margin-bottom: 0;
  }
}

.quote-promo__quote-marks {
  display: block;
  height: rem(75);
  margin-bottom: rem(11);
  margin-right: 0;
  width: rem(75);

  svg {
    height: rem(75);
    width: rem(75);
  }

  @media print {
    &,
    svg {
      height: rem(40);
      width: rem(40);
    }

    svg path {
      fill: $black;
    }
  }
}

.quote-promo__quote {
  font-size: rem(26);
  line-height: 1.667;
  margin-bottom: rem(21);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futurab-fallback, demi);
    letter-spacing: rem(-0.65);
    word-spacing: rem(-0.15);
  }

  .wf-active & {
    @include font(futurab, demi);
    letter-spacing: rem(1);
  }

  @media print {
    font-size: rem(18);
    @include print-style-general();
  }
}

.quote-promo__attribution {
  @include clearfix;

  .wf-loading & {
    @include font(futurac-fallback, book);
    font-size: rem(20);
    line-height: 1.468;
    letter-spacing: rem(-1);
  }

  .wf-active & {
    @include font(futurac, book);
    font-size: rem(23);
    line-height: 1.261;
  }

  @media print {
    font-size: rem(18);
    @include print-style-general();
  }
}

.quote-promo__image {
  float: left;
  margin-right: $standard-side-margin;

  img {
    border-radius: 50%;
    height: rem(64);
    width: rem(64);
  }
}

// Variations

// Centered version, seen in rich text in the designs
.quote-promo--centered {
  margin-left: auto;
  margin-right: auto;
  max-width: rem(690);
}

// Smaller version, with quote marks to the left
.quote-promo--small {
  &:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
  }

  .quote-promo__quote-marks {
    float: left;
    height: rem(55);
    margin-right: $standard-side-margin;
    margin-bottom: rem(5);
    width: rem(55);

    svg {
      float: left;
      height: rem(55);
      width: rem(55);
    }
  }

  .quote-promo__quote {
    font-size: rem(18);

    .wf-loading & {
      @include font(futurab-fallback, demi);
      letter-spacing: rem(-0.15);
      word-spacing: rem(-0.05);
    }

    .wf-active & {
      @include font(futurab, demi);
      letter-spacing: rem(1);
    }
  }
}

// Overlap right
.quote-promo--overlap-right {
  padding-top: rem(32);
  position: relative;

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: rem(4);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media (min-width: $bp-min-small-tablet) {
      width: calc(100% + #{$base-component-whitespace});
      z-index: $stack-overlaps;
    }
  }
}
