.search-trigger {
  @include font(stevie, regular);

  align-items: center;
  background-color: transparent;
  border: none;
  border-left-style: solid;
  border-left-width: 1px;
  color: currentColor;
  display: flex;
  height: rem(42);
  outline: none;
  padding: 0 0 0 rem(15);

  span {
    transition: color $standard-transition;
  }

  @media (min-width: $bp-min-small-tablet) {
    border: none;
    height: auto;
    padding: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    .header--office & {
      // border-left: 1px solid $drexel-grey-soft;
      padding-left: rem(15);
    }
  }

  svg {
    display: inline-block;
    min-width: 24px;
  }
}

.search-trigger__label {
  font-size: rem(15);
  font-weight: 500;
  margin-left: rem(11);
  line-height: 1;
}
