@use "sass:math";
@import "../base/_grid-settings.scss";
@import "../base/_variables.scss";

// Dev note: When IE 11 support is no longer required, we can remove the flexbox fallbacks (with the exception of layout-columns--flex) and this file will be much more DRY.

.layout-columns {
  --column-gap: #{$layout-columns-spacer};
  max-width: calc(100% - #{$double-side-margin});
  margin-left: auto;
  margin-right: auto;

  // Flexbox fallback as the default, in case of IE 11
  @media (min-width: $bp-min-small-tablet) {
    max-width: $max-width;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .mobile-striper &,
  .promo-grid &,
  .stat-grid &,
  .video-grid &,
  .container--wide & {
    max-width: 100%;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    max-width: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

// Fixes for IE11 issues
@media (min-width: $bp-min-small-tablet) {
  .layout-columns--70-30,
  .layout-columns--30-70 {
    flex-wrap: nowrap;

    &.page-segment {
      justify-content: space-between;
    }
  }
}

.layout-columns > * {
  background-clip: padding-box;
  width: 100%;

  // Vertical spacing for flexbox version
  border-bottom: $layout-columns-spacer solid transparent;

  .stat-grid & {
    border-bottom: math.div($layout-columns-spacer-wide, 2) solid transparent;
  }
}

@media (min-width: $bp-min-small-tablet) {
  // Using transparent borders to mimic grid gap for flexbox
  .layout-columns > * {
    border-left: #{math.div($layout-columns-spacer, 2)} solid transparent;
    border-right: #{math.div($layout-columns-spacer, 2)} solid transparent;
  }

  .layout-columns--skinny-gutter > * {
    border-left: #{math.div($layout-columns-spacer-narrow, 2)} solid transparent;
    border-right: #{math.div($layout-columns-spacer-narrow, 2)} solid transparent;
  }

  .layout-columns--wide-gutter > * {
    border-left: #{math.div($layout-columns-spacer-wide, 4)} solid transparent;
    border-right: #{math.div($layout-columns-spacer-wide, 4)} solid transparent;
  }

  // Assumption: 70/30 and 30/70 will always have both child columns,
  // even if one is empty

  // Don't add extra side gutter space to 70/30 or 30/70 layouts
  .layout-columns--30-70,
  .layout-columns--70-30 {
    & > :first-child {
      border-left: none;
    }
    & > :last-child {
      border-right: none;
    }
  }

  // This is not great BEM, but this is the intuitive place to find this
  .container--stripe
    .layout-columns--30-70.page-segment
    > :first-child:not(:last-child) {
    // Working around some IE 11 issues
    margin-right: calc((100% / 12) + #{$layout-columns-spacer});
  }

  // Column widths for flexbox

  // 100% widths
  .layout-columns--1-up > * {
    max-width: rem(923);
    margin-left: auto;
    margin-right: auto;
  }

  // Two-thirds widths
  .layout-columns--30-70 > :last-child,
  .layout-columns--70-30 > :first-child {
    //width: calc((100% / 3) * 2);
    width: 67%;
  }

  // One-half widths
  .layout-columns--2-up > *,
  .layout-columns--4-up > *,
  .layout-columns--2-3-up > * {
    // 2-up on tablet, 3-up on desktop
    width: calc(100% / 2);
  }

  // One-third widths
  .layout-columns--3-up > *,
  .layout-columns--30-70 > :first-child,
  .layout-columns--70-30 > :last-child {
    width: calc(99.9% / 3); // Avoids wrapping due to rounding errors
  }
}

@media screen and (min-width: $bp-min-large) {
  // One-quarter widths
  .layout-columns--4-up > * {
    width: calc(100% / 4);
  }
}

@media screen and (min-width: $bp-min-xlarge) {
  .layout-columns--2-3-up > * {
    // 2-up on tablet, 3-up on desktop
    width: calc(99.9% / 3); // Avoids wrapping due to rounding errors
  }
}

@media screen and (min-width: $bp-min-xxlarge) {
  // Don't mimic the grid gap at all once we get wider on 70/30 or 30/70 layouts
  .layout-columns--30-70,
  .layout-columns--70-30 {
    & > * {
      border-left: none;
      border-right: none;
    }
  }
}

.layout-columns--whitebar {
  > * {
    @media (min-width: $bp-min-small-tablet) {
      position: relative;

      &:after {
        background-color: $white;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        right: calc(#{$layout-columns-spacer} / 4 * -1);
        width: 1px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  &.layout-columns--wide-gutter {
    > * {
      @media (min-width: $bp-min-small-tablet) {
        &:after {
          right: calc(#{$layout-columns-spacer-wide} / 4 * -1);
        }
      }
    }
  }

  &.layout-columns--skinny-gutter {
    > * {
      @media (min-width: $bp-min-small-tablet) {
        &:after {
          right: calc(#{$layout-columns-spacer-narrow} / 4 * -1);
        }
      }
    }
  }
}

// Separating out the CSS Grid into a @supports block
@supports (display: grid) {
  /* autoprefixer: off */
  .layout-columns {
    max-width: calc(100% - #{$double-side-margin});

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    // Overriding the flexbox default version
    justify-content: normal;

    // Only add gaps when the width can support them
    @media screen and (min-width: $bp-min-small-tablet) {
      gap: $layout-columns-spacer;
      column-gap: var(--column-gap, #{$layout-columns-spacer});
    }
  }

  // Keep the flexbox layout if desired
  .layout-columns--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &.layout-columns--3-up > * {
      flex-basis: 100%;

      @media screen and (min-width: $bp-min-small-tablet) {
        max-width: calc((100% - var(--column-gap) * 2) / 3);
      }
    }

    &.layout-columns--4-up > * {
      flex-basis: 100%;

      @media screen and (min-width: $bp-min-small-tablet) {
        max-width: calc((100% - var(--column-gap) * 1) / 2);
      }

      @media screen and (min-width: $bp-min-large) {
        max-width: calc((100% - var(--column-gap) * 3) / 4);
      }
    }
  }

  @media screen and (min-width: $bp-min-small-tablet) {
    .layout-columns--skinny-gutter {
      gap: #{$layout-columns-spacer-narrow};
      --column-gap: #{$layout-columns-spacer-narrow};
    }

    .layout-columns--wide-gutter {
      gap: #{math.div($layout-columns-spacer-wide, 2)};
      --column-gap: #{math.div($layout-columns-spacer-wide, 2)};
    }
  }

  .layout-columns > * {
    grid-column-end: span 12;
    border-left: none;
    border-right: none;
    border-bottom: none;

    // Overriding the flexbox default version
    background-clip: border-box;

    .stat-grid & {
      border-bottom: none;
    }
  }

  .layout-columns > *,
  .layout-columns > :first-child,
  .layout-columns > :last-child {
    // Overriding the flexbox default version
    width: auto;
  }

  // 100% widths
  .layout-columns--1-up > * {
    grid-column-end: span 12;

    @media screen and (min-width: $bp-min-small-tablet) {
      max-width: rem(923); // matches the max-width of "70" of 30/70
      justify-self: center;
    }
  }

  // One-half widths
  .layout-columns--2-up > * {
    grid-column-end: span 12;

    @media screen and (min-width: $bp-min-small-tablet) {
      grid-column-end: span 6;
    }
  }

  // One-quarter widths
  .layout-columns--4-up > * {
    grid-column-end: span 12;

    @media screen and (min-width: $bp-min-small-tablet) {
      grid-column-end: span 6;
    }

    @media screen and (min-width: $bp-min-large) {
      grid-column-end: span 3;
    }
  }

  // One-third widths
  .layout-columns--30-70 > :first-child,
  .layout-columns--70-30 > :last-child,
  .layout-columns--3-up > * {
    grid-column-end: span 12;

    @media screen and (min-width: $bp-min-small-tablet) {
      grid-column-end: span 4;
    }
  }

  // Two-thirds widths
  .layout-columns--30-70 > :last-child,
  .layout-columns--70-30 > :first-child {
    grid-column-end: span 12;

    @media screen and (min-width: $bp-min-small-tablet) {
      grid-column-end: span 8;
    }
  }

  // Override for flexbox default
  .container--stripe
    .layout-columns--30-70.page-segment
    > :first-child:not(:last-child) {
    margin-right: 0;
  }

  // 2-up on tablet, 3-up on desktop
  .layout-columns--2-3-up > * {
    grid-column-end: span 12;

    @media screen and (min-width: $bp-min-small-tablet) {
      grid-column-end: span 6;
    }

    @media screen and (min-width: $bp-min-xlarge) {
      grid-column-end: span 4;
    }
  }
}

.layout-columns--2-up {
  .layout-columns__col {
    @media screen and (max-width: $bp-min-small-tablet) {
      margin-bottom: $standard-vertical-margin;
    }
  }
}

@media screen and (min-width: $bp-min-xxlarge) {
  .layout-columns--30-70,
  .layout-columns--70-30 {
    // Special case for page segment sidebars
    & > .page-segment__sidebar {
      width: $standard-sidebar-width;
    }
  }
}
