$search-results__sort-arrow-size: 24;

.search-results {
  &.is-refreshing {
    .search-results__item-wrapper * {
    }
  }
}

.search-results__item-wrapper {
  position: relative;

  // We need to remove position:relative for the video grid variation,
  // so that the expanding drawer will expand to the edges of the browser.
  .dynamic-video-grid & {
    position: static;
  }
}

.search-results__items {
  min-height: rem(350);
  margin-bottom: rem(30);
}

.search-results__sort-bar {
  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: rem(30);

  ul {
    @include zero-list();

    display: inline-block;
  }

  .search-results__title-bar & {
    align-self: flex-start;
    margin-bottom: 1em;

    @media (min-width: $bp-min-small) {
      margin-bottom: 0;
    }
  }

  .search-results__title-bar + & {
    margin-top: rem(-42);

    @media (min-width: $bp-min-small) {
      margin-top: rem(-21);
    }
  }

  @media print {
    display: none;
  }
}

.search-results__sorter {
  @include zero();

  border-left: 1px solid currentColor;
  cursor: pointer;
  display: inline-block;
  padding: 0 rem(21);

  &:first-child {
    border-left: none;
  }

  &.is-active {
    .search-results__sort-label {
      border-bottom: rem(4) solid currentColor;
      font-weight: bold;
    }
  }
}

.search-results__sort-label {
  background-color: transparent;
  border: none;
  border-bottom: rem(4) solid transparent;
  font-size: rem(15);
  line-height: 1.733;
  padding: 0;
  color: $black;

  &:hover,
  &:focus {
    border-bottom: rem(4) solid currentColor;
    font-weight: bold;
  }
}

.search-results__status {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 1.8;
  margin-bottom: rem(30);
}

.search-results__active-facets {
  display: flex;
  flex-wrap: wrap;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 0;
    margin-left: rem(12);
    font-weight: 700;
    line-height: 1;

    span {
      padding-bottom: 0;
    }
  }

  button {
    float: right;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
  }
}

.search-results__load-more {
  text-align: center;
  transition: all 0.2s;
  margin-top: $layout-columns-spacer-double;

  .program-listing & {
    margin-top: $layout-columns-spacer;
  }

  @media print {
    display: none;
  }
}

.search-results--simple {
  .search-results__load-more {
    .btn {
      font-size: rem(12);
    }
  }
}

.search-results__spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.search-results__spinner div {
  position: absolute;
  width: 5px;
  height: 5px;
  background: $drexel-grey-soft;
  border-radius: 50%;
  animation: search-results__spinner 1.2s linear infinite;
}
.search-results__spinner div:nth-child(1) {
  animation-delay: 0s;
  top: 29px;
  left: 53px;
}
.search-results__spinner div:nth-child(2) {
  animation-delay: -0.1s;
  top: 18px;
  left: 50px;
}
.search-results__spinner div:nth-child(3) {
  animation-delay: -0.2s;
  top: 9px;
  left: 41px;
}
.search-results__spinner div:nth-child(4) {
  animation-delay: -0.3s;
  top: 6px;
  left: 29px;
}
.search-results__spinner div:nth-child(5) {
  animation-delay: -0.4s;
  top: 9px;
  left: 18px;
}
.search-results__spinner div:nth-child(6) {
  animation-delay: -0.5s;
  top: 18px;
  left: 9px;
}
.search-results__spinner div:nth-child(7) {
  animation-delay: -0.6s;
  top: 29px;
  left: 6px;
}
.search-results__spinner div:nth-child(8) {
  animation-delay: -0.7s;
  top: 41px;
  left: 9px;
}
.search-results__spinner div:nth-child(9) {
  animation-delay: -0.8s;
  top: 50px;
  left: 18px;
}
.search-results__spinner div:nth-child(10) {
  animation-delay: -0.9s;
  top: 53px;
  left: 29px;
}
.search-results__spinner div:nth-child(11) {
  animation-delay: -1s;
  top: 50px;
  left: 41px;
}
.search-results__spinner div:nth-child(12) {
  animation-delay: -1.1s;
  top: 41px;
  left: 50px;
}
@keyframes search-results__spinner {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.search-results__pagination {
  text-align: center;

  @media print {
    display: none;
  }
}

.search-results__pages {
  display: inline-block;

  .btn--link {
    display: inline-block;
    width: auto;
  }
}

.search-results__filter-open {
  display: none;
  margin-bottom: rem(16);

  .icon {
  }

  @media (max-width: $bp-max-medium) {
    display: block;
  }
}

.search-results__spinner {
  position: absolute;
  left: calc(50% - 88px); // subtract half width of spinner icon
  text-align: center;

  img {
  }
}

.search-results__title-bar {
  align-items: flex-end;
  display: block;
  font-size: rem(15);
  justify-content: space-between;
  margin-bottom: rem(51);

  & > * {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-small) {
    display: flex;
  }
}

.search-results__alert-banner {
}

.search-results__alert-banner-flag {
  background-color: $black; /* Accessible placeholder, override in theme */
  color: $white;
  font-size: rem(15);
  line-height: 1.733;
  padding: rem(11);

  svg {
    display: inline-block;
    height: rem(19);
    margin-right: rem(11);
    position: relative;
    top: 3px;
    width: rem(19);
  }
}

.search-results__prev-page {
  &:after {
    border: 3px solid currentColor;
    border-right-width: 0;
    border-top-width: 0;
    content: "";
    display: block;
    height: rem(11);
    transform: rotate(45deg);
    width: rem(11);
  }
}

.search-results__next-page {
  &:after {
    border: 3px solid currentColor;
    border-left-width: 0;
    border-bottom-width: 0;
    content: "";
    display: block;
    height: rem(11);
    transform: rotate(45deg);
    width: rem(11);
  }
}

.search-results__item-header {
  display: flex;
  justify-content: flex-end;
}

.search-results__relevance-header {
  @include font(stevie, bold);

  display: none;
  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: rem(32);
  width: rem(105);

  @media (min-width: $bp-min-large) {
    display: block;
  }

  span {
    border-bottom: rem(4) solid currentColor;
    display: inline-block;
    padding-right: rem(5); /* make the border a little longer than the word */
  }
}
