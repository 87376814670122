.image-promo {
  @include component-normalize();
  @include font-smoothing(off);

  background-color: $black; // Fallback for if some reason no image shows, to retain text readability
  color: white;
  min-height: 400px;
  padding: rem(42);
  position: relative;

  .promo-grid & {
    margin-bottom: 0;
  }

  // a little more space for the "back to top" if we're at the bottom of the page in a promo grid
  .promo-grid:last-child & {
    &:last-child {
      margin-bottom: $promo-spacer;
    }

    @media (min-width: $bp-min-small-tablet) {
      & {
        margin-bottom: $promo-spacer;
      }
    }
  }

  &.image-promo--light {
    background-color: $white;
    color: $black;

    .btn--primary {
      border-color: $black;
    }
  }

  &.image-promo[style*="color"] {
    .btn--primary {
      border-color: currentColor;
    }
  }

  &::after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  @media (min-width: $bp-min-medium) {
    padding: rem(42) rem(63);
  }

  @media (min-width: $bp-min-large) {
    padding: rem(53) rem(74);
  }

  @media (min-width: $bp-min-xlarge) {
    padding: rem(71) rem(125);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    @include font-smoothing(on);
    background-color: transparent !important;
    color: $black !important;
    min-height: 0;
    padding: 0;

    &::after {
      display: none;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.image-promo__image-overlay {
  background-position: center center;
  background-size: cover;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.image-promo__body {
  position: relative;
  z-index: $stack-internal;

  @media (min-width: $bp-min-xlarge) {
    max-width: rem(560);
    margin-left: auto;
    margin-right: 0;
  }

  @media (min-width: $bp-min-xxxlarge) {
    // 2-column layout, swap the margins
    .promo-grid__promos.layout-columns--2-up > *:nth-child(2) & {
      margin-left: 0;
    }
  }

  @media print {
    max-width: none;
    margin-left: 0;
  }
}

.image-promo__summary {
  font-size: rem(21);
  line-height: 1.619;
  margin-bottom: rem(36);

  @media print {
    font-size: inherit;
    margin-bottom: $print-standard-text-whitespace;
  }
}

.image-promo__title {
  @extend .txt-h2;

  color: $white;
  margin-bottom: rem(14);

  .image-promo.image-promo--light & {
    color: $black;
  }

  .image-promo[style*="color"] & {
    color: inherit;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
    color: $black !important;

    & + .image-promo__summary {
      break-before: avoid-page;
      page-break-before: avoid;
    }
  }
  /* stylelint-enable declaration-no-important */
}
.image-promo__cta {
  @media print {
    @include print-list;
  }
}
