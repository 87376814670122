.promo-grid {
  @include componentize();
  @include component-normalize();

  .mobile-striper & {
    max-width: 100%;
  }

  &.promo-grid--full-width {
    @include componentize-wide();
  }

  &.promo-grid--bump-up {
    @media (min-width: $bp-min-small-tablet) {
      @include overlap(margin-up, rem(-105));
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {


      font-size: rem(18);
      @include print-style-general();
    
    &,
    .mobile-striper & {
      max-width: none !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &:has(.related-event) {
      display: none !important;
    }

    &:has(+ .promo-grid) {
      margin-bottom: $print-standard-listitem-whitespace !important;
    }
  }
}

.promo-grid__promos {
  row-gap: $base-component-mobile-whitespace; // for vertical spacing on mobile

  // A very specific override, for a smaller space when there's a small expert promo and an expert bio.
  // The expert bio already takes care of handling this gap.  Example: Now Topic Page
  &.layout-columns--30-70:has(> .expert-promo--small:first-child) {
    row-gap: 0;
  }
}

/* Allow for faking a single wrapping promo where necessary */
.promo-grid__promos--spacer-margin {
  margin-bottom: calc(#{$promo-spacer} - #{$base-component-whitespace});

  .promo-grid:last-child & {
    margin-bottom: $promo-spacer;
  }

  &.layout-columns--skinny-gutter,
  &.layout-columns--4-up {
    margin-bottom: calc(
      #{$promo-spacer-narrow} - #{$base-component-whitespace}
    );

    .promo-grid:last-child & {
      margin-bottom: $promo-spacer-narrow;
    }
  }
}

// Edge case -- media promo without a quote promo should be centered.
// Example: featured news on homepage, no quote
@media screen and (min-width: $bp-min-small-tablet) {
  .promo-grid__promos.layout-columns--30-70 .media-promo:first-child {
    grid-column-start: 3;
  }
}

.promo-grid__cta {
  display: flex;
  justify-content: space-around;
  /* Always need a little breathing space here; use padding to prevent it being subsumed by parents' margin */
  padding-bottom: rem(32);
  margin-top: $base-component-mobile-whitespace;

  @media (min-width: $bp-min-small-tablet) {
    margin-top: calc(#{$base-component-whitespace} - #{$standard-side-margin});
  }
}

.promo-grid__summary {
  margin-bottom: rem(42);
  max-width: rem(810);
}

.promo-grid--news {
  .promo-grid__promos {
    grid-template-rows: auto 1fr;
  }

  .mini-promos {
    grid-row: span 2;

    > *:last-child {
      margin-bottom: rem(10);
    }
  }

  .promo-grid__cta {
    height: fit-content;
    justify-self: center;
    margin-top: 0;
    padding-bottom: 0;

    @media screen and (min-width: $bp-min-small-tablet) {
      grid-column-end: span 8;
    }
  }
}
