.anchor-header {
    @include component-normalize();
    @include componentize();
    
    display: block;
    position: relative;
    width: 100%;
    margin: 1.5rem auto;
    
    @media (min-width: 90.06em) {
            max-width: 1400px;
    }
    h2 {
        //margin-bottom: rem(5);

    }
    ::after {
        background-color: $drexel-gold;
        content: "";
        display: block;
        height: 0.25rem;
        position: absolute;
        bottom: -.5rem;
        left: 0;
        width: 20rem;

        @media (max-width: $bp-min-medium) {
            width:90%;
        }
    }
}