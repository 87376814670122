.highlight-text {
  @include componentize();
  text-align: center;

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(24);
    line-height: 1.605;
    letter-spacing: rem(-0.4);
    word-spacing: rem(0.4);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(30);
    line-height: 1.267;
  }

  @media (min-width: $bp-min-small-tablet) {
    padding-bottom: $base-component-whitespace; /* needs a little space because it's always followed by a bump up */

    .wf-loading & {
      font-size: rem(31);
      line-height: 1.524;
      letter-spacing: rem(-0.8);
    }

    .wf-active & {
      font-size: rem(38);
      line-height: 1.263;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: rem(24) !important;
    text-align: left;

    strong {
      font-weight: inherit;
    }

    // Adjust spacing for following stat-grids (progressive enhancement)
    &:has(+ .stat-grid) {
      margin-bottom: $print-standard-text-whitespace !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.highlight-text__inner {
  margin: auto;
  max-width: rem(956);
}
