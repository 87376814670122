.event-facets {
  padding: rem(21);

  @media (min-width: $bp-min-medium) {
    padding: rem(50) rem(42);

    .program-listing__facets &,
    .staff-search-listing__facets & {
      padding: 0;
    }
  }

  &.is-open h3 button:before {
    transform: rotate(0deg);
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;

    .wf-loading & {
      @include font(futura-fallback, demi);
      font-size: rem(19);
      line-height: 1.484;
      letter-spacing: rem(0.8);
      word-spacing: rem(-1.8);
    }

    .wf-active & {
      @include font(futura, demi);
      font-size: rem(20);
      line-height: 1.4;
      letter-spacing: 1.6px;
    }

    button {
      @include zero();

      background-color: transparent;
      border: none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      position: relative;
      outline: none;
      text-transform: uppercase;
      text-align: left;
      width: 100%;
      color: $black;

      &:after,
      &:before {
        background-color: currentColor;
        content: "";
        display: block;
        height: rem(2);
        width: rem(23);
        position: absolute;
        right: rem(21);
        top: calc(50% - #{rem(1)});
      }

      &:before {
        transform: rotate(270deg);
        transition: transform 0.3s ease-out;
      }
    }
  }

  .radio-facets__group-heading h3 {
    margin-bottom: rem(18);
  }

  &.in-toggle-mode h3 {
    cursor: pointer;
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: rem(11);

    .wf-loading & {
      @include font(futura-fallback, demi);
      font-size: rem(15);
      line-height: 1.842;
      letter-spacing: rem(0.65);
      word-spacing: rem(-2.15);
    }

    .wf-active & {
      @include font(futura, demi);
      font-size: rem(16);
      line-height: 1.688;
      letter-spacing: rem(1);
    }
  }
}

.event-facets__group-clear {
  @include font(stevie, regular);

  background-color: transparent;
  border: none;
  font-size: rem(15);
  line-height: 1.733;
  margin: 0 0 rem(21);
  text-transform: lowercase;
  padding: 0;
  white-space: nowrap;

  .staff-search-listing__facets &,
  .program-listing__facets & {
    line-height: rem(38);
    margin-bottom: 0;

    @media screen and (min-width: $bp-min-medium) {
      align-self: flex-end;
      margin-left: $promo-spacer;

      @supports (column-gap: 10px) {
        margin-left: 0; // column gap will be handled via "column-gap" in _event-facets.scss
      }
    }

    // Hide the first group clear button on certain pages where it's a horizontal layout
    // Could potentially put this under JS control as a user option
    &:first-child {
      display: none;
    }
  }

  .admissionrep-facets & {
    margin-left: 0;
    line-height: inherit;
    display: block;
  }
}

.event-facets__group {
  border-bottom: 1px solid currentColor;
  padding-bottom: rem(21);
  margin-bottom: rem(21);

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.event-facets__group-values {
  @include zero();
  font-size: rem(17);
  line-height: 1.529;
  list-style-type: none;

  li {
    @include zero();

    margin-bottom: rem(11);

    .event-facets__group-values {
      margin-left: rem(29);
      margin-top: rem(7);
    }
  }

  input {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;

    &:hover + label,
    &:focus + label {
      text-decoration: underline;
    }
  }

  label {
    cursor: pointer;
    display: block;
    margin-left: 0;
    padding-left: rem(29);
    position: relative;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    svg {
      display: block;
      height: rem(19);
      position: absolute;
      top: rem(2);
      left: rem(1);
      width: rem(19);
    }

    &:before {
      border: 2px solid currentColor;
      border-radius: 4px;
      content: "";
      display: block;
      height: rem(17);
      position: absolute;
      top: 1px !important; /* The cascade for standard form fields makes this ugly */
      left: 0;
      width: rem(17);
    }

    &:after {
      display: none !important; /* The cascade for standard form fields makes this ugly */
    }
  }
}

.event-facets__all-facets {
  .event-facets.is-open & {
    margin-top: rem(11);
    max-height: calc(100vh - #{rem(210)});
    overflow: hidden scroll;

    @media (min-width: $bp-min-medium) {
      max-height: none;
      overflow: visible;
    }
  }

  @media (min-width: $bp-min-medium) {
    .event-facets.is-open & {
      margin-top: 0;
    }

    .program-listing__facets &,
    .staff-search-listing__facets & {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @supports (column-gap: 10px) {
        column-gap: #{$promo-spacer};
      }

      & > div {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: rem(21);
        width: calc(50% - (#{$promo-spacer} / 2));
      }
    }

    .staff-search-listing__facets & {
      justify-content: flex-start;
    }

    .admissionrep-facets & {
      display: block;

      & > div {
        width: auto;
        margin-top: rem(21);
        //margin-bottom: rem(21);

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  @media (min-width: $bp-min-large) {
    .program-listing__facets &,
    .staff-search-listing__facets & {
      flex-wrap: nowrap;

      & > div {
        margin-top: 0;
        width: calc(25% - (#{$promo-spacer} * 3 / 4));
      }
    }

    .admissionrep-facets & > div {
      width: auto;
      margin-top: rem(21);

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
