.footer-info {
  align-items: center;
  font-size: rem(18);
  margin-bottom: rem(31);
  padding: rem(21) 21px 0;

  @media (min-width: $bp-footer-min-medium) {
    margin-bottom: 0;
    padding: 0 rem(30);
  }

  &.show-desktop {
    display: none;
  }

  &.show-mobile.show-tablet {
    display: block;
  }

  a {
    font-weight: 600;
    border-bottom: none;
  }

  @media (min-width: $bp-footer-min-medium) {
    &.show-mobile.show-tablet {
      align-items: flex-start;
      display: flex;
    }
  }

  @media (min-width: $bp-footer-min-large) {
    &.show-mobile.show-tablet {
      width: 100%;
      align-items: center;
    }
  }

  @media (min-width: $bp-footer-min-xlarge) {
    &.show-desktop {
      display: flex;
    }

    &.show-mobile.show-tablet {
      display: none;
    }
  }
}

.footer-info__label {
  position: relative;
  top: 2.5px;
  text-transform: uppercase;
  white-space: nowrap;

  .wf-loading & {
    @include font(futurac-fallback, book);
    font-size: rem(11);
    line-height: 2.145;
    letter-spacing: rem(1.05);
  }

  .wf-active & {
    @include font(futurac, book);
    font-size: rem(13);
    line-height: 1.1;
    letter-spacing: rem(1.5);
  }

  @media (min-width: $bp-footer-min-medium) {
    .wf-loading & {
      font-size: rem(14);
      line-height: 2.458;
      letter-spacing: rem(1.3);
    }

    .wf-active & {
      font-size: rem(18);
    }
  }

  @media (min-width: $bp-footer-min-medium) {
    padding: rem(21) 0;
    margin-right: rem(21);
  }

  @media (min-width: $bp-footer-min-large) {
    top: 0;
    margin-right: 0;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    padding: 0;
    top: 1px;
  }
}

.footer-info__links {
  @include zero();

  align-items: center;
  font-size: rem(17);
  font-weight: 600;
  position: relative;
  top: 2px;

  @media (min-width: $bp-footer-min-medium) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (min-width: $bp-footer-min-large) {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    top: 0;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    justify-content: space-between;
    top: 2px;
  }
}

.footer-info__link {
  list-style-type: none;
  margin: 0;

  border-top-width: 1px;
  border-top-style: solid;
  padding: rem(16) 0;

  &:first-child {
    border-top: none;
  }

  @media (min-width: $bp-footer-min-medium) {
    padding: rem(21) 0;
    width: calc(50% - #{rem(21)});

    &:first-child,
    &:nth-child(2) {
      border-top: none;
    }
  }

  @media (min-width: $bp-footer-min-large) {
    width: auto;
    border-left-width: 1px;
    border-left-style: solid;
    border-top: none;
    padding: 0 rem(26);

    &:first-child {
      border-left: none;
    }
  }

  @media (min-width: $bp-footer-min-xlarge) {
    border-left-width: 1px;
    border-left-style: solid;
    border-top: none;
    padding: 0 rem(26);

    &:first-child {
      border-left: none;
    }
  }
}
