.section-header {
  @include componentize();
  @include component-normalize();

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
  }

  h2 {
    @extend .txt-h1;
  }
}

.section-header__ctas {
  @media (min-width: $bp-min-small-tablet) {
    width: calc(33% - (#{$promo-spacer} / 2));
  }

  @media print {
    width: 100%;
  }
}

.section-header__cta {
  margin-bottom: rem(28);

  a {
    width: 100%;
    text-align: center;

    @media (min-width: $bp-min-xsmall) {
      width: auto;
    }
  }

  @media print {
    @include print-list;

    a {
      width: auto;
      text-align: inherit;
    }
  }
}

.section-header__intro {
  font-size: rem(20);
  line-height: 1.6;
  margin-bottom: rem(28);

  @media (min-width: $bp-min-small) {
    font-size: rem(27);
    line-height: 1.556;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: rem(18) !important;
    margin-bottom: $print-standard-text-whitespace;
  }
  /* stylelint-enable declaration-no-important */
}

.section-header__body {
  @media (min-width: $bp-min-small-tablet) {
    margin-right: $promo-spacer;
    width: calc(67% - (#{$promo-spacer} / 2));
  }
}
