.tabs__tablist {
  display: flex;
  list-style-type: none;
  margin-left: initial;
  padding-bottom: rem(8);
  border-bottom: rem(4) solid $drexel-gold;
}

.tabs__tabpanels-panel {
  display: none;

  &.is-active {
    display: block;
  }
}

.tabs__tablist-tab {
  margin-right: rem(4);

  &:last-child {
    margin-right: 0;
  }
}

.tabs__tablist-tab-button {
  @include font(stevie, regular);

  font-weight: 700;
  border: none;
  display: inline-block;
  font-size: rem(17);
  line-height: 1.471;
  margin: 0;
  padding: rem(6) rem(11);
  transition: color 0.3s, background-color 0.3s;
  background-color: transparent;
  color: $drexel-blue;

  &:hover,
  &.is-active {
    background-color: $drexel-navy;
    color: $white;
  }

  &:focus {
    outline: 4px solid $drexel-gold;
  }
}
