.highlight-text-color-divide {
  @include componentize();
  text-align: center;

  padding-top: 3.75rem;
  padding-bottom: 3.75rem;

  &.highlight-text-color-divide__remove-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  @media (max-width: $bp-min-small-tablet) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(24);
    line-height: 1.605;
    letter-spacing: rem(-0.4);
    word-spacing: rem(0.4);
  }

  .wf-active & {
    @include font(futura, book);

    font-size: rem(30);
    line-height: 1.267;
  }

  @media (min-width: $bp-min-small-tablet) {
    padding-bottom: $base-component-whitespace; /* needs a little space because it's always followed by a bump up */

    .wf-loading & {
      font-size: rem(31);
      line-height: 1.524;
      letter-spacing: rem(-0.8);
    }

    .wf-active & {
      font-size: rem(38);
      line-height: 1.263;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: rem(24) !important;
    text-align: left;

    strong {
      font-weight: inherit;
    }

    // Adjust spacing for following stat-grids (progressive enhancement)
    &:has(+ .stat-grid) {
      margin-bottom: $print-standard-text-whitespace !important;
    }
  }
  /* stylelint-enable declaration-no-important */

}
.highlight-text-color-background{
  width: 100%;
  height: auto;

  &.highlight-text-bg-accent {
    background-color: $drexel-gold;
  }
  &.highlight-text-bg-accent-gradient {
    background: rgb(255,198,0);
    background: linear-gradient(90deg, rgba(255,198,0,1) 0%, rgba(108,172,228,1) 100%);
  }
  &.highlight-text-bg-dark {
    background-color: $drexel-navy;

    .highlight-text-color-divide  .highlight-text-color-divide__inner{
      color: $white;
    }
  }
  &.highlight-text-bg-dark-gradient {
    background: rgb(7,41,77);
    background: linear-gradient(90deg, rgba(7,41,77,1) 0%, rgba(0,102,152,1) 100%);
    
    .highlight-text-color-divide  .highlight-text-color-divide__inner{
      color: $white;
    }
  }
}
.is-page-editor {
  .highlight-text__inner {
    .scWebEditInput{
      box-shadow: none;
    }
  }
}
.highlight-text-color-divide__inner {
  margin: auto;
  max-width: rem(956);

  &.highlight-text-style-regular {
    @include font(stevie, book);
    font-weight: 400;
    font-size: rem(21);
    line-height: rem(34);
    
    @media (max-width: $bp-min-small-tablet) {
      font-size: rem(18);
      line-height: rem(31);
    }
  }

  &.highlight-text-style-bold {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.highlighted-text-divide__button {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &.highlighted-text-divide__btn-light-bg {

    a {
      color: $drexel-navy;
      border-color: $drexel-navy;

      &:before {
        background: linear-gradient(90deg, #006298, #07294d);
        border-color: $drexel-navy;
        transition: all $standard-transition;
      }

      &:hover {
        background: linear-gradient(90deg, #006298, #07294d);
        color: $white;
        transition: all $standard-transition;
        border-color: $drexel-navy;
      }
    }
  }

  &.highlighted-text-divide__btn-dark-bg {

    a {
      color: $white;

      &:hover {
        color: $black;
      }
    }
  }
}