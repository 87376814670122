.affiliation-links {
  color: $drexel-blue;
  font-weight: 700;
  margin-bottom: rem(25);

  a {
    border-bottom-color: transparent;
  }

  a:hover,
  a:focus {
    border-bottom-color: currentColor;
  }
}
