$triangle-offset-default: 2rem;

.primary-nav {
  margin: 0 auto;
  max-width: calc(100% - #{$double-side-margin});
  padding: rem(37) rem(15);
  position: relative;
  z-index: $stack-menu;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-xxlarge) {
    max-width: $max-width;
  }

  @media (min-width: $bp-min-small-tablet) {
    padding: 0;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  a {
    border-bottom: none;
  }
}

.primary-nav__top-level {

  @media (min-width: $bp-min-small-tablet) {
    align-items: center;
    display: flex;
    justify-content: space-between;

    // Progressive enhancement:  If there are 3 or less items, items be auto-sized and not stretched to fit the bar
    &:has(> :nth-child(-n + 3):last-child) {
      justify-content: flex-start;

      // Child item overrides
      > * {
        width: auto;
        flex-grow: 0;

        &:not(:first-child) {
          margin-left: $base-component-whitespace;
        }
      }
    }

    // Progressive enhancement:  If there are exactly 3 items, change up the way the second-to-last menu aligns at desktop
    &:has(> :nth-child(3):last-child) {
      @media (min-width: $bp-min-medium) {
        .primary-nav__top-level-item.is-open:nth-last-child(2):nth-child(n
            + 2) {
          // Arrow alignment
          &::before {
            left: $triangle-offset-default;
            right: auto;
          }
          // Menu alignment
          .primary-nav__sublevel {
            left: 0;
            right: auto;
          }
        }
      }
    }
  }
}

.primary-nav__top-level-item[tabindex] {
  cursor: pointer;
}

.primary-nav__top-level-item {
  border-top-style: solid;
  border-top-width: 1px;
  margin: 0;
  padding: rem(16) 0;
  text-transform: uppercase;
  
  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(14);
    letter-spacing: rem(1.25);
    word-spacing: rem(-1.25);
    line-height: 1.8;
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(16);
    letter-spacing: rem(1.49);
  }

  @media (min-width: $bp-min-small-tablet) {
    flex-grow: 2;
    flex-shrink: 1;
    margin-left: rem(21);
    padding: rem(21) 0;
    position: relative;
    width: auto; // for IE 11, fixes flexbox overlap problems (DR-441)
    flex-basis: min-content;

    > a,
    > span:not(.primary-nav__plus-sign) {
      display: inline-block;
    }

    .primary-nav--shrinkable & {
      white-space: normal;
    }

    .js-shrinkable & {
      white-space: nowrap;
    }

    .header--office & {
      .wf-loading & {
        font-size: rem(12);
        letter-spacing: rem(1.35);
        word-spacing: rem(-0.95);
        line-height: 1.9;
      }

    }

    .is-active {
      position: relative;

      &::after {
        background-color: currentColor;
        content: "";
        display: block;
        height: 4px;
        position: absolute;
        bottom: rem(-22);
        left: 0;
        width: 100%;
        z-index: $stack-back;
      }
    }
  }

  @media (min-width: $bp-min-medium) {
    width: auto;
    flex-basis: auto;
  }

  @media (min-width: $bp-min-large) {
    margin-left: $base-component-whitespace;

    .header--office & {
      .wf-loading & {
        font-size: rem(14);
        letter-spacing: rem(1.25);
        word-spacing: rem(-1.25);
        line-height: 1.8;
      }

      .wf-active & {
        font-size: rem(16);
      }
    }
  }

  &:first-child {
    border-top: none;

    @media (min-width: $bp-min-small-tablet) {
      margin-left: 0;
    }
  }

  &.primary-nav__child-trigger {
    position: relative;

    span {
      transition: color $standard-transition;
    }

    @media (min-width: $bp-min-small-tablet) {
      &.is-open {
        &::before {
          @include triangle(11px, 12px);

          position: absolute;
          bottom: -8px;
          left: $triangle-offset-default;
          transform: rotate(270deg);
        }

        // Match triangle to right-aligned dropdowns
        &:last-child:nth-child(n + 3)::before {
          left: auto;
          right: $triangle-offset-default;
        }
      }
    }
    @media (min-width: $bp-min-medium) {
      &.is-open {
        // Match triangle to right-aligned dropdowns
        &:nth-last-child(2):nth-child(n + 2)::before {
          left: auto;
          right: $triangle-offset-default;
        }
      }
    }
  }
}

.primary-nav__plus-sign {
  display: block;
  height: 3rem;
  float: right;
  position: relative;
  top: rem(-11);
  width: rem(42);

  &::after,
  &::before {
    background-color: currentColor;
    content: "";
    display: block;
    height: rem(2);
    width: rem(19);
    position: absolute;
    left: calc(50% - #{rem(7)});
    top: calc(50% - #{rem(1)});
  }

  &::before {
    transform: rotate(270deg);
    transition: transform 0.3s ease-out;
  }

  .primary-nav__top-level-item.is-open &::before {
    transform: rotate(0deg);
  }

  @media (min-width: $bp-min-small-tablet) {
    display: none;

    .primary-nav__more & {
      display: inline-block;
      float: none;
      height: 10px;
      top: -1px;
      width: 10px;

      &::after,
      &::before {
        height: 2px;
        left: calc(50% - 5px);
        top: calc(50% - 1px);
        width: 10px;
      }
    }
  }
}

.primary-nav__sublevel {
  display: none;

  .primary-nav--shrinkable & {
    white-space: normal;
  }

  a {
    display: block;
  }

  @media (min-width: $bp-min-small-tablet) {
    max-width: rem(350);
    min-width: rem(275);
    padding: rem(18) rem(24);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    // Align certain nav dropdowns to the right:
    //   - Last main nav item when there are more than 2 items in the nav
    .primary-nav__top-level-item:last-child:nth-child(n + 3) & {
      left: auto;
      right: 0;
    }
  }

  @media (min-width: $bp-min-medium) {
    // Align certain nav dropdowns to the right:
    //   - Second-to-last main nav item when there are more than 2 items in the nav
    .primary-nav__top-level-item:nth-last-child(2):nth-child(n + 2) & {
      left: auto;
      right: 0;
    }
  }
}

.primary-nav__sublevel-item {
  margin: 0;
  padding: rem(12) 0 0;

  @media (min-width: $bp-min-small-tablet) {
    border-top: 1px solid currentColor;
    padding: rem(17) 0;
  }

  &:first-child {
    @media (min-width: $bp-min-small-tablet) {
      border-top: none;
      padding: 0 0 rem(17);
    }
  }

  &:last-child {
    @media (min-width: $bp-min-small-tablet) {
      padding: rem(17) 0 0;
    }
  }

  /* This is ugly, but necessary to deal with the magic shrinking menu */
  .primary-nav__sublevel-item[style*="none"] + & {
    padding-top: 0;
  }
}

.primary-nav__mobile-utility-nav {
  font-size: rem(17);
  margin: 0;
  padding: rem(21) 0 0;

  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }
}

.primary-nav__more {
  display: none;

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }
}