.site-logo {
  svg {
    max-height: rem(25);
    max-width: rem(100);
  }

  @media (min-width: $bp-min-small-tablet) {
    svg {
      max-height: rem(38);
      max-width: rem(147);
    }
  }

  @media print {
    a[href]:after {
      display: none;
    }
    svg {
      max-height: none !important; /* stylelint-disable-line declaration-no-important */
      max-width: 100mm !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}

.site-logo--full-width {
  svg {
    max-height: none;
    max-width: none;
    width: 100%;
    height: auto;
  }
}
