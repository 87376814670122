.static-footer {
  padding: rem(21) 0;
  padding-bottom: calc(#{$footer-height} + #{rem(21)});

  @media (min-width: $bp-footer-min-small) {
    padding-bottom: calc(#{$footer-height-mobile} + #{rem(21)});
  }

  @media (min-width: $bp-footer-min-medium) {
    padding: rem(38) rem(21);
    padding-bottom: calc(#{$footer-height} + #{rem(38)});
  }

  @media (min-width: $bp-footer-min-xlarge) {
    padding: $base-component-whitespace 0;
    padding-bottom: calc(#{$footer-height} + #{rem(38)});
  }
}

.static-footer--no-sticky-footer {
  padding-bottom: rem(21);

  @media (min-width: $bp-footer-min-medium) {
    padding-bottom: rem(38);
  }

  @media (min-width: $bp-footer-min-xlarge) {
    padding: $base-component-whitespace;
  }
}

.static-footer__cols {
  @include componentize();
  opacity: 0.999; // reset internal stacking context

  @media (min-width: $bp-footer-min-medium) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: rem(81); /* space for the sticky footer */
  }
}

.static-footer__col:first-child {
  @media (min-width: $bp-footer-min-medium) {
    max-width: rem(216);
  }

  @media (min-width: $bp-footer-min-medium-large) {
    max-width: none;
    min-width: rem(368);
    width: rem(368);
  }

  @media (min-width: $bp-footer-min-xlarge) {
    min-width: rem(479);
    width: rem(479);
  }
}

.static-footer__col:nth-child(2) {
  @media (min-width: $bp-footer-min-medium) {
    border-left-style: solid;
    border-left-width: 1px;
    max-width: calc(100% - #{rem(268)});
    width: 100%;
    padding-left: rem(55);
  }

  @media (min-width: $bp-footer-min-medium-large) {
    max-width: calc(100% - #{rem(441)});
  }

  @media (min-width: $bp-footer-min-xlarge) {
    max-width: 640px;
  }
}

.static-footer__col:last-child {
  @media (min-width: $bp-footer-min-medium) {
    border-top-style: solid;
    border-top-width: 1px;
    display: flex;
    justify-content: space-between;
    margin-top: rem(55);
    padding-top: rem(55);
    width: 100%;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    border-top: none;
    margin-top: 0;
    max-width: none;
    width: rem(198);
    padding-top: 0;
    margin-left: auto;
  }
}

.static-footer__col-subcol:first-child {
  @media (min-width: $bp-footer-min-medium) {
    width: calc(100% - #{rem(145)});
  }

  @media (min-width: $bp-footer-min-xlarge) {
    width: 100%;
  }
}

.static-footer__col-subcol:last-child {
  @media (min-width: $bp-footer-min-medium) {
    align-items: center;
    display: flex;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    display: block;
  }
}
