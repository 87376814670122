.news-listing {
  @include componentize();
  @include component-normalize();

  .promo-grid & {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .expert-promo--small + & {
    margin-top: rem(
      21
    ); /* Special case to handle single-up expert promo in promo grid */

    & > * {
      max-width: rem(570);
    }
  }

  a:not(.btn) {
    border-bottom-color: transparent;

    &:hover,
    &:focus {
      border-bottom-color: rgba($drexel-grey-text, 0.8);
    }
  }
}

.news-listing__mobile-button {
  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }
}

.news-listing__item-title {
  margin-bottom: rem(5);

  .wf-loading & {
    @include font(stevie-fallback, regular);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: rem(0.55);
  }

  .wf-active & {
    @include font(stevie, regular);
    font-size: rem(20);
    line-height: 1.4;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    &:after {
      display: block;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.news-listing__item {
  border-bottom: 1px solid currentColor;
  padding-bottom: rem(28);
  margin-bottom: rem(28);

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.news-listing__item-source {
  font-size: rem(16);
  line-height: 1.625;
}

.news-listing__item-dateline {
  margin-bottom: rem(17);

  /* stylelint-disable declaration-no-important */
  @media print {
    color: $black !important;
  }
  /* stylelint-enable declaration-no-important */
}

.news-listing__item-date {
  display: inline-block;
  font-size: rem(14);
  letter-spacing: 0.7px;
  line-height: 1.571;
  text-transform: uppercase;

  .news-listing__item-source + & {
    border-left: 1px solid currentColor;
    margin-left: rem(17);
    padding-left: rem(17);
  }
}

.news-listing__item-teaser {
  clear: both;
  font-size: rem(15);
  line-height: rem(29);

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: inherit !important;
    line-height: inherit !important;
  }
  /* stylelint-enable declaration-no-important */
}

.news-listing__button {
  text-align: center;

  @media print {
    text-align: left;
  }
}

.news-listing__image {
  height: auto;
  margin-bottom: rem(21);
  width: 100%;

  @media (min-width: $bp-min-small) {
    float: left;
    margin: 0 rem(32) rem(21) 0;
    width: rem(210);
  }

  @media print {
    float: none;
    max-width: rem(210);
  }
}

.news-listing__items {
  margin-bottom: rem(28);

  &:last-child {
    margin-bottom: 0;
  }
}
