.experience-grid {
  @include componentize-wide();
  @include component-normalize();

  & > *:last-child {
    margin-bottom: 0;
  }
}

.experience-grid__title {
  @include componentize();
  @include component-normalize();

  margin-bottom: rem(36); /* A little tighter than a standard component */
  padding-left: rem(27);
  position: relative;
  z-index: $stack-overlaps;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: rem(36);
  }

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: calc(100% + #{rem(70)});
    position: absolute;
    top: 0;
    left: 0;
    transition: height 1s;
    width: 4px;

    .in-play-mode & {
      height: calc(100% + #{rem(34)});
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: $print-standard-text-whitespace !important;

    &:before {
      display: none;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.experience-grid__view-more {
  padding: $base-component-mobile-whitespace rem(21);
  text-align: center;

  @media (min-width: $bp-min-small-tablet) {
    padding: $base-component-whitespace 0;
  }

  @media print {
    display: none;
  }
}

.experience-grid__main-promos {
  position: relative;
  z-index: $stack-overlaps;
  margin-bottom: $layout-columns-spacer;
}

.experience-grid__media {
  height: rem(315);
  margin-bottom: rem(10);
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    height: rem(530);
  }

  /*
   * What follows is a bit of a hack to get the notch to go full-bleed left without messing with the
   *   the promo grid itself
   * It depends heavily on matching values in the promo grid and assumes many things about it, so these
   *   components are closely linked and should be considered together when making changes to either
   */
  &:before {
    @media (min-width: $bp-min-small-tablet) {
      background-color: $white;
      content: "";
      display: block;
      height: $base-component-whitespace; /* this plus the margin bottom needs to equal the height of the first promo bump-up */
      position: absolute;
      bottom: 0;
      left: 0;
      transition: bottom 1s;
      width: calc(
        #{rem(21)} + #{$promo-spacer-narrow} +
          (
            ((100% - #{$double-side-margin}) / 3) -
              (#{$promo-spacer-narrow} * 2 / 3)
          )
      ); /* Needs to be the width of the promo + margin because of video animation */
      z-index: $stack-overlaps;
    }

    @media (min-width: $bp-min-xxlarge) {
      width: calc(
        ((100% - #{$max-width-rems}) / 2) + #{$promo-spacer-narrow} + ((
                #{$max-width-rems} / 3
              ) - (#{$promo-spacer-narrow} * 2 / 3))
      ); /* Needs to be the width of the promo + margin because of video animation */
    }

    .in-play-mode & {
      bottom: -#{$base-component-whitespace};
    }
  }
}

.experience-grid__image--hero {
  background-size: cover;
  background-position: center center;
  border-bottom: none;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: height 1s;
  width: 100%;
  z-index: $stack-internal;

  picture {
    background-color: $black; /* Allow for black bars in IE */
    width: 100%;
  }

  img {
    display: block;
    height: rem(315);
    max-width: none;
    width: auto;
    margin: auto;

    @media (min-width: $bp-min-small-tablet) {
      height: rem(530);
    }

    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }
  }

  &:after {
    background-color: transparent;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

.experience-grid__video-wrapper {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  iframe {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
