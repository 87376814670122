.expert-promo {
  position: relative;

  &.expert-promo--horizontal {
    @media (min-width: $bp-min-large) {
      display: grid;
      grid-template-columns:
        calc(50% - (#{$promo-spacer} / 2))
        $promo-spacer
        calc(50% - (#{$promo-spacer} / 2));
      grid-template-rows: auto auto;
    }
    @media print {
      display: block;
    }
  }

  &:after {
    content: "";
    display: none;
    height: calc(100% - #{rem(60)});
    position: absolute;
    left: 0;
    bottom: rem(30);
    width: rem(90);

    @media (min-width: $bp-min-small) {
      display: block;
    }

    @media (min-width: $bp-min-small-tablet) {
      display: none;
    }

    @media (min-width: $bp-min-large) {
      display: block;
    }

    .page-segment__sidebar & {
      @media (min-width: $bp-min-small) {
        display: none;
      }

      @media (min-width: $bp-min-large) {
        display: none;
      }
    }
  }

  &.expert-promo--small {
    margin-bottom: 3.75rem;
  }

  &.expert-promo--small:after {
    display: none;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    &:after {
      display: none !important;
    }

    & + .expert-promo {
      margin-top: $print-standard-component-whitespace !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__outer-image-wrapper {
  display: block;
  position: relative;
  z-index: $stack-internal;

  &:after {
    content: "";
    display: none;
    height: calc(100% - 4px); /* Allow for the image border bottom */
    position: absolute;
    left: 0;
    top: rem(30);
    width: rem(90);

    .expert-promo--small & {
      @media (min-width: $bp-min-small) {
        display: block;
      }

      @media (min-width: $bp-min-small-tablet) {
        display: none;
      }

      @media (min-width: $bp-min-large) {
        display: block;
      }
    }

    .expert-promo--sidebar & {
      display: none;
    }
  }

  .expert-promo--horizontal & {
    @media (min-width: $bp-min-small-tablet) {
      grid-column-start: 1;
      grid-column-end: span 1;
      grid-row-start: 1;
      grid-row-end: span 1;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    &:after {
      display: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__image-wrapper {
  display: block;
  margin-bottom: rem(11);
  position: relative;
  z-index: $stack-internal;
  border-bottom: none;

  @media (min-width: $bp-min-small) {
    margin-left: $base-component-whitespace;
    margin-bottom: 0;
    width: calc(100% - #{$base-component-whitespace});
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: rem(11);
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: $bp-min-large) {
    margin-left: $base-component-whitespace;
    margin-bottom: 0;
    width: calc(100% - #{$base-component-whitespace});
  }

  .page-header-admin__sidebar &,
  .page-header__sidebar & {
    @media (min-width: $bp-min-small) {
      margin-left: 0;
      width: 100%;
    }

    @media (min-width: $bp-min-large) {
      margin-left: 0;
      width: 100%;
    }
  }

  &:after {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    width: $base-component-whitespace;
  }

  img {
    height: auto;
    transition: transform 0.5s;
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-left: 0 !important;
    width: 50% !important;

    &:after {
      display: none !important;
    }

    img {
      max-width: 50%;
    }
  }
  /* stylelint-enable declaration-no-important */
}

a.expert-promo__image-wrapper {
  &:hover,
  &:focus {
    img {
      transform: scale(1.1, 1.1);

      @media print {
        transform: none;
      }
    }
  }
}

.expert-promo__image {
  overflow: hidden;
}

.expert-promo__body {
  position: relative;
  z-index: $stack-internal;

  @media (min-width: $bp-min-small) {
    @include overlap(up, rem(-30));

    background-color: $white;
    margin-left: rem(90);
    margin-bottom: rem(-30);
    padding: rem(30) rem(21) rem(37) rem(37);
    width: calc(100% - #{rem(90)});
  }

  @media (min-width: $bp-min-small-tablet) {
    @include overlap(up, 0);

    background-color: transparent;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  @media (min-width: $bp-min-large) {
    @include overlap(up, rem(-30));

    background-color: $white;
    margin-left: rem(90);
    margin-bottom: rem(-30);
    padding: rem(30) rem(21) rem(37) rem(37);
    width: calc(100% - #{rem(90)});
  }

  .page-header-admin__sidebar &,
  .page-header__sidebar & {
    @media (min-width: $bp-min-small) {
      margin-left: rem(30);
      width: calc(100% - #{rem(30)});
    }

    @media (min-width: $bp-min-large) {
      margin-left: rem(30);
      width: calc(100% - #{rem(30)});
    }
  }

  .expert-promo--small & {
    padding-bottom: 0;
  }

  .expert-promo--horizontal & {
    @media (min-width: $bp-min-small-tablet) {
      grid-column-start: 1;
      grid-column-end: span 3;
      grid-row-start: 2;
      grid-row-end: span 1;
      margin-bottom: rem(-15);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    padding: 0 !important;
    width: auto !important;
    top: auto !important;
    margin: $print-standard-text-whitespace 0 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__name {
  margin-bottom: 0;
  line-height: 1;
  color: inherit;

  .wf-loading & {
    @include font(futura-fallback, regular);
    font-size: rem(24);
    line-height: 1.6;
    letter-spacing: rem(-0.05);
  }

  .wf-active & {
    @include font(futura, regular);
    font-size: rem(30);
    line-height: 1.267;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-bottom: 0;

    a {
      font-family: inherit !important;
      font-weight: inherit !important;
      color: inherit !important;
      line-height: 1 !important;

      &:after {
        display: block;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__pronouns {
  font-size: rem(19);
  color: $grey-600;
  font-weight: 400;
  margin-bottom: 0;

  .wf-loading & {
    @include font(futura-fallback, regular);
    font-size: rem(19);
    line-height: 1.6;
    letter-spacing: rem(-0.05);
  }

  .wf-active & {
    @include font(futura, regular);
    font-size: rem(19);
    line-height: 1.267;
    font-weight: 400;
  }
}

.expert-promo__area {
  margin-top: 1rem;

  .wf-loading & {
    @include font(futurac-fallback, book);
    font-size: rem(19);
    line-height: 1.622;
    letter-spacing: rem(-1.2);
    word-spacing: rem(1.9);
  }

  .wf-active & {
    @include font(futurac, book);
    font-size: rem(22);
    line-height: 1.409;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    color: $black !important;
    margin-top: $print-standard-listitem-whitespace;
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__summary {
  font-size: rem(15);
  line-height: 1.733;

  @media print {
    font-size: inherit;
    line-height: inherit;
    margin-top: $print-standard-text-whitespace;
  }
}

.expert-promo__links {
  @include zero-list();

  font-size: rem(15);
  line-height: 1.733;
  margin-top: rem(32);
  padding-top: rem(32);
  position: relative;

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: $base-double-component-whitespace;

    @media (min-width: $bp-min-small) {
      left: -#{$base-component-whitespace};
    }

    @media (min-width: $bp-min-small-tablet) {
      left: 0;
    }

    @media (min-width: $bp-min-large) {
      left: -#{$base-component-whitespace};
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: inherit;
    line-height: inherit;
    margin-top: $print-standard-text-whitespace;
    padding-top: $print-standard-text-whitespace;

    &:before {
      left: 0;
      height: 1px;
      background-color: $black !important;
    }

    li {
      margin-bottom: $print-standard-text-whitespace;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__area,
.expert-promo__name {
  a {
    border-bottom-color: transparent;
    color: currentColor;
    font-weight: inherit;

    &:hover,
    &:focus {
      border-bottom-color: currentColor;
    }
  }
}

.expert-promo--horizontal .expert-promo__contact {
  @media (min-width: $bp-min-small-tablet) {
    align-self: center;
    grid-column-start: 3;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    padding-top: rem(21);
  }

  @media (min-width: $bp-min-large) {
    align-self: end;
    flex-direction: column;
    padding-top: 0;
    margin-bottom: rem(30);
  }

  @media (min-width: $bp-min-xxlarge) {
    align-self: center;
    flex-direction: row;
    padding-top: rem(21);
    margin-bottom: 0;
  }
}

.expert-promo__address {
  margin-right: $promo-spacer;
  position: relative;
  z-index: $stack-internal;

  @media (max-width: $bp-max-small-tablet) {
    margin-right: 0;
    margin-bottom: $promo-spacer;
  }


  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: rem(-21);
    left: 0;
    transform: translateX(-50%);
    width: rem(120);

    /* stylelint-disable declaration-no-important */
    @media print {
      display: none !important;
    }
    /* stylelint-enable declaration-no-important */
  }
}

.expert-promo__contact {
  display: flex;
  font-size: rem(17);
  justify-content: space-between;
  line-height: 1.588;
  margin-top: rem(42);

  @media (max-width: $bp-max-small-tablet) {
    display: block;
  }

  .expert-promo--sidebar & {
    display: block;
    padding-left: rem(67);

    @media (max-width: $bp-max-small-tablet) {
      padding-left: 0;
    }

    @media (min-width: $bp-min-small-tablet) {
      margin: 0 0 0 rem(30);
      padding: rem(30) 0 0 0;
    }

    @media (min-width: $bp-min-large) {
      padding: rem(30) rem(21) rem(37) rem(37);
      background-color: $white;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block;
    line-height: 1.5;
    padding: 0 !important;
    margin: rem(2) 0 0 !important;
    background-color: transparent !important;
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo__contact-links {
  .expert-promo--sidebar & {
    margin-top: rem(42);

    @media (max-width: $bp-max-small-tablet) {
      margin-top: 0;
    }
  }

  .icon-link {
    display: flex;
    font-size: rem(15);
    line-height: 1.588;
    margin: 0;

    .icon-link__icon,
    svg {
      height: rem(17);
      width: rem(17);
    }

    .icon-link__icon {
      top: 3px;
    }

    & + .icon-link {
      margin: 0;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin-top: 0 !important;

    .icon-link {
      font-size: inherit !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.expert-promo.expert-promo--gold-blue-light.expert-promo--sidebar{
  margin-bottom: 3.75rem;
}