.footer-copyright {
  font-size: rem(13);
  line-height: 1.733;
  margin: 0 auto rem(42);
  width: rem(210);

  .static-footer--no-sticky-footer & {
    margin-bottom: 0;
  }

  &.show-desktop {
    display: none;
  }

  &.show-mobile.show-tablet {
    display: block;
  }

  @media (min-width: $bp-footer-min-medium) {
    font-size: rem(15);
    margin: 0;
    width: 100%;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    &.show-desktop {
      display: block;
    }

    &.show-mobile.show-tablet {
      display: none;
    }
  }

  a {
    text-decoration: underline;
  }
}

.footer-school-copyright {
  .static-footer {
    padding: 0;
  }

  .static-footer__cols {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 2rem;
  }

  .footer-school {
    background-color: #02639a;
    padding: 2rem 2.5rem;

    p {
      letter-spacing: -1px;
      font-size: 24px;
      line-height: 1.1em;
      text-transform: uppercase;
      border-left-color: $drexel-gold;
      border-left-width: 4px;
      border-left-style: solid;
      padding-left: 15px;
      font-weight: 400;
    }

    @media (min-width: $bp-footer-min-medium-large) {
      max-width: 50%;

      p {
        font-size: 28px;
      }
    }
  }

  .footer-copyright {
    padding: 2rem 0;

    @media (min-width: $bp-footer-min-medium) {
      text-align: right;
      max-width: 45%;
      padding: 0;
    }
  }
}
