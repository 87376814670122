.simple-accordion:has(+ .simple-accordion) {
  border-bottom: 1px solid currentColor;

  // Secondary variant (seen in Area of Study)
  &.simple-accordion--secondary {
    border-bottom: none;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    border-bottom: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.simple-accordion__body {
  display: none;
  margin-top: rem(21);
  padding-left: rem(53);
  padding-bottom: rem(32);

  .accordion-group .simple-accordion:last-child & {
    padding-bottom: 0;
  }

  // Secondary variant (seen in Area of Study)
  .simple-accordion--secondary & {
    margin-top: rem(11);
    padding-left: 0;

    a:not(.btn) {
      border-bottom-color: transparent;
      border-bottom-width: 2px;

      &:hover,
      &:focus {
        border-bottom-color: currentColor;
      }
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    padding: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.simple-accordion__trigger {
  @extend .txt-h3;

  background-color: transparent;
  border: none;
  display: block;
  margin-bottom: 0;
  padding: rem(32) 0 rem(32) rem(53);
  position: relative;
  text-align: left;
  transition: background-color 0.3s;
  width: 100%;

  &:hover,
  &:focus {
    background-color: $drexel-grey-pale; /* Accessible placeholder; override in theme file */
  }

  // Secondary variant (seen in Area of Study)
  .simple-accordion--secondary & {
    padding: rem(4) 0 rem(4) rem(35);

    .wf-loading & {
      @include font(stevie-fallback, bold);
      font-size: rem(18);
      line-height: 1.267;
      letter-spacing: 0;
    }

    .wf-active & {
      @include font(stevie, bold);
      font-size: rem(18);
      line-height: 1.267;
    }
  }

  @media print {
    .accordion-group__title
      + .accordion-group__accordions
      .simple-accordion:first-child
      & {
      margin-top: $print-standard-text-whitespace !important;
    }
  }
}

.container--greyband .simple-accordion__trigger {

  &:hover,
  &:focus {
    background-color: $drexel-light-border-grey; /* Accessible placeholder; override in theme file */
  }
}

.simple-accordion__plus-sign {
  display: block;
  height: rem(42);
  float: left;
  position: absolute;
  left: 0;
  top: rem(32);
  width: rem(42);

  // Secondary variant (seen in Area of Study)
  .simple-accordion--secondary & {
    height: rem(30);
    top: 0;
    width: rem(15);
  }

  &::after,
  &::before {
    background-color: currentColor;
    content: "";
    display: block;
    height: rem(3);
    width: rem(15);
    position: absolute;
    left: calc(50% - #{rem(7)});
    top: calc(50% - #{rem(2)});
  }

  &::before {
    transform: rotate(270deg);
    transition: transform 0.3s ease-out;
  }

  .simple-accordion__trigger.is-open &::before {
    transform: rotate(0deg);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.simple-accordion__link {
  @media print {
    @include print-list;
  }
}
