.in-the-news-listing__item-title {
    margin-bottom: rem(5);
    svg {
      fill:none;
      stroke:$drexel-blue;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-width:45px;
      display: inline-block;
      width: 1rem;
      path {
        fill: none;
      }
    }
    
  
  
    .wf-loading & {
      @include font(stevie-fallback, regular);
      font-size: rem(20);
      line-height: 1.4;
      letter-spacing: rem(0.55);
    }
  
    .wf-active & {
      @include font(stevie, regular);
      font-size: rem(20);
      line-height: 1.4;
    }
  
    /* stylelint-disable declaration-no-important */
    @media print {
      &:after {
        display: block;
      }
    }
    /* stylelint-enable declaration-no-important */
  }