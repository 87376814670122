.slideshow-image {
  width: 100%;
}

.slideshow-image__container {
  background-color: black;
  overflow: hidden;
  width: 100%;

  picture {
    width: 100%;
  }

  img {
    display: block;
    height: rem(315);
    max-width: none;
    width: auto;
    margin: auto;

    @media(min-width: $bp-min-small-tablet) {
      height: rem(530);
    }

    @supports (object-fit: cover) {
      object-fit: cover;
      width: 100%;
    }
  }
}

.slideshow-image__caption {
  @include component-normalize();
  @include componentize();

  font-size: rem(14);
  line-height: 1.714;

  margin-bottom: rem(21);
  margin-top: rem(21);
}
