.go-back {
  margin-right: rem(16);
  padding-bottom: rem(21);

  &.go-back--mobile {
    margin-left: rem(21);
    margin-top: rem(28);

    .page-header__crumb & {
      margin: 0;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    border-bottom: 1px solid currentColor;
    margin-bottom: rem(10);
    max-width: rem(250);

    &.go-back--mobile {
      display: none;
    }
  }

  @media (min-width: $bp-min-xlarge) {
    margin-right: rem(48);
  }

  a {
    @include font(stevie, medium);

    border-bottom: none;
    display: block;
    font-size: rem(17);
    line-height: 1.471;
    padding-left: rem(42);
    position: relative;
  }

  svg {
    height: rem(32);
    position: absolute;
    top: rem(-3);
    left: 0;
    width: rem(32);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}
