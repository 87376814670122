.header.header-sticky {
    background: $drexel-navy;
    position: fixed;
    width: 100%;
    z-index: 4;
    overflow-x: hidden;
    top: 0;

    .header__top {
      align-items: center;
    }
  
    .header__branding--wide {
      display: inline-flex;
      align-items: center;
      width: 11rem;

      @media (max-width: $bp-min-small-tablet) {
        width: 7rem;
      }
    }
  
    .header__right {
      justify-content: center;
      display: inline-flex;
      margin: 0 0 0 1.3125rem;
  
      .header__utility-navigation {
        @media (max-width: $bp-min-small-tablet) {
          display: inline-flex;
          padding: .5rem 0;
        }
      }
  
      .search-trigger{
        border: none;
        color: $drexel-blue-light;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .search-flyout__search-box.is-open {
    width: auto;
}