.share {
  margin: 0 $standard-side-margin;
  right: 0;
  position: absolute;

  a {
    border: none;
  }

  // When inside a page segment, needs same top margin as page title
  .page-segment__body .breadcrumbs + & {
    margin-top: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: $base-component-whitespace;
    }
  }

  @media print {
    display: none;
  }
}

.share__body {
  float: right;
  position: relative;
  z-index: $stack-share;
}

.share__trigger {
  @include font(stevie, regular);

  background-color: transparent;
  border: none;
  font-size: rem(13);
  letter-spacing: 0.7px;
  padding: 0;
  text-transform: uppercase;
  transition: color $standard-transition;

  &:hover,
  &:focus {
    color: $black; /* Accessibility placeholder; override in theme file */
  }
}

.share__popup {
  display: none;
  position: absolute;
  top: calc(100% + #{rem(14)});
  right: 0;
}

.share__trigger-icon {
  align-items: center;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: flex;
  height: rem(52);
  justify-content: center;
  margin-bottom: rem(5);
  width: rem(52);

  svg {
    height: rem(34);
    width: rem(34);
  }
}

.share__btn {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  &.button_threads {
    svg {
      width: 24px;
    }
  }
}

.share__btn-icon {
  align-items: center;
  background-color: $drexel-grey-text; /* Accessibility placeholder; override in theme file */
  border: none;
  border-radius: 50%;
  color: $white;
  display: flex;
  height: rem(52);
  justify-content: center;
  margin-bottom: rem(5);
  margin-left: rem(11);
  width: rem(52);

  svg {
    width: 50%;
  }

  &:hover,
  &:focus {
    background-color: $black; /* Accessibility placeholder; override in theme file */
  }
}

.share__btn-text {
  /* This is clipped, to allow screen readers to read it */
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}

.share__trigger-text {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;

  @media (min-width: $bp-min-small-tablet) {
    position: static;
    clip: auto;
    overflow: auto;
    height: auto;
    width: auto;
  }
}
