.color-block-cta {
  @include component-normalize();

  background-color: $black; /* Accessibility placeholder; override in theme file */
  color: $white; /* Accessibility placeholder; override in theme file */
  padding: rem(30) rem(30) rem(42);

  svg {
    float: left;
    height: rem(24);
    width: rem(24);
  }

  img {
    display: block;
    height: auto;
    margin-bottom: rem(21);
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.color-block-cta__title {
  color: $white; /* Accessibility placeholder; override in theme file */
  margin-bottom: rem(11);

  .wf-loading & {
    @include font(futura-fallback, regular);
    font-size: rem(24);
    line-height: 1.6;
    letter-spacing: rem(0.15);
  }

  .wf-active & {
    @include font(futura, regular);
    font-size: rem(30);
    line-height: 1.267;
  }
}

.color-block-cta__body {
  font-size: rem(15);
  line-height: 1.733;
  margin-bottom: rem(21);

  svg + & {
    margin-left: rem(38);
  }
}

.color-block-cta__link {
  a.btn--secondary {
    background-color: $white; /* Accessibility placeholder; override in theme file */
    border-color: $white; /* Accessibility placeholder; override in theme file */
    color: $black; /* Accessibility placeholder; override in theme file */
    display: inline-block;
    line-height: 1;
    padding: rem(14);
  }
}

.color-block-cta__icon-links {
  .icon-link {
    display: block;
    font-size: rem(15);
    line-height: 1.733;
    margin: 0;

    & + .icon-link {
      margin: 0;
    }

    .icon-link__icon {
      margin-right: rem(5);
      top: 4px;
    }

    svg {
      height: rem(17);
      width: rem(17);
    }
  }
}
