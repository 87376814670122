.stat-item {

  .page-header-admin__content-wrapper & {
    margin-bottom: $base-component-whitespace;
  }

  // Single stat items that aren't part of a stat grid or a stat mosaic need some default spacing after them
  .layout-columns__col > & + * {
    margin-top: $base-component-mobile-whitespace;

    @media (min-width: $bp-min-small-tablet) {
      margin-top: $base-component-whitespace;
    }

    @media print {
      margin-top: $print-standard-listitem-whitespace;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    @include print-list;

    font-family: "stevie-sans", Arial, sans-serif !important;

    &,
    & * {
      color: $black !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.stat-item__link {
  margin-top: rem(20);
  text-align: center;

  .container--navyband & a {
    --link-color: $white;
    --link-hover-color: $white;
    --link-border: #{rgba($white, 0.5)};
    --link-hover-border: #{$white};
  }

  @media print {
    margin-top: rem(10);
    margin-bottom: rem(20);
    text-align: left;
  }
}
