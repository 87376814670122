.stat-mosaic-item {
  @include componentize();

  background-color: $black; /* Placeholder to showcase inversion and enforce accessible color; add actual colors in theme scss */
  color: $base-background-color; /* Placeholder to showcase inversion and enforce accessible color; add actual colors in theme scss */
  padding: rem(42) rem(32);
  text-align: center;
  width: 100%;

  .wf-loading & {
    @include font(futura-fallback, book);
    font-size: rem(15);
    line-height: 1.692;
    letter-spacing: rem(-0.55);
    word-spacing: rem(0.4);
  }

  .wf-active & {
    @include font(futura, book);
    font-size: rem(18);
    line-height: 1.4;
  }

  @media (min-width: $bp-min-small-tablet) {
    height: rem(271);
    overflow: hidden;
    width: rem(290);

    .wf-loading & {
      font-size: rem(17);
      letter-spacing: rem(-0.7);
      word-spacing: 0;
    }

    .wf-active & {
      font-size: rem(20);
    }
  }

  .stat-mosaic & {
    margin: 0; /* prevent weirdness in the CSS grid */
    max-width: 100%;
  }

  .stat-mosaic .stat-mosaic__promo--4 & {
    @media (min-width: $bp-min-small-tablet) {
      margin-left: auto;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    background-color: none !important;
    color: $black !important;
    height: auto !important;
    width: auto !important;
    max-width: 50ch !important;
    padding: 0 1cm !important;
    margin-top: $print-standard-component-whitespace !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  /* stylelint-enable declaration-no-important */
}

.stat-mosaic-item__value-wrapper {
  margin-bottom: rem(5);
}

.stat-mosaic-item__value {
  line-height: 0;

  .wf-loading & {
    font-size: rem(79);
  }

  .wf-active & {
    font-size: rem(84);
  }

  @media (min-width: $bp-min-small-tablet) {
    .wf-loading & {
      font-size: rem(87);
    }

    .wf-active & {
      font-size: rem(92);
    }
  }

  @media print {
    line-height: 1;
    font-size: rem(42) !important; 
  }
}

.stat-mosaic-item__prefix,
.stat-mosaic-item__postfix {
  font-size: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    font-size: rem(69);
  }
}
