.slate-event-list {
  @include component-normalize;
  margin-left: 3.75rem;
  padding-left: 0;

  .simple-accordion & {
    margin-bottom: 0;
    // padding-left: rem(20);
    // margin-left: 0;
  }
}

.slate-event-list__title:empty {
  display: none;
}

.slate-event-list-item {
  margin-bottom: rem(35);

  .icon-link{
    display: block;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  &:last-child,
  > *:last-child {
    margin-bottom: 0;
  }
}

.slate-event-list-item__title {
  margin-bottom: rem(4);

  a {
    color: currentColor;
    border-bottom: 4px solid rgba($drexel-grey-text, 0.5);
    font-weight: 600;

    &:hover,
    &:focus {
      border-bottom-color: $drexel-grey-text;
    }
  }

  .wf-loading & {
    @include font(stevie-fallback, bold);
    font-size: rem(20);
    line-height: 1.5;
    letter-spacing: rem(-0.2);
    word-spacing: rem(0.6);
    font-weight: 600;
  }

  .wf-active & {
    @include font(stevie, semibold);
    font-size: rem(20);
    line-height: 1.5;
  }
}

.slate-event-list-item__details,
.slate-event-list-item__address {
  display: block;
}

.slate-event-list-item__address {
  .icon-link__icon {
    margin-right: 0;
  }
}

.slate-event-list-item__register-link {
  display: inline-block;
  width: fit-content;
  margin-top: rem(10);

  &:not(.btn) {
    line-height: 1.1;
    position: relative;

  }

  @media print {
    display: none;
  }
}

.slate-event-list__no-results{
  @include containerize;
}

.js-slate-sort-state, .js-slate-sort-country{
  opacity: 0;
}