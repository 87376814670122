.footer-address {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: rem(17);
  line-height: 1.588;
  margin: rem(21) rem(21) rem(25);
  padding-bottom: rem(25);

  @media (min-width: $bp-footer-min-medium) {
    border-bottom: none;
    margin: 0;
    padding-bottom: 0;
    text-align: left;
  }
  @media (max-width: $bp-min-small-tablet) {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.footer-address__address {
  margin-bottom: rem(16);
  display: inline-block;
  width: auto;

  @media (max-width: $bp-min-small-tablet) {
    display: block;
  }
}

.footer-address__info {
  text-align: center;

  @media (min-width: $bp-footer-min-medium) {
    text-align: left;
  }

  @media (min-width: $bp-footer-min-medium) {
    .subfooter & {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      column-gap: 1.75rem;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    .subfooter & {
      width: auto;
    }
  }

  @media print {
      .subfooter & {
      display: block;
      margin: 0 auto;
    }
  }
  @media (max-width: $bp-min-small-tablet) {
    .subfooter & {
    width: 100%;
    justify-content: flex-start;
    }
  }

  @media (min-width: $bp-footer-min-medium-large) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 30px;
  }

  @media (min-width: $bp-footer-min-xlarge) {
    justify-content: flex-start;
  }

  @media print {
    display: block;
  }
}

.footer-address__links {
  width: auto;
  display: inline-block;

  .icon-link {
    @include font(stevie, regular);

    display: block;
    font-size: rem(15);
    margin-bottom: rem(7);

    & + .icon-link {
      margin-left: 0;
    }
  }

  .subfooter & {
    .icon-link {
      @include font(stevie, regular);
      display: flex;
      justify-content: center;
    }

    @media (min-width: $bp-footer-min-medium) {
      .icon-link {
        justify-content: flex-start;
      }
    }
  }

  .icon-link__icon {
    display: inline-block;
    height: rem(18);
    float: none;
    top: 2px;
    width: rem(18);

    svg {
      height: rem(18);
      width: rem(18);
    }

    @media (min-width: $bp-footer-min-medium) {
      float: left;
    }

    @media print {
      display: none;
    }
  }
}

.footer-address__logo {
  margin-bottom: rem(36);

  @media (min-width: $bp-footer-min-large) {
    margin-bottom: rem(18);
  }

  @media (min-width: $bp-footer-min-xlarge) {
    margin-bottom: rem(36);
  }

  svg {
    height: rem(100);
    margin: auto;
    /* Force a width on IE because IE doesn't do auto widths on SVGs */
    width: rem(96.73);
    /* IE 11 also doesn't do @supports, so we can use this as a trick to target everything else */
    @supports (width: auto) {
      width: auto; /* All the modern browsers */
    }

    @media (min-width: $bp-footer-min-medium) {
      margin: 0;
    }
  }
}

.footer-address__name {
  @include font(stevie, bold);
  font-size: rem(17);
  width: 100%;

  .subfooter & {
    @media (min-width: $bp-footer-min-medium) {
      margin-bottom: 0.25em;
    }
    @media (max-width: $bp-min-small-tablet) {
      text-align: center;
    }
  }
}

.footer-address__contact-link {
  margin-top: rem(30);
  text-align: center;

  @media (min-width: $bp-footer-min-medium) {
    text-align: left;
  }

  @media (max-width: $bp-min-small-tablet) {
    text-align: center;
  }

  @media (min-width: $bp-footer-min-medium-large) {
    margin-top: rem(23);
  }

  @media (min-width: $bp-min-large) {
    margin-top: rem(15);
  }

  @media (min-width: $bp-footer-min-xlarge) {
    margin-top: rem(30);
  }
}
