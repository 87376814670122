.footer-links {
  margin: rem(21) rem(21) rem(25);

  @media (min-width: $bp-footer-min-medium) {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-bottom: rem(38);
    margin: 0 0 rem(28);
  }
}

.footer-links__links {
  display: block;
  list-style-type: none;
  margin: 0;
  text-align: center;

  @media (min-width: $bp-footer-min-medium) {
    display: flex;
    justify-content: space-between;
    max-width: rem(588);
    text-align: left;
  }
}

.footer-links__link {
  margin: 0;
}

.footer-links__link a {
  border: 2px solid transparent;
  display: inline-block;
  text-transform: uppercase;
  padding: rem(10) rem(12);

  .wf-loading & {
    @include font(futura-fallback, regular);
    font-size: rem(15);
    letter-spacing: rem(1.35);
    word-spacing: rem(-0.35);
  }

  .wf-active & {
    @include font(futura, regular);
    font-size: rem(16);
    letter-spacing: rem(1.7);
  }

  @media (min-width: $bp-footer-min-medium) {
    display: block;
  }
}
