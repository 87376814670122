.video-grid {
  @include componentize();
  @include component-normalize();
  text-align: center;

  .search-results & {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }

  .layout-columns {
    text-align: left;
  }

  @media print {
    &
      + .video-grid
      > .layout-columns:first-child
      > .video-grid-block:first-child
      h3 {
      margin-top: 0 !important;
    }
  }
}

.video-grid__title {
  text-align: left;
  margin-right: $share-component-spacer; // make room for Share button

  @media print {
    margin-bottom: 0 !important;
  }
}

.js-video-grid__content {
  @media print {
    height: auto !important;
  }
}