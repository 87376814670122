.header {
  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .site-logo {
    width: 100%;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block;
    padding-bottom: rem(50);
    border-bottom: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.header__branding {
  padding: rem(14) 0;
  width: rem(100);
  min-height: rem(52);

  // To center the logo vertically
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $bp-min-small-tablet) {
    width: rem(147);
    min-height: rem(66);
  }

  .header--office & {
    @media (min-width: $bp-min-small-tablet) {
      margin-left: rem(38);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block;
    width: 100% !important;
    margin: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.header__branding--wide {
  width: rem(220);
}

.header__mobile-navigation {
  align-items: center;
  display: flex;
  width: auto;

  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.header__primary-navigation {
  display: none;
  height: 100%;
  overflow: auto;
  padding: $base-component-whitespace 0 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $stack-modal;

  @media (min-width: $bp-min-small-tablet) {
    border-bottom: 1px solid currentColor;
    display: block;
    overflow: visible;
    padding: 0;
    position: static;
    z-index: $stack-reset;
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  /* TODO: Breaking BEM a little here, this isn't ideal, but there's not a better way to deal with this either */
  .footer-info {
    margin-bottom: 0;
    padding-left: rem(15);
    padding-right: rem(15);

    @media (min-width: $bp-min-small-tablet) {
      display: none;
    }
  }

  .search-box {
    @media (min-width: $bp-min-small-tablet) {
      display: none;
    }
  }
}

.header__primary-navigation-close {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: rem(17);
  top: rem(16);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(14);
    line-height: 1.418;
    letter-spacing: rem(0.95);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(16);
    line-height: 1.2;
    letter-spacing: rem(1.5);
  }

  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }

  svg {
    display: inline-block;
    position: relative;
    top: 7px;
  }
}

// Currently used in .header--office only
.header__left {
  @media (min-width: $bp-min-small-tablet) {
    align-items: center;
    background-color: $black; /* Accessible placeholder */
    color: $white; /* Accessible placeholder */
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 43%;
    min-width: 33%;
  }

  @media (min-width: $bp-min-large) {
    min-width: 30%;
  }
}

.header-logo__left {
  @media (min-width: $bp-min-small-tablet) {
    align-items: center;
    background-color: transparent; /* Accessible placeholder */
    color: $white; /* Accessible placeholder */
    display: flex;
    justify-content: start;
    text-align: center;
    max-width: 55%;
    min-width: 33%;
  }

  @media (min-width: $bp-min-large) {
    min-width: 30%;
  }

  @media (max-width: $bp-max-small) {
    margin-left: auto;
    margin-right: auto;
  }

  .header__office-logo {
    max-height: 100px;

    @media (max-width: $bp-max-small) {
      max-height: 65px;
      margin: .5rem 0;
    }

    img {
      max-height: 100px;

      @media (max-width: $bp-max-small) {
        max-height: 60px;
      }
    }
  }
}

// Currently used in .header--office only
.header__right {
  display: flex;
  justify-content: space-between;
  margin: 0 $standard-side-margin;

  @media (min-width: $bp-min-small-tablet) {
    flex-direction: column;
    margin: 0;
    align-items: flex-end;
    justify-content: flex-end;
    min-width: rem(315); // width when search flyout is expanded
    max-width: 56%;

    &:has(.header__branding.header-office__branding){
      justify-content: space-between;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    margin: 0 !important;
    justify-content: flex-start;
    max-width: 100%;
  }
  /* stylelint-enable declaration-no-important */
}

.header__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: calc(100% - #{$double-side-margin});

  @media print {
    display: block;
    max-width: none;
    margin: 0;
  }

  .header--office & {
    // Title of office header needs to be full width on mobile;
    // overwriting max-width here to 100% because
    // max-width in .header__top above needs to stay put for other header instances.
    max-width: 100%;
    flex-direction: column;

    @media (min-width: $bp-min-small-tablet) {
      // When this breakpoint is reached,
      // office header is no longer full width
      max-width: calc(100% - #{$double-side-margin});
      flex-direction: row;
      flex-wrap: nowrap;
    }

    @media (min-width: $bp-min-xxlarge) {
      max-width: $max-width;
    }

    .utility-navigation__item:not(:first-child) {
      border-left-color: $drexel-grey-soft;
    }

    .utility-navigation__item:last-child {
      border-right: 1px solid $drexel-grey-soft;
      padding-right: 1.3125rem;
    }

    @media print {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $bp-min-xxlarge) {
    max-width: $max-width;
  }
}

.header__utility-navigation {
  display: none;
  padding: 1rem 0;

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
    align-items: center;
  }

  @media (min-width: $bp-min-xlarge) {
    display: flex;
    align-items: center;
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.header__office-title {
  text-transform: uppercase;
  padding: rem(21);
  text-align: center;
  max-width: calc(40ch + #{rem(42)});

  @media (max-width: $bp-max-small) {
    margin-left: auto;
    margin-right: auto;
  }

  .wf-loading & {
    @include font(futurab-fallback, demi);
    font-size: rem(20);
    line-height: 1.26;
    word-spacing: rem(-1.3);
    letter-spacing: rem(-0.95);
  }

  .wf-active & {
    @include font(futurab, demi);
    font-size: rem(21);
    line-height: 1.2;
  }

  @media (min-width: $bp-min-small-tablet) {
    padding: rem(34) rem(30);
    max-width: calc(40ch + #{rem(60)});

    .wf-loading & {
      font-size: rem(23);
      line-height: 1.25;
      letter-spacing: rem(-1.55);
      word-spacing: rem(-1.3);
    }

    .wf-active & {
      font-size: rem(24);
    }
  }

  a {
    border-bottom: none;
    color: $white; /* Accessible placeholder */

    &:hover,
    &:focus {
      color: $drexel-gold;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    text-align: left;
    padding: 0 !important;

    a:after {
      display: block;
    }
  }
  /* stylelint-enable declaration-no-important */
}
