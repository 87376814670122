.footer-quick-links {
    font-size: rem(17);
    line-height: 1.588;
    margin: rem(21) rem(21) rem(25);
    padding-bottom: rem(25);
  
    @media (min-width: $bp-footer-min-medium) {
      border-bottom: none;
      margin: 0;
      padding-bottom: 0;
      text-align: left;
    }
  
  
    .footer-quick-links__icon {
      span {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
  
        @media (max-width: $bp-min-small-tablet) {
          justify-content: center;
        }
      }
  
      svg {
        display: inline-block;
        height: 30px;
        width: 30px;
      }
  
      h3 {
        @include font(stevie, regular);
        display: inline-block;
        padding-left: .5rem;
        margin-bottom: 0;
        font-size: rem(16);
        font-weight: 700;
        line-height: 1.837;
        letter-spacing: rem(-0.2);
      }
    }
  
    .footer-quick-links__info {
      text-align: left;
  
      @media (max-width: $bp-min-small-tablet) {
        text-align: center;
      }
  
      .link-list-foot__list {
        display: grid;
        align-items: start;
        grid-template-columns: 1fr;
        grid-gap: .25rem;
        margin-left: 0;
        list-style-type: none;
        li{
          &::marker{
            display: none;
          }
        }
  
        @media (max-width: $bp-min-small-tablet) {
          grid-template-columns: 1fr !important;
  
        }
      }
  
      .link-list-foot {
        margin: 0;
  
        @media (max-width: $bp-footer-min-medium) {
          max-width: none;
        }
      }
  
      .link-list-foot__item {
        position: relative;
        margin-left: .45rem;
  
        a {
          span {
            color: $drexel-blue;
            letter-spacing: 0rem;
            
            text-transform: none;
  
            &:hover {
              border-bottom-color: $drexel-blue;
            }
          }
  
          @media (max-width: $bp-footer-min-medium) {
            text-align: center;
            margin: auto;
            display: inline-block;
            margin-left: 0;
          }
        }
      }
    }
  }