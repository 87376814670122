@import "../themes/base/_variables.scss";

.stat-circle {
  font-family: $futura;
  color: #07294d;
  position: relative;
  align-self: start;

  .container--navyband & {
    color: $white;
  }

  @media print {
    text-align: left;
  }
}

@include create-gradient-custom-props(
  $core-theme-gradient-colors,
  "stat-circle"
);

.stat-circle__circle-wrapper {
  height: 0;
  position: relative;
  padding-bottom: 100%;

  @media print {
    height: auto;
    padding: 0;
    display: inline;
  }
}

.stat-circle__circle {
  --deg: calc(360deg * (var(--percent) / 100));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999em;
  background-color: #f1f1f1;
  background-image: conic-gradient(
    var(--start-color, #ffc501),
    var(--end-color, #ff901c) var(--deg),
    transparent var(--deg),
    transparent 360deg
  );
  gap: 0rem;

  @media (min-width: $bp-min-small) {
    gap: 1rem;
  }

  .container--greyband & {
    background-color: $grey-400;
  }

  @media print {
    display: inline;
    position: static;
    border-radius: 0;
  }
}

.stat-circle__background {
  position: absolute;
  content: "";
  background-color: $white;
  border-radius: 999em;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;

  .container--greyband & {
    background-color: $greyband-color;
  }

  .container--navyband & {
    background-color: $navyband-color;
  }

  @media print {
    display: none;
  }
}

.stat-circle__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  path {
    stroke: #f1f1f1;
    stroke-width: 10;
    stroke-dasharray: 300;
    fill: none;

    .stat-circle:not(.is-shifted) & {
      animation: path-fill 5s linear forwards;
    }
  }

  @media print {
    display: none;
  }
}

@keyframes path-fill {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}

.stat-circle__data {
  position: relative;
  z-index: $stack-internal;
  line-height: 1;
  display: flex;
  align-items: baseline;
  font-weight: 300px;

  @media print {
    display: inline;
    font-weight: 700;
    line-height: inherit;
    margin-bottom: 0;
  }
}

.stat-circle__value {
  font-size: 100px;

  @media (min-width: $bp-min-xsmall) {
    font-size: 115px;
  }

  @media print {
    font-size: inherit;
  }
}

.stat-circle__suffix {
  font-size: 69px;

  @media print {
    font-size: inherit;
  }
}

.stat-circle__inner-text {
  position: relative;
  z-index: $stack-internal;
  padding-right: 2.25rem;
  padding-left: 2.25rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 1.4;
  width: 100%; // fixes IE 11 flex bug

  @media (min-width: $bp-min-xsmall) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
  @media (min-width: $bp-min-small) {
    padding-right: 7rem;
    padding-left: 7rem;
  }

  @media (min-width: $bp-min-medium) {
    font-size: 2rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media print {
    &,
    & p {
      display: inline;
      font-size: inherit;
      line-height: inherit;
      text-align: inherit;
      padding: 0;
    }
  }
}

.layout-columns--2-up{
  .stat-circle__circle{
    gap: 0rem;
  }
  .stat-circle__inner-text {

    @media (min-width: $bp-min-small) {
      padding-right: 3rem;
      padding-left: 3rem;
    }
  }
}

.layout-columns--3-up {
  @media (min-width: $bp-min-small-tablet) {
    .stat-circle__inner-text {
      font-size: 1.5rem;
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .stat-circle__value {
      font-size: 70px;
    }
    .stat-circle__circle {
      gap: .15rem;
    }
  }
  @media (min-width: $bp-min-large) {
    .stat-circle__inner-text {
      font-size: 1.5rem;
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .stat-circle__value {
      font-size: 80px;
    }
    .stat-circle__circle {
      gap: .15rem;
    }
  }
}

.layout-columns--4-up {
  @media (min-width: $bp-min-small-tablet) {
    .stat-circle__inner-text {
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
  @media (min-width: $bp-min-large) {
    .stat-circle__inner-text {
      font-size: 1.5rem;
      padding-right: 2rem;
      padding-left: 2rem;
    }
    .stat-circle__value {
      font-size: 80px;
    }
    .stat-circle__circle {
      gap: .15rem;
    }
  }
}

.stat-circle__outer-text {
  text-align: center;
  margin-top: 40px;
  font-size: 1.75rem;
  line-height: 1.4;

  @media (min-width: $bp-min-medium) {
    font-size: 2rem;
  }

  @media print {
    &,
    & p {
      display: inline;
      font-size: inherit;
      line-height: inherit;
      text-align: inherit;
      margin: 0;
    }
  }
}
