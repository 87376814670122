.tour {
  @include componentize-wide();

  margin-top: calc(0px - #{$base-component-whitespace});

  @media print {
    margin-top: 0;
  }
}

.tour__content {
  position: relative;
  width: 100%;

  @media print {
    break-after: page;
    page-break-after: always;
  }
}

.tour__column {
  position: relative;

  @media (min-width: $bp-min-large) {
    max-width: calc(
      ((100% - 1400px) / 2) + #{rem(980)}
    ); /* One side's margin plus 70% of the max 1400px content well */

    /* For non IE Browsers only, switch map to desktop view */
    @supports (position: sticky) {
      background-image: linear-gradient(
        90deg,
        $white 0%,
        $white calc(100% - 100px),
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

.tour__intro {
  font-size: rem(27);
  line-height: 1.556;
  margin: 0 auto;
  padding: rem(63) 0 rem(63);
  position: relative;
  width: calc(100% - #{$double-side-margin});

  @media (min-width: $bp-min-xxlarge) {
    margin-right: rem(170);
    max-width: rem(810);
  }

  strong,
  b {
    @include font(stevie, regular);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    padding: 0 !important;
    width: auto !important;
    font-size: inherit !important;
    line-height: inherit !important;
    margin: $print-standard-component-whitespace 0;
  }
  /* stylelint-enable declaration-no-important */
}

.tour__map {
  @media (min-width: $bp-min-large) {
    /* For non IE Browsers only, switch map to desktop view */
    @supports (position: sticky) {
      min-height: 100vh; // to match the map within
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(
        ((100% - 1400px) / 2) + #{rem(420)} + 100px
      ); /* One side's margin plus 30% of the max 1400px content well + 100px overlap */

      &::after {
        background-image: linear-gradient(
          0deg,
          $white 0%,
          rgba(255, 255, 255, 0) 100%
        );
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 10%;
        width: 100%;
        z-index: $stack-internal;
        pointer-events: none;
      }
    }
  }
}

.tour__map-list {
  position: sticky;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  height: calc(100vh - #{$footer-height-mobile});

  @media (min-width: $bp-footer-min-xlarge) {
    height: calc(100vh - #{$footer-height});
  }
}

.tour__map-list-tabs {
  position: sticky;
  top: 0;
  background: $white;
}

.tour__map-container {
  height: rem(210);
  width: 100%;

  @media (min-width: $bp-min-large) {
    height: rem(525);

    /* For non IE Browsers only, switch map to desktop view */
    /* stylelint-disable declaration-no-important */
    @supports (position: sticky) {
      height: 100vh;
      right: 0;
      top: 0;
      position: sticky !important;
      width: 100%;
    }
    /* stylelint-enable declaration-no-important */
  }
}

.tour__stops {
  position: relative;
  width: 100%;
}

.tour__map-marker {
  align-items: center;
  background-color: $black;
  background-size: cover;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: rem(21);
  height: rem(36);
  justify-content: center;
  transition: color 0.1s, background-color 0.1s, height 0.1s, width 0.1s,
    font-size 0.1s;
  width: rem(36);
  z-index: $stack-overlaps;

  &:hover {
    color: $white;
  }

  /* stylelint-disable property-no-unknown, declaration-no-important */
  @media print {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    background-color: $black !important;
    color: $white !important;
    text-decoration: none !important;
  }
  /* stylelint-enable property-no-unknown, declaration-no-important */
}

.tour__list {
}

.tour__list ul {
  margin: 0;
  list-style-type: none;
}

.tour__list-item button {
  appearance: none;
  background: none;
  border: none;
  border-bottom: rem(4) solid $drexel-gold;
}
