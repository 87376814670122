// Some variables for sortable tables
.responsive-table {
  --col-header-bg-color: #fff;
  --col-header-color: #000;
  --col-header-hover-bg-color: #666;
  --col-header-hover-color: #fff;
}

@media screen and (-ms-high-contrast: active),
  screen and (forced-colors: active) {
  :root {
    --col-header-bg-color: ButtonFace;
    --col-header-color: ButtonText;
    --col-header-hover-bg-color: Canvas;
    --col-header-hover-color: CanvasText;
  }
}

// We're doing a desktop-first styling so that the table semantics are
// preserved for desktop screenreaders.

.responsive-table__scroller {
  overflow: auto;
}

.responsive-table {
  max-width: calc(100% - ($standard-side-margin * 2));
  margin:auto;

  @media (min-width: $bp-min-xxlarge) {
    max-width: $max-width;
  }

  &:not(:first-child) {
    margin-top: $base-component-whitespace;
  }

  .is-hidden--filters,
  .is-hidden--search {
    display: none;
  }

  caption {
    text-align: left;
  }

  tr {
    padding: 0;
  }

  th,
  td {
    &:first-child {
      padding-left: 0;
    }

    tr:last-child & {
      border-bottom: none;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  // Adding some utility classes from Provost, so not exactly BEM
  th.heading-long {
    width: 50%;
  }

  th.heading-short {
    width: 16.66%;
  }

  // Don't display the pseudo headers for our desktop table
  // They'll only display on mobile
  td::before {
    display: none;
  }

  h3,
  h3 a {
    @extend .txt-h3--secondary;
  }
}

.responsive-table__table {
  min-width: 100%;

  .simple-accordion & {
    margin-bottom: $base-half-component-whitespace;
  }
}

// Interactive tables should probably have their cell content top-aligned
.responsive-table__table.is-interactive td {
  vertical-align: top;
}

.responsive-table__description {
  display: block;
  font-weight: 300;
  margin-bottom: rem(35);
}

// Non-table layout for mobile and print
@media screen and (max-width: $bp-min-small), print and (max-width: 5in) {
  .responsive-table__scroller {
    overflow: hidden;
  }

  .responsive-table {
    &:not(:first-child) {
      margin-top: $base-component-mobile-whitespace;
    }

    &,
    tr,
    td {
      display: block;
    }

    tr[hidden="true"] {
      display: none;
    }

    tr {
      padding: rem(21) 0;
    }

    th,
    td {
      border: none;
      text-align: left;
      padding: rem(14) 0;
    }

    th[role="rowheader"] {
      font-size: rem(18);
    }

    // Pseudo-headers for mobile testing
    td::before {
      @include font(stevie, semibold);

      display: block;
      line-height: 1.2;
      margin-bottom: rem(7);
    }

    tr.responsive-table__header-row {
      display: none;
    }
  }
}

// A few print styles
@media print {
  .responsive-table {
    margin-top: $print-standard-component-whitespace !important;
    margin-bottom: $print-standard-component-whitespace !important;
  }

  thead {
    break-after: avoid-page;
    page-break-after: avoid;
    break-inside: avoid-page;
    page-break-inside: avoid;
  }

  .responsive-table__scroller {
    overflow: visible;
  }

  .responsive-table__table {
    break-inside: auto;
    page-break-inside: auto;
  }

  .responsive-table__row {
    break-inside: avoid-page;
    page-break-inside: avoid;
  }
}

// Responsive table forms (sort, filter, etc)
// Going back to mobile-first

.responsive-table__form-wrapper {
  margin-bottom: rem(28);
  position: sticky;
  top: rem(21);
  z-index: $stack-overlaps;

  > .responsive-table__form {
    padding: rem(21);
    background-color: $white;
    color: $drexel-grey-medium;
  }

  @media (min-width: $bp-min-medium) {
    margin-bottom: 0;
    max-height: none;
    position: static;
    z-index: $stack-reset;

    > .responsive-table__form {
      padding: 0;
      background-color: transparent;
    }
  }
}

// Hide sortable form wrapper if it's the only one when sorts show in table
@media (min-width: $bp-min-small) {
  .responsive-table__fieldset--sortable,
  .responsive-table__form-wrapper:has(form
      > .responsive-table__fieldset--sortable:first-child:last-child),
  .responsive-table__form-wrapper:has(form
      > .responsive-table__fieldset--sortable:first-child:last-child)
    + .responsive-table__divider {
    display: none;
  }
}

.responsive-table__filters {
  padding: rem(21);
  background-color: $drexel-grey-pale;
  color: $drexel-grey-medium;

  @media (min-width: $bp-min-medium) {
    background-color: transparent;
    padding: 0;
  }

  h3,
  h4,
  label {
    color: $black;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    display: none;

    .wf-loading & {
      @include font(futura-fallback, demi);
      font-size: rem(19);
      line-height: 1.484;
      letter-spacing: rem(0.8);
      word-spacing: rem(-1.8);
    }

    .wf-active & {
      @include font(futura, demi);
      font-size: rem(20);
      line-height: 1.4;
      letter-spacing: 1.6px;
    }

    button {
      @include zero();

      background-color: transparent;
      border: none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      position: relative;
      outline: none;
      text-transform: uppercase;
      text-align: left;
      width: 100%;

      &:after,
      &:before {
        background-color: currentColor;
        content: "";
        display: block;
        height: rem(2);
        width: rem(23);
        position: absolute;
        right: rem(21);
        top: calc(50% - #{rem(1)});
      }

      &:before {
        transform: rotate(270deg);
        transition: transform 0.3s ease-out;
      }
    }

    &.responsive-table__filters-header--mobile {
      // Show the mobile H3
      display: block;
    }

    @media (min-width: $bp-min-medium) {
      // Hide the mobile H3 from all
      &.responsive-table__filters-header--mobile {
        display: none;
      }
      // Hide the desktop H3 from sighted users only
      /* stylelint-disable declaration-no-important */
      &.responsive-table__filters-header--desktop {
        display: block;
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        overflow: hidden;
        height: 1px;
        width: 1px;
        word-wrap: normal;
      }
      /* stylelint-enable declaration-no-important */
    }
  }

  &.is-open h3 button:before {
    transform: rotate(0deg);
  }

  &.in-toggle-mode h3 {
    cursor: pointer;
  }
}

.responsive-table__button--clear-all {
  @include font(stevie, regular);

  background-color: transparent;
  border: none;
  border-bottom: rem(2) solid rgba($drexel-blue-dark, 0);
  border-radius: 0;
  font-size: rem(15);
  line-height: 1;
  margin: 0;
  text-transform: lowercase;
  transition: color $standard-transition, border-color $standard-transition;
  padding: 0 0 rem(1);
  white-space: nowrap;

  // Rare use of specific color here because this does need to be clearly deliminated from the text; override in theme when necessary
  &:hover,
  &:focus {
    border-bottom-color: var(--link-hover-border, #{$drexel-blue-dark});
    color: var(--link-hover-color, #{$drexel-blue-dark});
  }

  @media screen and (min-width: $bp-min-medium) {
    align-self: flex-end;
    margin-bottom: rem(9);
    margin-left: $promo-spacer;

    @supports (column-gap: 10px) {
      margin-left: 0; // column gap will be handled via "column-gap"
    }
  }
}

.responsive-table__form-toggle {
  display: none;
  margin-top: rem(11);
  max-height: calc(100vh - #{rem(210)});
  overflow: hidden scroll;

  .responsive-table__filters.is-open & {
    display: block;
  }

  @media (min-width: $bp-min-medium) {
    display: block;
    margin-top: 0;
    max-height: none;
    overflow: visible;
  }
}

.responsive-table__fieldset {
  border: none;
  padding: 0;
  margin: 0;

  @media (min-width: $bp-min-medium) {
    max-height: none;
    overflow: visible;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @supports (column-gap: 10px) {
      column-gap: #{$promo-spacer};
    }
  }

  @media (min-width: $bp-min-large) {
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}

@media (min-width: $bp-min-small) {
  .responsive-table__fieldset--sortable {
    display: none;
  }
}

.responsive-table__fieldset-item {
  margin-top: rem(21);

  @media (min-width: $bp-min-medium) {
    width: calc(50% - (#{$promo-spacer} / 2));
  }

  @media (min-width: $bp-min-large) {
    margin-top: 0;
    width: calc(25% - (#{$promo-spacer} * 3 / 4));
  }
}

.responsive-table__label {
  @include font(futurab, demi);
  color: $black;
  font-size: rem(16);
  letter-spacing: 1px;
  line-height: 1.5;
  text-transform: uppercase;

  &:before {
    top: 0;
  }
}

.responsive-table__search-wrapper:has(+ .responsive-table__fieldset--filterable) {
  .responsive-table__button--clear-all {
    display: none;
  }

  @media (min-width: $bp-min-large) {
    margin-bottom: rem(21);
  }
}

.responsive-table__input--search {
  max-width: 50ch;

  @media (max-width: $bp-max-xsmall) {
    font-size: rem(17);
    padding: 0.5rem;
  }
}

.responsive-table__input--select,
.responsive-table__input--text {
  font-size: rem(17);
  line-height: 1.529;
}

.responsive-table__input--select {
  padding: rem(6) rem(45) rem(6) rem(6);
}

.responsive-table__input--text {
  padding: rem(6);
}

.responsive-table__divider {
  border: none;
  margin-top: rem(25);
  padding-bottom: rem(44);
  position: relative;

  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

// Sortable tables

// Hide the SVG sprites
#responsive-table__sprites {
  display: none;
}

.responsive-table svg path,
.responsive-table__icon-template path {
  transition: fill $standard-transition, stroke $standard-transition;

  // Override SVG defaults causing issues with #use
  fill: inherit;
}

// Assuming that table headers with IDs have them because they're sortable
.responsive-table th[id] {
  padding: 0;
}

.responsive-table__button--sort {
  background: transparent;
  border: 1px solid transparent;
  color: inherit;
  font: inherit;
  text-align: left;
  cursor: pointer;
  padding: rem(14);
  white-space: nowrap;
  width: 100%;
  min-width: 4.5em;
  display: grid;
  grid-template-columns: minmax(auto, max-content) 0.65em auto;
  grid-template-areas: "t a x" "t d x";
  transition: color $standard-transition, background-color $standard-transition;

  &:focus,
  &:hover,
  &:focus-within {
    color: var(--col-header-hover-color, $white);
    background-color: var(--col-header-hover-bg-color, #666);
    outline: none;
  }

  &::after {
    content: "";
    grid-area: x;
  }

  th:first-child > & {
    padding-left: 0;
  }

  th:last-child > & {
    padding-right: 0;
  }

  // Sort button text (the table header)
  & > span {
    grid-area: t;
    padding-right: 0.5em;
  }

  // Sort button ascending arrow
  > .asc {
    grid-area: a;
    align-self: center;
  }

  // Sort button descending arrow
  > .des {
    grid-area: d;
    align-self: center;
  }

  svg.sort {
    fill: transparent;
    stroke: var(--col-header-color, #000);
    max-width: 0.65em;
    max-height: 1.2em;
  }

  [aria-sort="ascending"] > & svg.asc,
  [aria-sort="descending"] > & svg.des {
    stroke: var(--col-header-color, #000);
    fill: var(--col-header-color, #000);
  }

  th:focus > & svg.sort,
  th:hover > & svg.sort,
  th:focus-within > & svg.sort {
    stroke: var(--col-header-hover-color, #fff);
  }

  [aria-sort="ascending"] > &:focus svg.asc,
  [aria-sort="ascending"] > &:hover svg.asc,
  [aria-sort="descending"] > &:focus svg.des,
  [aria-sort="descending"] > &:hover svg.des {
    stroke: var(--col-header-hover-color, #fff);
    fill: var(--col-header-hover-color, #fff);
  }
}
