@use "sass:math";

.stat-grid {
  @include componentize;
  @include component-normalize;

  margin-bottom: 0;

  .layout-columns {
    align-items: center;
    row-gap: math.div($layout-columns-spacer-wide, 2);
  }

  + .stat-grid {
    margin-top: math.div($layout-columns-spacer-wide, 2);
  }

  @media print {
    max-width: none;

    & + .stat-grid {
      margin-top: 0;
    }
    // Progressive enhancement: Remove space between consecutive stat grids
    &:has(+ .stat-grid) {
      margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
    }

    .layout-columns__col + .layout-columns__col {
      margin-top: $print-standard-text-whitespace;
    }
  }
}

.stat-grid--equal {
  .layout-columns {
    align-items: stretch;
  }
}

.stat-grid--staggered {
  @media (min-width: $bp-min-small-tablet) {
    .layout-columns--2-up,
    .layout-columns--4-up {
      padding-bottom: 45px;

      .stat-item:nth-child(2n) {
        position: relative;
        top: 45px;
      }
    }

    .layout-columns--3-up {
      padding-bottom: 90px;

      .stat-item:nth-child(3n + 2) {
        position: relative;
        top: 45px;
      }

      .stat-item:nth-child(3n) {
        position: relative;
        top: 90px;
      }
    }
  }

  @media (min-width: $bp-min-large) {
    .layout-columns--4-up {
      padding-bottom: 135px;

      .stat-item:nth-child(4n + 2) {
        position: relative;
        top: 45px;
      }

      .stat-item:nth-child(4n + 3) {
        position: relative;
        top: 90px;
      }

      .stat-item:nth-child(4n) {
        position: relative;
        top: 135px;
      }
    }
  }
}
