.link-list {
  @include component-normalize();
  @include componentize();

  .page-header__content-wrapper & {
    max-width: rem(810);
  }

  @media (min-width: $bp-min-small-tablet) {
    .container > & {
      margin-right: $standard-side-margin;
      max-width: calc(
        ((100% - #{$double-side-margin}) * 67 / 100) - (#{$promo-spacer} / 2)
      );
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    .container > & {
      margin-left: auto;
      margin-right: auto;
      max-width: $max-width-rems;
      padding-left: rem(477);
      padding-right: rem(85);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    &,
    .page-header__content-wrapper & {
      padding: 0 !important;
      max-width: none !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.link-list__list {
  @include zero-list();

  @media print {
    list-style: disc;
    //   margin-left: 1em;
  }
}

.link-list__item {
  margin-bottom: rem(7);

  a {
    align-items: center;
    border-bottom: none;
    display: flex;
    justify-content: flex-start;
    line-height: 1;
    text-transform: uppercase;

    .wf-loading & {
      @include font(futurab-fallback, bold);
      font-size: rem(13);
      line-height: 1.163;
      letter-spacing: rem(2.55);
    }

    .wf-active & {
      @include font(futurab, bold);
      font-size: rem(15);
      line-height: 1;
      letter-spacing: rem(2.25);
    }

    span {
      border-bottom: rem(4) solid transparent;
      border-top: rem(4) solid transparent; /* keep the span centered */
      transition: color $standard-transition, border-color $standard-transition;
    }

    &:hover svg,
    &:focus svg,
    &:hover span,
    &:focus span {
      color: $drexel-navy;
    }
  }

  svg {
    display: inline-block;
    height: rem(40);
    margin-right: rem(11);
    transform: rotate(180deg);
    width: rem(40);
    flex-shrink: 0;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    @include print-list;

    a {
      display: inline;
      text-transform: none;
      letter-spacing: 0 !important;
      font-size: inherit !important;

      &:hover span {
        border: none;
      }
    }

    svg {
      display: none;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.link-list-wrapper {
  // The link-list element needs to be wrapped in a div on the 404 page, to prevent it from being
  // a direct child of the .container element (which would give it an undesired left margin).
  // No styles are applied to this wrapper directly (its only purpose is to wrap the link-list element).
  // This is not a proper BEM classname, but is already being used in production so we're sticking with it.
}
