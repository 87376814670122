.scrolling-list {
  @include containerize();
  background-color: $base-box-bg-color;
  line-height: 1.5;
  max-height: rem(128);
  min-width: rem(210);
  overflow-y: auto;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }
}

.scrolling-list__list {
  @include zero-list;
  padding: rem(13) rem(26) rem(13) rem(16);
}

.scrolling-list__item {
  margin-bottom: $standard-box-list-spacing;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }
}
