.in-the-news {
  @include componentize();

  padding: rem(21);

  @media (min-width: $bp-min-small-tablet) {
    padding: rem(54);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    padding: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.in-the-news__body {

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
  }

  & > * {
    @media (min-width: $bp-min-small-tablet) {
      max-width: 50%;
    }
  }

  .quote-promo {
    margin-bottom: rem(25);

    @media (min-width: $bp-min-small-tablet) {
      margin-bottom: 0;
    }
  }
}

.in-the-news__title {
  border-bottom: rem(4) solid currentColor;
  margin-bottom: rem(32);
  max-width: rem(448);
  padding-bottom: rem(11);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(19);
    line-height: 1.516;
    letter-spacing: rem(0.4);
    word-spacing: rem(0.4);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: 1.6px;
  }
}

.in-the-news__image {
  margin-bottom: rem(25);

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
    margin-right: rem(38);
    width: 25%;
  }

  @media print {
    width: 50% !important;
  }

  img {
    height: auto;
    width: 100%;
  }
}

.in-the-news__link {
  @include font(stevie, regular);
  font-size: rem(17);
  line-height: 1.471;

  @media (min-width: $bp-min-small-tablet) {
    border-left: 1px solid currentColor;
    margin-left: rem(46);
    padding-left: rem(46);
    width: 25%;
  }

  a {
    border-bottom-color: transparent;
  }

  @media print {
    @include print-list;
  }
}
