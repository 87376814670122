.person-title-card {
  @include component-normalize();

  align-items: start;
  display: grid;
  grid-template-columns: calc(100% - #{$share-component-spacer}) $share-component-spacer;
  grid-template-rows: auto auto auto auto auto auto;
  margin-left: $standard-side-margin;
  margin-right: $standard-side-margin;
  margin-top: $standard-side-margin;

  .btn + .btn {
    margin-left: rem(14);
  }

  .rich-text {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    /*
     * The math here is much like that for page-header (it's worth looking at its comments to
     *   help understand this), except that we have both a 33/67 and a 67/33 to deal with.
     * The calculations below give us:
     * margin | 33% of the content well | universal promo spacer | 33% of the content well |
     *   universal promo spacer | 33% of the content well | margin
     */
    margin-top: initial;
    grid-template-columns:
      $standard-side-margin calc(
        ((100% - #{$double-side-margin}) * 33 / 100) -
          (2 * #{$promo-spacer} / 3)
      )
      $promo-spacer calc(
        ((100% - #{$double-side-margin}) * 33 / 100) -
          (2 * #{$promo-spacer} / 3)
      )
      $promo-spacer calc(
        ((100% - #{$double-side-margin}) * 33 / 100) - (#{$promo-spacer} / 3)
      )
      $standard-side-margin;
    grid-template-rows: auto auto auto auto rem(70) auto auto;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: $bp-min-large) {
    grid-template-rows: auto auto auto auto rem(70) auto;
  }

  @media (min-width: $bp-min-xxlarge) {
    /*
     * Same basic idea as above, except we can use explicit widths for the columns since we know
     *   the width of the content well.
     */
    grid-template-columns:
      calc((100% - #{$max-width-rems}) / 2) rem(447) $promo-spacer rem(447)
      $promo-spacer rem(447) calc((100% - #{$max-width-rems}) / 2);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.person-title-card__title {
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 1;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 4;
    grid-column-end: span 3;
  }

  @media (max-width: $bp-max-small-tablet) {
    margin-bottom: 1.5rem;
  }
}

.person-title-card__pronouns {
  font-size: rem(32);
  font-weight: 400;
  color: $grey-600;


  .wf-loading & {
    @include font(futura-fallback, regular);
    font-weight: 400;
    font-size: rem(32);
    line-height: 1.6;
    letter-spacing: rem(-0.05);
  }

  .wf-active & {
    @include font(futura, regular);
    font-weight: 400;
    font-size: rem(32);
    line-height: 1.267;
  }
}

.person-title-card__image {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  grid-row-end: span 1;

  img {
    margin-bottom: rem(21);
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 4; /* Why the extra row?  It pushes the title, name, and tags up where they belong */

    img {
      margin-bottom: 0;
    }
  }

  @media print {
    width: 50%;
  }
}

.person-title-card__info {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 3;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 4;
    grid-column-end: span 3;
    grid-row-start: 2;
    grid-row-end: span 1;
  }
}

.person-title-card__tags {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 4;
  grid-row-end: span 1;
  padding-bottom: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 4;
    grid-column-end: span 3;
    grid-row-start: 3;
    grid-row-end: span 1;
    padding-bottom: 0;
  }

  @media print {
    padding-bottom: $print-standard-text-whitespace;
  }
}

.person-title-card__summary {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 5;
  grid-row-end: span 1;
  padding-bottom: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 2;
    grid-column-end: span 5;
    grid-row-start: 6;
    grid-row-end: span 1;
  }

  @media (min-width: $bp-min-large) {
    grid-column-start: 2;
    grid-column-end: span 3;
    grid-row-start: 6;
    grid-row-end: span 1;
    padding-bottom: 0;
  }

  
  @media (max-width: $bp-max-small-tablet) {
    padding-bottom: 0  
  }

  & > .rich-text {
    margin-left: 0;
    max-width: rem(810);
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    max-width: none !important;
    padding: 0 !important;
    margin-top: $print-standard-text-whitespace;
  }
  /* stylelint-enable declaration-no-important */
}

.person-title-card__contact {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 6;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 2;
    grid-column-end: span 5;
    grid-row-start: 7;
    grid-row-end: span 1;
  }

  @media (min-width: $bp-min-large) {
    grid-column-start: 6;
    grid-column-end: span 1;
    grid-row-start: 6;
    grid-row-end: span 1;
    justify-self: end;
  }
}

.person-title-card__name {
  margin-bottom: 0;
  margin-left: -2px;
  display: inline-block;

  .wf-active & {
    font-size: 3.75rem;

    @media (max-width: $bp-max-small-tablet) {
      line-height: 1;
    }
  }
}

.person-title-card__job-title {

  .wf-loading & {
    @include font(futura-fallback, light);
    font-size: rem(26);
    line-height: 1.519;
    letter-spacing: rem(-1.05);
  }

  .wf-active & {
    @include font(futura, light);
    font-size: rem(32);
    line-height: 1.235;
  }

  @media (min-width: $bp-min-small-tablet) {
    .wf-loading & {
      font-size: rem(31);
      line-height: 1.465;
      letter-spacing: rem(-1.15);
      word-spacing: rem(0.4);
    }

    .wf-active & {
      font-size: rem(38);
      line-height: 1.184;
    }
  }
}

.person-title-card__affiliation {
  margin-top: rem(24);

  a {
    border-bottom-color: transparent;
  }

  a:hover,
  a:focus {
    border-bottom-color: currentColor;
  }
}

.person-title-card__tag-label {
  display: inline-block;
  margin-right: $standard-side-margin;
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(19);
    line-height: 1.687;
    letter-spacing: rem(-1.1);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(20);
    line-height: 1.6;
  }

  @media print {
    display: block;
    margin-right: 0;
  }
}

.person-title-card__tag-list {
  border-top: 1px solid currentColor;
  margin-top: rem(33);
  padding-top: rem(33);

  .btn--secondary {
    margin-right: $standard-side-margin;
    padding: rem(7) rem(14);
  }

  @media print {
    a {
      @include print-list;
    }
  }
}

.person-title-card__info-columns {
  border-top: 1px solid $drexel-grey-soft;
  font-size: rem(15);
  margin-top: rem(38);
  padding-top: rem(38);

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
  }
}

.person-title-card__info-column {
  margin-bottom: $base-half-component-whitespace;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
    margin-right: rem(42);

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: $bp-min-large) {
    margin-right: rem(84);
  }
}

.person-title-card__info-column-title {
  color: $drexel-grey-headline;
  margin-bottom: rem(16);
  text-transform: uppercase;

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(15);
    line-height: 1.654;
    letter-spacing: rem(1.5);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(16);
    line-height: 1.5;
    letter-spacing: rem(1.5);
  }
}

.person-title-card__info-column-item {
  margin-bottom: rem(11);
}
