.mini-promos__promo {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: rem(30);

  a {
    border-bottom: rem(4) solid transparent;
    color: currentColor;
    font-weight: 500;
    text-decoration: none;
  }

  &.clickable-card--whole-card:hover a,
  a:hover,
  a:focus {
    border-bottom-color: rgba($drexel-grey-text, 0.5);
  }

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }

  @media (min-width: $bp-min-large) {
    display: flex;
  }

  @media print {
    @include print-list;
  }
}

.mini-promos__image {
  margin-right: rem(26);
  max-width: rem(130);
  min-width: rem(130);
  overflow: hidden;
  width: rem(130);

  border-bottom: rem(4) solid transparent;
  transition: border $standard-transition;

  img {
    height: auto;
    transition: transform 0.25s;
    width: 100%;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover & {
    border-bottom-color: $drexel-gold;

    img {
      transform: scale(1.1, 1.1);
    }
  }

  @media print {
    display: none;
  }
}

.mini-promos__title a {
  @include font(stevie, regular);

  font-size: rem(16);
  line-height: 1.625;

  /* stylelint-disable declaration-no-important */
  @media print {
    font-family: "stevie-sans", Arial, sans-serif !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }
  /* stylelint-enable declaration-no-important */
}
