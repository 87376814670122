.article-tag-list {
  @include componentize();
  @include component-normalize();

  border-top: 1px solid currentColor;
  padding-top: rem(65);

  .student-story-card + & {
    border-top: none;
    padding-top: rem(74);
  }

  @media print {
    display: none;
  }
}

.article-tag-list__title {
  text-transform: uppercase;
  margin-bottom: rem(21);

  .wf-loading & {
    @include font(futura-fallback, demi);
    font-size: rem(19);
    line-height: 1.477;
    letter-spacing: rem(0.5);
  }

  .wf-active & {
    @include font(futura, demi);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: rem(0.5);
  }
}

.article-tag-list__tags {
  .btn {
    margin-bottom: rem(11);
    margin-right: rem(11);
  }
}
