.info-list {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0;
  align-items: baseline;
  width: max-content;
  max-width: 100%;
}

.info-list__label {
  font-weight: 700;
  grid-row: auto;
  grid-column: 1 / 2;
  margin: 16px 0 0 0;

  &:first-child {
    margin-top: 0;
  }
}

.info-list__value {
  margin: 0 0 0 2ch;
  grid-row: auto;
  grid-column: 2 / 3;
}
