.explore-map {
  @include componentize-wide();

  margin-top: $base-component-whitespace;
}

.explore-map__tablist {
  display: none;
  list-style-type: none;
  margin-left: initial;
  padding-bottom: rem(8);
  border-bottom: rem(4) solid $drexel-gold;

  @media (min-width: $bp-min-large) {
    flex-basis: 100%;
    display: flex;
  }
}

.explore-map__tabpanels-panel {
  display: none;

  &.is-active {
    display: block;
  }
}

.explore-map__tablist-tab {
  margin-right: rem(4);

  &:last-child {
    margin-right: 0;
  }
}

.explore-map__tablist-tab-button {
  @include font(stevie, regular);

  font-weight: 700;
  border: none;
  display: inline-block;
  font-size: rem(17);
  line-height: 1.471;
  margin: 0;
  padding: rem(6) rem(11);
  transition: color 0.3s, background-color 0.3s;
  background-color: transparent;
  color: $drexel-blue;

  &:hover,
  &.is-active {
    background-color: $drexel-navy;
    color: $white;
  }

  &:focus {
    outline: 4px solid $drexel-gold;
  }
}

// .explore-map__content
// The "content", including map/list and location details

.explore-map__content {
  position: relative;
  display: grid;
  grid-template-rows: 40vh auto;
  grid-template-columns: auto;

  @media (min-width: $bp-min-large) {
    grid-template-rows: auto;
    grid-template-columns: calc(40% + 100px) calc(60% - 100px);
  }
}

// .explore-map__column
// The colum where the main rich text content appears

.explore-map__column {
  height: 100%;
  max-width: none;
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;

  @media (min-width: $bp-min-large) {
    z-index: $stack-overlaps;
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
    width: 100%;
  }
}

// .explore-map__map*
// The map part of the explore map

.explore-map__map {
  height: 100%;
  position: static;
  width: auto;

  @media (min-width: $bp-min-large) {
    &::after {
      background-image: linear-gradient(
        270deg,
        $white 0%,
        $white calc(100% - 100px),
        rgba(255, 255, 255, 0) 100%
      );
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100px;
      pointer-events: none;
    }
  }

  @media (max-width: $bp-max-medium) {
    display: block !important;
  }
}

// .explore-map__map-list
// The map and list tabbed area
.explore-map__map-list {
  background: $white;
  position: sticky;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
  height: 100%;

  @media (max-width: $bp-max-medium) {
    z-index: $stack-internal;
  }

  @media (min-width: $bp-min-large) {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    height: calc(100vh - #{$footer-height-mobile});
  }

  @media (min-width: $bp-footer-min-xlarge) {
    height: calc(100vh - #{$footer-height});
  }
}

.explore-map__map-list-tabs {
  position: sticky;
  top: 0;
  background: $white;
  margin: 0 $standard-side-margin;
  display: flex;

  @media (min-width: $bp-min-large) {
    margin-right: 120px;
  }
}

.explore-map__map-container {
  height: 100%;
}

// .explore-map__list*
// The main list of explore locations, on desktop

.explore-map__list {
  padding: 0 calc(#{$standard-side-margin} + 100px) 0 $standard-side-margin;

  @media (max-width: $bp-max-medium) {
    display: none !important;
  }

  ul {
    margin: 0;
    list-style-type: none;
  }
}

.explore-map__list-item button {
  appearance: none;
  background: none;
  text-align: left;
  width: 100%;
  border: 0;
  border-bottom: 1px solid $grey-900;
  padding: rem(40) 0;
  display: flex;
  flex-direction: column;

  &:active {
    color: inherit;
    appearance: none;
  }

  &:focus,
  &:active {
    color: $drexel-navy;
    outline: 3px solid $drexel-navy;
  }
}

.explore-map__list-item-title {
  display: block;
  font-size: rem(20);
  font-weight: 700;
  margin-bottom: rem(16);
  border-bottom: 4px solid transparent;

  .explore-map__list-item.is-active & {
    border-color: $grey-400;
  }
}

.explore-map__list-item-description {
  display: block;
  font-size: rem(16);
}

// .explore-map__dropdown*
// The dropdown location list for mobile

.explore-map__dropdown-controls {
  width: 100%;
  border-bottom: rem(4) solid $drexel-gold;
  padding-bottom: rem(8);
  margin-bottom: rem(16);

  @media (min-width: $bp-min-large) {
    display: none !important;
  }
}

.explore-map__dropdown-open {
  // Styles from .tabs__tablist-tab-button
  @include font(stevie, regular);

  border: none;
  display: inline-block;
  font-size: rem(17);
  line-height: 1.471;
  margin: 0;
  padding: rem(6) rem(11);
  transition: color 0.3s, background-color 0.3s;
  background-color: $drexel-navy;
  color: $white;
  appearance: none;

  &:hover,
  &:focus {
    outline: 3px solid $drexel-gold;
  }
}

.explore-map__dropdown-close {
  background-color: $drexel-gold;
  position: relative;
  display: block;
  margin-left: auto;
  border: none;
  appearance: none;
  padding: rem(12) rem(16) rem(12) rem(48);
  text-transform: uppercase;
  font-size: rem(14);

  .wf-loading & {
    @include font(futurab-fallback, demi);
    letter-spacing: rem(0.65);
  }

  .wf-active & {
    @include font(futurab, demi);
    letter-spacing: rem(1.5);
  }

  &:before,
  &:after {
    background-color: currentColor;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    left: 16px;
    top: calc(50% - 1px);
    width: rem(21);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:before {
    transform: rotate(45deg);
  }

  &:active,
  &:focus {
    outline: 3px solid $drexel-navy;
  }
}

.explore-map__dropdown-list {
  display: none;

  @media (max-width: $bp-max-medium) {
    &.is-visible {
      padding: 0 $double-side-margin;
      overflow: auto;
      background: $white;
      display: block;
      position: fixed;
      z-index: $stack-modal;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      list-style-type: none;
    }
  }

  @media (min-width: $bp-min-large) {
    display: none;
  }
}

// .explore-map__stop*
// The content within each "stop"/location in the map

.explore-map__stop {
  display: none;

  @media (max-width: $bp-max-medium) {
    margin-top: $standard-side-margin;
  }

  &.is-active {
    display: block;
  }
}

.explore-map__stop-image {
  margin-bottom: rem(42);

  picture,
  img {
    height: auto;
    width: 100%;
  }

  @media (min-width: $bp-min-large) {
    width: calc(100% + 100px);
    position: relative;
    left: -100px;
  }
}

.explore-map__stop-body {
  margin: 0 $double-side-margin;

  .rich-text {
    max-width: none;
  }

  @media (min-width: $bp-min-large) {
    margin: 0 100px;
  }
}
