.stat-card {
  font-family: $futura;

  background-color: $black; /* Placeholder to showcase inversion and enforce accessible color; add actual colors in theme scss */
  color: $base-background-color; /* Placeholder to showcase inversion and enforce accessible color; add actual colors in theme scss */
  //padding: rem(42) rem(32);
  padding: 2.25rem 1.75rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @supports (display: grid) {
    // breaks things in IE 11 flexbox, so limit it to grid browsers
    .stat-grid--equal & {
      width: 100%;
      height: 100%;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    background-color: transparent !important;
    color: $black !important;
    padding: 0;
    text-align: left;

    .stat-grid--equal & {
      width: auto !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.stat-card__value-wrapper {
  margin-bottom: rem(5);
  font-weight: 400;
  line-height: 1.2875;

  @media print {
    display: inline;
    font-weight: 700;
    line-height: inherit;
    margin-bottom: 0;
  }
}

.stat-card__value {
  font-size: 2.75rem;

  @media (min-width: $bp-min-small-tablet) {
    font-size: 3rem;
  }

  @media print {
    font-size: inherit;
  }
}

.stat-card__prefix,
.stat-card__suffix {
  font-size: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    font-size: 2.75rem;
  }

  @media print {
    font-size: inherit;
  }
}

.stat-card__eyebrow {
  letter-spacing: 0.0625em;
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;

  @media print {
    display: inline;
    font-size: inherit;
    font-weight: 700;
    line-height: inherit;

    &:after {
      content: " \2014 "; // m-dash
    }
  }
}

.stat-card__description {
  font-size: 1.45rem;
  line-height: 1.4444;
  font-weight: 400;

  @media print {
    &,
    & p {
      display: inline;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }
  }
}

.stat-item.stat-item--clickable.stat-card.js-clickable-card.clickable-card--whole-card.clickable-card--initialized {
  margin-bottom: 3.75rem;
}