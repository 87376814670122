.notice-container {

    padding: 3rem;

    &:not(:first-child) {
        border-top: 1px solid #aaa;
    }

    .notice__header {
        h2 {
            font-family: 'futura-pt-bold';
            font-size: 1.5rem;
            text-transform: uppercase;
            line-height: 1.5rem;
            margin-bottom: 0;
            color: $drexel-navy;
        }

        h3 {
            font-family: 'stevie-sans';
            font-size: 1.2rem;
            margin-bottom: 0;
        }

        p {
            line-height: 1.5rem;
        }

        .bold {
            font-weight: bold;
        }
    }

    .notice__blurb {
        margin-top: 1.5rem;

        .continue-reading {
            margin-top: 1.5rem;
            display: block;
        }

    }

    hr {
        padding-top: 3rem;
        border-color: #ccc;
    }

    .notice__description {
        overflow: hidden;
        line-height: 2rem;
        max-height: 8rem;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 4;
    }
}