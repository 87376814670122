.footer-social {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 $standard-side-margin rem(28);
  padding-bottom: rem(38);

  &.show-desktop {
    display: none;
  }

  @media (min-width: $bp-footer-min-medium) {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 0 rem(28);
  }

  @media (min-width: $bp-footer-min-xlarge) {
    width: 100%;

    &.show-desktop {
      display: flex;
    }

    &.show-mobile.show-tablet {
      display: none;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.footer-social__label {
  text-transform: uppercase;
  margin-bottom: rem(24);

  .wf-loading & {
    @include font(futurac-fallback, book);
    font-size: rem(15);
    line-height: 2.057;
    letter-spacing: rem(0.65);
    word-spacing: rem(1.95);
  }

  .wf-active & {
    @include font(futurac, book);
    font-size: rem(18);
    line-height: 1.722;
    letter-spacing: 1.5px;
    min-width: fit-content;
  }

  @media (min-width: $bp-footer-min-medium) {
    margin-bottom: 0;
  }
}

.footer-social__links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  margin: 0;

  @media (min-width: $bp-max-small){
    justify-content: flex-start;

  }

  .subfooter & {
    flex-wrap: wrap;
    position: relative;

    @media (min-width: $bp-footer-min-medium) {
      margin-right: -25px;
    }
  }

  svg {
    height: rem(24);
    width: rem(24);
  }
}

.footer-social__link {
  margin: .5rem 1rem;

  .subfooter & {
    margin-top: rem(11);
    margin-bottom: rem(11);
  }

  @media (min-width: $bp-footer-min-small) {
    margin: .5rem 1.5rem;
  }

  &:last-child {
    @media (min-width: $bp-footer-min-medium) {
      margin-right: 0;

      .subfooter & {
        margin-right: 1.5rem;
      }
    }
  }
}
