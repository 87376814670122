// This overrides a lot of react-select styles, hence the sometimes convoluted selectors and extreme nesting
.event-typeahead {
  border-bottom: 1px solid currentColor;
  font-size: rem(17);
  line-height: 1.529;
  padding-bottom: rem(21);
  margin-bottom: rem(21);
  align-self: flex-end;

  &:last-child,
  .admissionrep-facets & {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .admissionrep-facets & {
    margin-bottom: rem(21);
  }

  .visit-facets &,
  .program-listing & {
    margin-bottom: rem(5);
  }

  @media (min-width: $bp-min-small-tablet) {
    .staff-search-listing__facets &,
    .program-listing__facets & {
      margin-left: $promo-spacer;
    }
    .staff-search-listing__facets &:first-child,
    .staff-search-listing__facets .event-facets__group-clear:first-child + &,
    .program-listing__facets &:first-child,
    .program-listing__facets .event-facets__group-clear:first-child + & {
      margin-left: 0;
    }

    @supports (column-gap: 10px) {
      .staff-search-listing__facets &,
      .program-listing__facets & {
        margin-left: 0; // column gap will be handled via "column-gap" in _event-facets.scss
      }
    }

    .admissionrep-facets & {
      width: calc(33% - #{$promo-spacer});
      max-width: rem(400);
      display: inline-block;
      vertical-align: bottom;
      margin-left: 0;
      margin-right: $promo-spacer;
      //margin-bottom: 0;
    }
  }

  h4 {
    margin-bottom: 0;

    label {
      @include font(futurab, demi);
      font-size: rem(16);
      letter-spacing: 1px;
      line-height: 1.5;
      text-transform: uppercase;
    }
  }

  .event-typeahead__control {
    border-color: currentColor;
    border-radius: 0;
  }

  .event-typeahead__control.event-typeahead__control--is-focused {
    border-color: currentColor;
    box-shadow: none;
    outline: none;
  }

  .event-typeahead__menu {
    border: 1px solid currentColor;
    border-top: none;
    border-radius: 0;
    position: static;
    margin-bottom: rem(42);
    margin-top: 0;
    overflow: auto;
    z-index: $stack-overlaps;

    @media (min-width: $bp-min-small-tablet) {
      position: absolute;
    }
  }

  .event-typeahead__option.event-typeahead__option--is-focused {
    background-color: transparent;
    font-weight: 600;
  }

  .event-typeahead__option {
    cursor: pointer;
    font-size: rem(15);
    line-height: 1.733;
  }

  .event-typeahead__multi-value {
    background-color: transparent;
    border: 1px dashed currentColor;
    border-radius: 0;
  }

  /* This is bad BEM, but it's on the react-select library, so we're stuck with it */
  .event-typeahead__multi-value__label {
    cursor: text;
    white-space: normal;
  }

  /* This is bad BEM, but it's on the react-select library, so we're stuck with it */
  .event-typeahead__multi-value__remove {
    background-color: $drexel-grey-text;
    border-radius: 50%;
    color: $base-background-color;
    cursor: pointer;
    height: rem(16);
    margin: rem(6);
    width: rem(16);

    &:hover {
      color: $base-background-color;
      background-color: rgba($drexel-grey-text, 0.5);
    }

    svg {
      @include zero();

      display: block;
      height: rem(16);
      width: rem(16);
    }
  }

  .event-typeahead__control {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPGRlZnM+CiAgICA8cGF0aCBpZD0ic2VhcmNoLWEiIGQ9Ik0xMy43ODgzMjUxLDE2LjQ1MjIxOTkgQzEyLjYxODA2NzcsMTcuMTU2MTkxNiAxMS4yNDc0Mjg2LDE3LjU2MTA3NjIgOS43ODIyMzQ0NiwxNy41NjEwNzYyIEM1LjQ4Mzc5MDI0LDE3LjU2MTA3NjIgMiwxNC4wNzc4NjY1IDIsOS43ODA1MzgxMiBDMiw1LjQ4Mjc1NDQyIDUuNDgzNTY3MDEsMiA5Ljc4MjIzNDQ2LDIgTDkuNzgyMjM0NDYsMi43NDkyMTYwNiBMOS43ODIyMzQ0NiwyIEMxNC4wODAxNjUzLDIgMTcuNTY0MTI5Nyw1LjQ4MzAzNTU3IDE3LjU2NDEyOTcsOS43ODA1MzgxMiBDMTcuNTY0MTI5NywxMS41MzE4ODM4IDE2Ljk4NTM2MSwxMy4xNDgwODczIDE2LjAwODYzNDYsMTQuNDQ4Mzg3IEMxNi4wMjExNzUxLDE0LjQ2MDYyNTEgMTYuMDMzNTUxMiwxNC40NzMxMjYxIDE2LjA0NTc1NTcsMTQuNDg1ODg5NyBMMjAuODA3NzE2NSwxOS40NjU5OTk2IEMyMS4zNzk2NDM3LDIwLjA2NDEyNzMgMjEuMzU4NDA0MSwyMS4wMTI2NDQzIDIwLjc2MDI3NjQsMjEuNTg0NTcxNSBDMjAuMTYyMTQ4NywyMi4xNTY0OTg4IDE5LjIxMzYzMTcsMjIuMTM1MjU5MiAxOC42NDE3MDQ0LDIxLjUzNzEzMTUgTDEzLjg3OTc0MzcsMTYuNTU3MDIxNiBDMTMuODQ3MzcxNCwxNi41MjMxNjYzIDEzLjgxNjg5OTUsMTYuNDg4MTg4NSAxMy43ODgzMjUxLDE2LjQ1MjIxOTkgWiBNOS43ODIyMzQ0NiwyLjc0OTIxNjA2IEw5Ljc4MjIzNDQ2LDMuNDk4NDMyMTIgQzYuMzExMDQ5OTgsMy40OTg0MzIxMiAzLjQ5ODQzMjEyLDYuMzEwMzkzOSAzLjQ5ODQzMjEyLDkuNzgwNTM4MTIgQzMuNDk4NDMyMTIsMTMuMjUwMjQ5NCA2LjMxMTI5NTU2LDE2LjA2MjY0NDEgOS43ODIyMzQ0NiwxNi4wNjI2NDQxIEMxMy4yNTI0NDgsMTYuMDYyNjQ0MSAxNi4wNjU2OTc1LDEzLjI0OTk1NyAxNi4wNjU2OTc1LDkuNzgwNTM4MTIgQzE2LjA2NTY5NzUsNi4zMTA2ODYyMyAxMy4yNTI2OTM1LDMuNDk4NDMyMTIgOS43ODIyMzQ0NiwzLjQ5ODQzMjEyIEw5Ljc4MjIzNDQ2LDIuNzQ5MjE2MDYgWiIvPgogIDwvZGVmcz4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+CiAgICA8dXNlIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIgeGxpbms6aHJlZj0iI3NlYXJjaC1hIi8+CiAgPC9nPgo8L3N2Zz4K");
    background-repeat: no-repeat;
    background-position: rem(4) 50%;
    background-size: rem(19) rem(19);
    padding-left: rem(21);
  }

  .event-typeahead__indicator-separator,
  .event-typeahead__dropdown-indicator {
    display: none;
  }
}

.event-typeahead__menu-list {
  max-height: none;
}
