.contact-card-set {
  @include componentize();
  @include component-normalize();
}

.contact-card-set__cards {
  @media (min-width: $bp-min-small) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.contact-card-set__title {
  .page-segment & {
    /* This gets a smaller title in this situation for some reason */
    text-transform: uppercase;
    margin-bottom: rem(21);

    .wf-loading & {
      @include font(futura-fallback, demi);
      font-size: rem(19);
      line-height: 1.503;
      letter-spacing: rem(0.6);
      word-spacing: rem(-0.15);
    }

    .wf-active & {
      @include font(futura, demi);
      font-size: rem(20);
      line-height: 1.4;
      letter-spacing: rem(1.6);
    }
  }
}
