.notice-promo {
  color: inherit;
  display: block;
  flex-direction: column;
  justify-content: space-between;
  padding: rem(32);
  transition: color 0s;
  margin-bottom: calc(2.625rem / 2);

  @media (min-width: $bp-min-small-tablet) {
    display: flex;
    min-height: rem(250);
  }

  :not(.layout-columns)>& {
    border-bottom: none;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  &.clickable-card--whole-card:hover {
    color: inherit;

    :not(.layout-columns)>& {
      border: 1px solid currentColor;
      /* For accessibility purposes; turn off in theme if you add other hover states */
    }
  }

  @media print {
    display: block;
    min-height: 0;
    padding: 0;
    margin-bottom: $print-standard-component-whitespace;
  }
}

.notice-promo__date,
.notice-promo__time,
.notice-promo__date-card,
.notice-promo__title {
  display: block;
}

.notice-promo__date-card {
  @include font(stevie, light);

  font-size: rem(14);
  line-height: 1.571;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  padding-left: $standard-side-margin;
  position: relative;

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }

  &:before {
    background-color: currentColor;
    content: "";
    display: block;
    height: calc(100% + 2rem);
    position: absolute;
    top: -2rem;
    left: 0;
    width: 2px;
  }

  @media print {
    border-left: none;
    line-height: inherit;
    padding-left: 0;
    margin-bottom: 0;
    width: auto;

    &:before {
      display: none;
    }
  }
}

.notice-promo__date-card.right-side {
  padding-left: 0;
  padding-right: $standard-side-margin;
  text-align: right;
}

.notice-promo__title {
  margin-top: auto;
}

.location {
  @include font(futurab, demi);
  font-weight: 600;
  font-size: rem(19);
  line-height: 1.5;
  color: inherit;
  text-align: center;
  display: block;
}

.buildings {
  font-family: "futura-pt", "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.625rem;
  line-height: 1.346;
  display: inline-block;
  max-width: 40rem;
  margin-top: .25rem;
  @media (max-width: $bp-min-small-tablet) {
    display: block;
    text-align: center;
  }
}

.notice-promo__link {
  @include font(futurab, demi);
  border-bottom: rem(4) solid transparent;
  font-weight: 600;
  font-size: rem(24);
  line-height: 1.5;
  color: inherit;
  text-align: center;
  display: block;
  text-transform: uppercase;

  // Only add these hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card &:hover {
    color: inherit;
    border-bottom: rem(4) solid transparent;
  }

  @media print {
    &:after {
      display: block;
    }
  }
}

.notice-promo__location {
  @include font(stevie, light);

  font-size: rem(14);
  line-height: 1.571;
  margin-top: 0;
}

.notice-promo {
  background-color: $drexel-grey-pale;
  position: relative;
  z-index: $stack-internal;

  @media (max-width: $bp-min-xsmall) {
    padding: 2rem 1rem;
  }

  &:before {
    background: linear-gradient(135deg,
        $drexel-blue 0%,
        $drexel-blue-light 100%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: $stack-back;
    transition: opacity $standard-transition;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  &.clickable-card--whole-card:hover {
    :not(.layout-columns)>& {
      border: none;
    }

    color: $white;

    &:before {
      opacity: 1;
    }
  }

  &.notice-promo--inverse {
    background-color: $white;

    // Only add hover styles if it's a clickable card and JS initialized
    &.clickable-card--whole-card:hover {
      background: linear-gradient(135deg,
          $drexel-blue 0%,
          $drexel-blue-light 100%) padding-box;
      color: $white;
    }
  }
}

.notice-promo__date-card {
  color: $drexel-grey-text;
  display: block;
  width: 200px;

  @media (max-width: $bp-min-xsmall) {
    width: 150px;
  }

  @media (min-width: $bp-min-xsmall) {
    width: 150px;
  }

  &:before {
    background-color: $drexel-blue-light;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover & {
    color: $white;
  }
}

.right-side {}

.notice-promo__date-card.right-side:after {
  background-color: $drexel-blue-light;
  content: "";
  display: block;
  height: calc(100% + 2rem);
  position: absolute;
  top: -2rem;
  right: 0rem;
  width: 2px;
}

.notice-promo__date-card.right-side::before {
  background-color: #ffc600;
  content: none;
  display: block;
  height: calc(100% + 2rem);
  position: absolute;
  top: -2rem;
  right: -20px;
  width: 2px;
}

.notice-promo__date-card:after {
  background-color: #ffc600;
}

.notice-promos__full {
  @include font(stevie, regular);
  color: $white;
  font-size: rem(13);
  height: rem(90);
  letter-spacing: 0.7px;
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  text-transform: uppercase;
  width: rem(90);

  &:before {
    border: rem(45) solid transparent;
    border-top: rem(45) solid $drexel-grey-light;
    border-right: rem(45) solid $drexel-grey-light;
    box-sizing: border-box;
    content: "";
    display: block;
    height: rem(90);
    position: absolute;
    top: 0;
    left: 0;
    width: rem(90);
  }
}

.notice-promos__full-inner {
  position: absolute;
  right: rem(11);
  top: rem(11);
}

.notice-promo__top-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 1rem;
  grid-auto-flow: row;
  justify-content: space-between;
}

.notice-promo__info {

  text-align: center;

  h3 {
    font-weight: bold;
    text-transform: uppercase;
  }

  .notice-detail {

    font-family: "stevie-sans", Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1rem;
    max-width: 1050px;
    text-align: center;
    margin: auto auto .5rem auto;
    overflow: hidden;
    line-height: 2rem;
    max-height: 8rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }

  p:last-child {
    margin: .5rem auto auto auto;
  }
}

.notice-title {
  font-family: "futura-pt", "Lucida Grande", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  line-height: 2;
  font-weight: bold;
  text-transform: uppercase;
}


$text-arrow-space: 16px;
$shaft-width: 64px;
$shaft-thickness: 1px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;

// The Arrow

.the-arrow {

  width: 600px;
  transition: all 0.2s;

  @media (max-width: $bp-min-xsmall) {
    width: 50px;
  }

  @media (min-width: $bp-min-xsmall) {
    width: 100px;
  }

  @media (min-width: $bp-min-small) {
    width: 200px;
  }

  @media (min-width: $bp-min-small-tablet) {
    width: 300px;
  }

  @media (min-width: $bp-min-medium) {
    width: 500px;
  }

  @media (min-width: $bp-min-large) {
    width: 600px;
  }

  @media (min-width: $bp-min-xlarge) {
    width: 700px;
  }

  &.-left {
    position: absolute;
    top: 60%;
    left: 0;

    >.shaft {
      width: 0;
      background-color: $drexel-grey-pale;

      &:before,
      &:after {
        width: 0;
        background-color: $drexel-grey-pale;
      }

      &:before {
        transform: rotate(0);
      }

      &:after {
        transform: rotate(0);
      }
    }
  }

  &.-right {
    top: 3px;

    >.shaft {
      width: 100%;
      transition-delay: 0.2s;

      &:before,
      &:after {
        width: 14px;
        transition-delay: 0.3s;
        transition: all 0.5s;
      }

      &:before {
        transform: rotate(45deg);
        top: 1px;
        right: -1px;
      }

      &:after {
        transform: rotate(-45deg);
        top: -1px;
        right: -1px;
      }
    }
  }

  >.shaft {
    background-color: $drexel-blue-light;
    display: inline-block;
    height: 2px;
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0;
    transition-delay: 0;
    will-change: transform;
    bottom: 0;

    &:before,
    &:after {
      background-color: $drexel-blue-light;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s;
      transition-delay: 0;
    }

    &:before {
      transform-origin: top right;
    }

    &:after {
      transform-origin: bottom right;
    }
  }
}

.layout-columns--30-70 .the-arrow {

  width: 600px;
  transition: all 0.2s;

  @media (max-width: $bp-min-xsmall) {
    width: 50px;
  }

  @media (min-width: $bp-min-xsmall) {
    width: 50px;
  }

  @media (min-width: $bp-min-small) {
    width: 100px;
  }

  @media (min-width: $bp-min-small-tablet) {
    width: 150px;
  }

  @media (min-width: $bp-min-medium) {
    width: 200px;
  }

  @media (min-width: $bp-min-large) {
    width: 300px;
  }

  @media (min-width: $bp-min-xlarge) {
    width: 400px;
  }

  &.-left {
    position: absolute;
    top: 60%;
    left: 0;

    >.shaft {
      width: 0;
      background-color: $drexel-grey-pale;

      &:before,
      &:after {
        width: 0;
        background-color: $drexel-grey-pale;
      }

      &:before {
        transform: rotate(0);
      }

      &:after {
        transform: rotate(0);
      }
    }
  }

  &.-right {
    top: 3px;

    >.shaft {
      width: 100%;
      transition-delay: 0.2s;

      &:before,
      &:after {
        width: 14px;
        transition-delay: 0.3s;
        transition: all 0.5s;
      }

      &:before {
        transform: rotate(45deg);
        top: 1px;
        right: -1px;
      }

      &:after {
        transform: rotate(-45deg);
        top: -1px;
        right: -1px;
      }
    }
  }

  >.shaft {
    background-color: $drexel-blue-light;
    display: inline-block;
    height: 2px;
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0;
    transition-delay: 0;
    will-change: transform;
    bottom: 0;

    &:before,
    &:after {
      background-color: $drexel-blue-light;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s;
      transition-delay: 0;
    }

    &:before {
      transform-origin: top right;
    }

    &:after {
      transform-origin: bottom right;
    }
  }
}

.notice-group {
  @include componentize();

  display: block;
  position: relative;
  //margin: 0 calc(2.625rem / 2) calc(2.625rem / 2) calc(2.625rem / 2);

  .notice-group__button {
    display: block;

    a {
      display: block;
      margin: auto;
      position: relative;
      width: fit-content;
    }
  }
}