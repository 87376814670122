.rich-text {
  @include component-normalize();
  @include componentize();
  height: min-content;

  // No bottom margin on empty rich texts
  &:empty {
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }

  .page-header__content-wrapper & {
    max-width: rem(810);
  }

  .subfooter & {
    opacity: 1;
    text-align: center;

    @media (min-width: $bp-footer-min-medium) {
      text-align: left;
    }
  }

  .layout-columns &,
  .promo-grid &,
  .subfooter &,
  .program-item & {
    margin-right: 0;
    margin-left: 0;
    max-width: 100%;
  }

  .simple-accordion &:not(:last-child) {
    margin-bottom: $base-half-component-whitespace;
  }

  .btn {
    margin-right: rem(11);
    margin-bottom: rem(11);
  }

  .subfooter & p {
    font-size: rem(17);
    line-height: 1.588;
    margin-bottom: rem(18);

    &:last-child {
      margin-bottom: 0;
    }
  }

  blockquote {
    border-left: 4px solid currentColor;
    display: block;
    font-size: rem(17);
    font-style: italic;
    margin: 0 0 rem(56);
    padding-left: rem(32);

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Clear floats, when we get to the breakpoint where floats occur
  @media (min-width: $bp-min-small) {
    @include clearfix;
  }

  @media (min-width: $bp-min-small-tablet) {
    .container > & {
      margin-right: $standard-side-margin;

      max-width: calc(
        (
            (100% - #{$double-side-margin} - (11 * #{$layout-columns-spacer})) *
              (8 / 12)
          ) + (7 * #{$layout-columns-spacer})
      );
    }

    .container--stripe > & {
      max-width: calc(
        100% - #{$double-side-margin} - #{$standard-sidebar-width} - #{$layout-columns-spacer}
      );
    }

    .promo-grid &,
    .subfooter &,
    .program-item & {
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;
    }
  }

  @media (min-width: $bp-striped-sidebar-scales) {
    .container--stripe > & {
      max-width: calc(
        (
            (100% - #{$double-side-margin} - (11 * #{$layout-columns-spacer})) *
              (8 / 12)
          ) + (7 * #{$layout-columns-spacer})
      );
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    // .container > & {
    //   margin-left: auto;
    //   margin-right: auto;
    //   max-width: $max-width-rems;
    //   padding-left: rem(477);
    //   padding-right: rem(85);
    // }

    .container:not(.container--stripe) .page-segment__body & {
      padding-right: rem(85);
    }

    .container--stripe > & {
      padding-right: 0;
    }

    .promo-grid &,
    .subfooter &,
    .program-item & {
      margin-right: 0;
      margin-left: 0;
      max-width: 100%;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    .container > &,
    .layout-columns &,
    .page-header__content-wrapper &,
    .program-item &,
    .promo-grid &,
    .subfooter & {
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      max-width: none !important;
    }

    .program-item &,
    .subfooter & {
      margin-bottom: 0 !important;
    }

    .page-header__content-wrapper &,
    &:has(+ .stat-grid) {
      margin-bottom: $print-standard-text-whitespace !important;
    }

    .subfooter & {
      text-align: center;
    }

    h2 strong,
    h3 strong,
    h4 strong,
    h5 strong,
    h6 strong {
      font-weight: inherit;
    }

    p > span,
    li > span,
    a > span {
      font-family: inherit !important;
    }

    .page-segment & {
      p,
      ul,
      ol {
        max-width: 80ch;
      }
    }

    //   margin-bottom: $print-standard-text-whitespace !important;
    //   & + & {
    //     margin-bottom: $print-standard-text-whitespace !important;
    //   }  
  }
  /* stylelint-enable declaration-no-important */
}

// "Secondary" rich text variant, with smaller type and slightly different
// bold headers.  Used on the Area of Study page and in the Person Card Bio
.rich-text--secondary {
  font-size: rem(18);
  line-height: 1.722;
  padding-left: 0;

  .page-header__content-wrapper &,
  .person-contact-card__bio & {
    max-width: none;
  }

  p,
  ul {
    margin-bottom: rem(11);

    a {
      border-bottom-width: 2px;
    }
  }

  ul {
    margin-left: $base-half-component-whitespace;
  }

  li {
    margin-bottom: 0;
  }

  h4 {
    @include font(stevie, semibold);
    font-size: rem(18);
    margin-top: rem(34);

    &:first-child {
      margin-top: 0;
    }
  }

  h5 {
    @include font(stevie, semibold);
    color: $drexel-grey-medium;
    font-size: rem(18);
    margin-bottom: rem(5);
  }

  .text-columns {
    font-size: rem(18);
  }
}

.accent_background, .dark_background, .light_background {
  padding: 2rem;
}

.accent_background {
  background-color: $drexel-gold;
  color: $black;
  border-bottom: .5rem solid $drexel-navy;
  a {
    color: $drexel-navy;
    border-bottom-color: $drexel-navy;
    &:hover {
      color: $drexel-blue;
      border-bottom-color: $drexel-blue;
    }
  }

  .btn--primary{
    border: 2px solid $black;
    background-color: transparent;
    color: $black;
    &:before {
      background: linear-gradient(90deg, $drexel-blue, $drexel-navy);
    }
    &:hover{
      color: $white
    }
    &:active, &:focus {
      color: $white;

      .icon-link__icon {
        color: $white;
      }
    }
  }


  .btn--secondary{
    background-color: $drexel-navy;
    border: none;
    color: $white;
    &:before {
      background: $white;
    }
    &:hover{
      color: $black;
    }
  }
}

.dark_background {
  background-color: $drexel-navy;
  color: $white;
  border-bottom: .5rem solid $drexel-gold;
  a {
    color: $drexel-blue-light;
    border-bottom-color: $drexel-blue-light;
    &:hover {
      color: $drexel-gold;
      border-bottom-color: $drexel-gold;
    }
  }

  h2,h3,h4,h5,h6,td,th {
    color: $white;
  }

  .btn--primary{
    border: 2px solid $drexel-gold;
    background-color: transparent;
    color: $white;

    &:hover, &:active, &:focus {
      color: $black
    }
  }

  .btn--secondary{
    background-color: $drexel-gold;
    border: none;
    color: $black;
    &:before {
      background: $drexel-blue;
    }
    &:hover, &:focus, &:active {
      color: $white;
      .icon-link__icon {
        color: $white;
      }
    }
  }

}
.light_background {
  background-color: $drexel-grey-pale;
  color: $black;
  border-bottom: .5rem solid $drexel-blue-light;
  a {
    color: $drexel-navy;
    border-bottom-color: $drexel-navy;
    &:hover {
      color: $drexel-blue;
      border-bottom-color: $drexel-blue;
    }
  }

  .btn--primary{
    border: 2px solid $drexel-blue-light;
    background-color: transparent;
    color: $black;
    &:before {
      background: linear-gradient(90deg, $drexel-blue, $drexel-navy);
    }
    &:hover, &:focus, &:active {
      color: $white;
      
      .icon-link__icon {
        color: $white;
      }

    }
  }

  .btn--secondary{
    background-color: $drexel-navy;
    border: none;
    color: $white;
    &:before {
      background: $drexel-gold;
    }
    &:hover{
      color: $black;
    }
  }
}
.rich-text:last-child {
  margin-bottom: 3.75rem;
}

.container .page-segment .rich-text.accent_background .image-block--float-right,
.container .page-segment .rich-text.dark_background .image-block--float-right,
.container .page-segment .rich-text.light_background .image-block--float-right {
  margin-right: auto;
}

//Normalize the full-width text component
.full-width-text {
  @include componentize();
  @include component-normalize();
}