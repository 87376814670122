.page-header {
  align-items: start;
  display: grid;
  grid-template-columns: calc(100% - #{$share-component-spacer}) $share-component-spacer;
  grid-template-rows: auto auto auto auto auto auto auto;
  margin-bottom: $base-component-mobile-whitespace;

  &:last-child {
    margin-bottom: 0;
  }

  .breadcrumbs + &:not(.page-header--no-hero) {
    @media (min-width: $bp-min-small-tablet) {
      margin-top: rem(-32);
    }
    @media print {
      margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  .rich-text {
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    /*
     * OK, so what on earth is going on here?
     * Because of the way %s are calculated in CSS, and because the notch and hero need to span the full
     *   width of the page, we have to do some weird calculations to get the content of the page header
     *   to line up appropriately with the  1/3rd/2/3rds promos that may follow it down the page.
     * Most importantly, we need 33% and 67% of the content well, not of the container, here, hence the
     *   math
     * The calculations below give us:
     * margin | 33% of the content well | layout-columns spacer | 67% of the content well | margin
     */
    grid-template-columns:
      $standard-side-margin
      calc(
        ((100% - #{$double-side-margin}) * 33 / 100) -
          (#{$layout-columns-spacer} / 2)
      )
      $layout-columns-spacer
      calc(
        ((100% - #{$double-side-margin}) * 67 / 100) -
          (#{$layout-columns-spacer} / 2)
      )
      $standard-side-margin;
    grid-template-rows: 
      auto
      $base-double-component-whitespace
      rem(57)
      minmax(0, min-content) 
      minmax(0, min-content)  
      1fr;
      // minmax(0, min-content) 1fr;
    margin-bottom: $base-component-whitespace;

    //auto 2.5rem 5.5625rem 5.5625rem auto 1fr minmax(0, min-content);

    &.page-header--no-hero {
      grid-template-rows: minmax(0, min-content) minmax(0, min-content) 1fr;
    }

    &.page-header--no-notch {
      grid-template-columns: rem(126) 0 0 calc(100% - #{rem(252)}) rem(126);
    }
  }

  @media (min-width: $bp-min-xxlarge) {
    display: grid;
    /*
     * Same basic idea as above, except we can use explicit widths for the columns since we know
     *   the width of the content well.
     */
    grid-template-columns: calc((100% - #{$max-width-rems}) / 2) $standard-sidebar-width $standard-column-gap-width $standard-body-width calc(
        (100% - #{$max-width-rems}) / 2
      );

    &.page-header--no-notch {
      grid-template-columns: calc((100% - #{$max-width}) / 2) 0 0 rem(930) calc(
          (100% - #{rem(930)}) / 2
        );
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block !important;
  }
  /* stylelint-enable declaration-no-important */
}

.page-header__content-wrapper {
  margin: rem(21) $standard-side-margin 0;

  .page-header__hero & {
    margin: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    margin: rem(11) 0 0 0;
  }

  @media print {
    margin-left: 0;
    margin-right: 0;
  }
}

.page-header__hero {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 1;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 1;
    grid-column-end: span 6;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}

.page-header__crumb {
  background-color: $white;
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  padding: 3.5625rem 0 0;
  height: 100%;
  justify-content: start;

  .page-header__content-wrapper {
    margin-left: $standard-side-margin;

    @media (min-width: $bp-min-xxlarge) {
      margin-left: calc(100% - #{$standard-sidebar-width});
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 2;
    grid-row-end: span 4;
    position: relative;
    z-index: $stack-overlaps;

    .page-header--no-hero & {
      grid-row-start: 1;
      justify-content: start;
      padding-top: 0;
      grid-row-end: span 2;
    }
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.page-header__share {
  grid-column-start: 2;
  grid-column-end: span 1;
  grid-row-start: 2;
  grid-row-end: span 1;
  padding: rem(42) 0 $standard-side-margin;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 5;
    grid-column-end: span 1;
    grid-row-start: 4;
    grid-row-end: span 1;
    padding-top: 0;

    .page-header--no-hero & {
      grid-row-start: 1;
    }
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.page-header__title {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 3;
  grid-row-end: span 1;

  .page-header__share + & {
    // make room for share button
    margin-right: $share-component-spacer;
  }

  .page-header--no-notch .page-header__share + & {
    // layout takes care of it, no need to make room
    margin-right: 0;
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 4;
    grid-column-end: span 1;
    grid-row-start: 4;
    grid-row-end: span 1;

    .page-header--no-hero & {
      grid-row-start: 1;
    }
  }
}

.page-header__subnav {
  background-color: $white;
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 4;
  grid-row-end: span 1;
  //padding-top: rem(15);

  .page-header__content-wrapper {
    margin-left: $standard-side-margin;
    margin-right: $standard-side-margin;

    @media (min-width: $bp-min-small-tablet) {
      margin-bottom: $base-component-whitespace;
    }

    @media (min-width: $bp-min-xxlarge) {
      margin-left: calc(100% - #{$standard-sidebar-width});
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 1;
    grid-column-end: span 2;
    grid-row-start: 3;
    grid-row-end: span 3;
    position: relative;
    z-index: $stack-overlaps;

    .page-header--no-hero &,
    .page-header--no-hero.page-header--sidebar & {
      grid-row-start: 2;
      grid-row-end: span 1;
    }

    .page-header--no-title &,
    .page-header--no-title.page-header--sidebar & {
      grid-row-end: 3;
    }

    .page-header--sidebar & {
      grid-row-end: span 3;
    }
  }

  @media print {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.page-header__body {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 5;
  grid-row-end: span 1;
  margin-bottom: $base-component-mobile-whitespace;

  /* Convoluted, but if the share is immediately before the subnav (no title), account for it */
  .page-header__share + .page-header__subnav + & {
    @media (min-width: $bp-min-small-tablet) {
      margin-right: $share-component-spacer;
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 4;
    grid-column-end: span 1;
    grid-row-start: 5;
    grid-row-end: span 2;
    margin-bottom: 0;
    align-self: start;

    .page-header__share + .page-header__subnav + & {
      margin-right: 0;
    }

    .page-header--no-hero &,
    .page-header--no-hero.page-header--sidebar & {
      grid-row-start: 2;
      grid-row-end: span 2;
    }

    .page-header--sidebar & {
      grid-row-start: 5;
      grid-row-end: span 2;
    }

    .page-header--no-title & {
      grid-row-start: 4;
      grid-row-end: span 3;
    }
  }

  // When no title (ex. Admissions), make space for the share
  .page-header--no-title & {
    @include spaceForShare($bp-min-large, rem(1557));
  }
}

.page-header__element--show-tablet {
  display: none;

  @media (min-width: $bp-min-small-tablet) {
    display: block;
  }
}

.page-header__element--hide-tablet {
  @media (min-width: $bp-min-small-tablet) {
    display: none;
  }
}

.page-header__sidebar {
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 6;
  grid-row-end: span 1;

  @media (min-width: $bp-min-small-tablet) {
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 6;
    grid-row-end: span 1;

    .page-header--no-hero & {
      grid-row-start: 3;
      grid-row-end: span 1;
    }
  }

  @media print {
    display: none;
  }
}
