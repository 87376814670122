@use "sass:math";

.program-item {
  /* We use padding here instead of margin to simplify height calculation */
  padding-bottom: rem(21);

  @media (min-width: $bp-min-small-tablet) {
    position: absolute;
    transition: top 0.5s, left 0.5s;
    width: calc(50% - (#{$promo-spacer} / 2));
  }

  &.is-open {
    z-index: $stack-internal;
  }

  @media print {
    br {
      display: none;
    }
  }
}

.program-item__inner {
  background-color: $greyband-color;
  padding: rem(29) rem(21) rem(29) rem(53);

  @media (min-width: $bp-min-xsmall) {
    padding: rem(29) rem(53);
  }

  @media print {
    padding: 0;
  }
}

.program-item__body {
  font-size: rem(18);
  height: 0;
  line-height: 1.722;
  padding: 0;
  overflow: hidden;
  transition: height 0.2s;

  /* just using a standard RTE here, so override default styles */
  p {
    font-size: rem(18);
    line-height: 1.722;
  }

  .rich-text + p {
    margin-top: math.div($base-component-mobile-whitespace, -2);

    @media (min-width: $bp-min-small-tablet) {
      margin-top: math.div($base-component-whitespace, -2);
    }

    @media print {
      margin-top: $print-standard-text-whitespace;
    }
  }

  a:not(.btn) {
    border-bottom-width: rem(2);
    border-bottom-color: rgba(108, 172, 228, 0.5);

    &:hover,
    &:focus {
      border-bottom-color: rgba(108, 172, 228, 1);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    height: auto !important;
    overflow: visible;
    transition: none !important;
  }
  /* stylelint-enable declaration-no-important */
}

.program-item__body-inner {
  padding-top: rem(29);
  padding-bottom: rem(29);
}

.program-item__title {
  margin: 0;
  cursor: pointer;

  button {
    @extend .txt-h3;

    background-color: transparent;
    border: none;
    display: inline-block;
    margin: 0 0 0 rem(-53);
    padding: 0 0 0 rem(53);
    position: relative;
    text-align: left;

    span {
      border-bottom: rem(4) solid transparent;
    }

    &:hover,
    &:focus {
      span {
        border-bottom-color: rgba($drexel-grey-headline, 0.5);
      }
    }

    &:after,
    &:before {
      background-color: currentColor;
      content: "";
      display: block;
      height: rem(3);
      width: rem(15);
      position: absolute;
      left: rem(21);
      top: rem(18);
    }

    &:before {
      transform: rotate(270deg);
      transition: transform 0.3s ease-out;

      .program-item.is-open & {
        transform: rotate(0deg);
      }
    }

    /* stylelint-disable declaration-no-important */
    @media print {
      margin: 0 !important;
      display: block;

      &:after,
      &:before {
        display: none;
      }
    }
    /* stylelint-enable declaration-no-important */
  }
}

.program-item__info {
  display: flex;
  font-size: rem(15);
  line-height: 1.733;
  margin-top: rem(11);

  @media print {
    display: block;
  }
}

.program-item__college {
  padding-right: rem(21);
  flex-basis: 65%;
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: $bp-min-xsmall) {
    flex-basis: 71%;
  }

  @media print {
    display: inline;
    padding-right: 0;

    &:after {
      content: " — ";
    }
  }
}

.program-item__program {
  @media print {
    display: inline;
  }
}

.program-item__info-blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -($promo-spacer);
  position: relative;

  /* stylelint-disable declaration-no-important */
  @media print {
    display: block;
  }
  /* stylelint-enable declaration-no-important */
}

.program-item__info-block {
  margin-bottom: $promo-spacer;
  width: 100%;

  &.program-item__info-block--50 {
    width: calc(50% - (#{$promo-spacer} / 2));

    @media print {
      width: 100%;
    }
  }

  h4 {
    margin-bottom: rem(11);
  }

  ul {
    margin-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  li {
    margin-bottom: rem(8);
    line-height: 1.4;
  }

  .program-item__info-blocks & h4 {
    // Increased specificity to override `.wf-active h4`
    font-weight: 700;
    font-size: 20px;
  }

  @media print {
    a {
      display: block;
    }
  }
}

.program-item__info-block--locations {
  h4 {
    margin-bottom: rem(8);
  }

  // inline list for Locations
  ul li {
    display: inline;

    & + li:before {
      content: ", ";
    }
  }

  h4 {
    margin-bottom: rem(11);
  }

  ul {
    margin-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  li {
    margin-bottom: rem(8);
    line-height: 1.4;
  }

  .program-item__info-blocks & h4 {
    // Increased specificity to override `.wf-active h4`
    font-weight: 700;
    font-size: 20px;
  }

  @media print {
    a {
      display: block;
    }
  }
}

.program-item__info-block--locations {
  h4 {
    margin-bottom: rem(8);
  }

  // inline list for Locations
  ul li {
    display: inline;

    & + li:before {
      content: ", ";
    }
  }
}
