.form-grid {
  @include componentize();

  .layout-columns__col & {
    max-width: 100%;
  }
}

.form-grid__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: rem(48);
}

.form-grid__item {
  margin-bottom: rem(54);
  width: 100%;

  @media (min-width: $bp-min-small-tablet) {
    &.form-grid__item--2,
    &.form-grid__item--3,
    &.form-grid__item--4 {
      width: calc(50% - (#{$promo-spacer} / 2));
    }
  }

  @media (min-width: $bp-min-large) {
    &.form-grid__item--3 {
      width: calc((100% / 3) - (#{$promo-spacer} / 3 * 2));
    }

    &.form-grid__item--4 {
      width: calc(25% - (#{$promo-spacer} / 4 * 3));
    }
  }
}
