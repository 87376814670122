.accordion-group {
  margin-bottom: rem(42);

  @media print {
    margin-bottom: $print-standard-component-whitespace;
  }
}

.accordion-group--secondary {
  margin-bottom: rem(32);

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: rem(42);
  }

  @media print {
    margin-bottom: $print-standard-component-whitespace;
  }
}

@media (min-width: $bp-min-small-tablet) {
  .accordion-group__accordions {
    padding-left: rem(42);

    // Secondary variant (seen in Area of Study)
    .accordion-group--secondary & {
      padding-left: 0;
    }
  }
}

.accordion-group__title {
  margin-bottom: rem(21);
  padding-bottom: rem(12);
  padding-right: 1ch;
  position: relative;

  &::after {
    background-color: currentColor;
    content: "";
    display: block;
    height: rem(4);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @media (min-width: $bp-min-xsmall) {
    display: inline-block;

    &::after {
      min-width: rem(390);
    }
  }

  // Secondary variant (seen in Area of Study)
  .accordion-group--secondary & {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    padding: 0 !important;
    margin: 0 !important;

    &::after {
      display: none;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.accordion-group__subtitle {
  @extend .txt-h3--secondary;
}

.accordion-group__description {
  margin-bottom: rem(15);
}
