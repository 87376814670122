.anchor-navigation {
    margin: auto auto 3rem auto;

}


.nav-holder {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    background-color: $white;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

#anchor-nav {
    @include componentize-fixed();

    padding: rem(15) rem(15);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    z-index: 0;
    background-color: white;
    margin: auto;

    @media (max-width: $bp-max-small-tablet) {

        overflow-x: clip;
        overflow-y: hidden;
        max-width: none;
        width: max-content;
        margin: 0;
        padding: 0.5rem 0.5rem;
        height: 4rem;
    }

}

#anchor-nav {
    opacity: 0;
    transition: all $standard-transition;
}

.anchor-line {
    width: 1px;
    height: 35px;
    background-color: rgba(1, 1, 1, 0.5);
}

// .anchor-header {
//     scroll-margin-top: 500px;
//   }

.anchor-nav-item {
    border-right-style: solid;
    border-right-color: rgba(1, 1, 1, 0.25);
    border-right-width: 0px;
    margin: 0;
    padding: 0 1rem;
    text-transform: uppercase;
    @include font(futurab, demi);
    font-size: 1.2rem;
    letter-spacing: rem(0.25);
    word-spacing: rem(-1.25);
    line-height: 1.15;
    flex-grow: 1;
    width: calc(100vw / 3);
    cursor: pointer;

    height: 3rem;
    vertical-align: middle;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;


    a {
        border-bottom: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media (max-width: $bp-max-small-tablet) {
        width: calc(100vw / 2.5);
        display: inline-block;
        position: relative;
        font-size: 1.1rem;
        height: 3.5rem;
    }

}

.anchor-nav-item:last-child {
    border-right: none;
}

.anchor-links-fixed-top {

    @include fixed-top();
    background-color: white;
    top: 0px;
    border-bottom: 3px solid $drexel-grey-soft;

    @media (max-width: $bp-max-small-tablet) {
        overflow-x: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */

    }

}

.anchor-links-fixed-top::-webkit-scrollbar,
.anchor-navigation::-webkit-scrollbar,
.nav-holder::-webkit-scrollbar,
#anchor-nav::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
    /* make scrollbar transparent */
}

.anchor-links-arrow-controls {
    display: inline-block;
    position: absolute;
    z-index: 100;
    height: 3.5rem;
    width: 3rem;
    cursor: pointer;
    display: none;

    button {
        background: none;
        margin: 0em;
        padding-block: 1px;
        padding-inline: 6px;
        border: none;
    }

    @media (min-width: $bp-min-small-tablet) {
        height: 3rem;

        width: 4rem;
    }


    svg {
        margin-left: $standard-side-margin;
        height: 4rem;
        stroke: $drexel-navy;
        fill: $drexel-navy;
        padding-bottom: 1px;

        &:hover {
            stroke: $drexel-blue;
            fill: $drexel-blue;

            path {
                fill: $drexel-blue;
            }
        }

        @media (min-width: $bp-min-small-tablet) {
            height: 4rem;

        }
    }
}

.navigate-anchorlinks-left {
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 1) 100%);
}

.navigate-anchorlinks-right {
    right: 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background: rgb(255, 255, 255);
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.0) 0%, rgba(255, 255, 255, 1) 100%);
}

.anchor-link-active {
    display: inline;
    position: relative;
    color: $drexel-navy;
    transition: all 0.1s;

    &::after {
        content: "";
        width: 75px;
        margin-top: 2px;
        border-bottom: 4px solid #006298 !important;
    }
}

.js-anchor-links {
    transition: all 0.3s ease-in-out;
}