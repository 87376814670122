.date-quick-picks {
  input {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }

  .search-listing__facets & {
    margin-bottom: rem(21);
  }
}

.date-quick-pick {
  .search-listing__facets & {
    display: block;
  }
}

/* Need to overcome the cascade here; this is ugly */
.date-quick-picks .date-quick-pick input[type="checkbox"] + label,
.date-quick-picks .date-quick-pick input[type="checkbox"]:checked + label {
  @include font(stevie, regular);

  cursor: pointer;
  display: inline-block;
  font-size: rem(17);
  line-height: 1.471;
  margin: 0;
  padding: rem(6) rem(11);

  &:before,
  &:after {
    display: none !important; /* requirements for custom checkboxes on default checks makes this ugly */
  }

  .search-listing__facets & {
    background-color: transparent;
    color: $drexel-grey-text;
    display: inline-block;
    font-weight: 300;
    line-height: 1.529;
    padding: 0 0 0 rem(29);
    position: relative;
    margin: 0 0 rem(11);
    transition: color 0.3s;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $drexel-grey-text;
      text-decoration: underline;
    }
  }

  .date-quick-pick__check {
    border: 2px solid currentColor;
    border-radius: 50%;
    display: block;
    height: rem(21);
    position: absolute;
    top: 2px;
    left: 1px;
    width: rem(21);
  }

  svg {
    display: block;
    height: rem(17);
    position: relative;
    top: 1px;
    left: 1px;
    width: rem(17);
  }
}

.date-quick-pick input:focus + label {
  text-decoration: underline;
}

.date-quick-pick input:checked + label {
  background-color: $drexel-grey-text;
  color: $base-background-color;

  .search-listing__facets & {
    background-color: transparent;
    color: $drexel-grey-text;
  }
}
