.stat-icon-card {
  font-family: $futura;

  background-color: $base-background-color; /* Placeholder to showcase inversion and enforce accessible color; add actual colors in theme scss */
  color: $black; /* Placeholder to showcase inversion and enforce accessible color; add actual colors in theme scss */
  padding: 0;

  // Breaks the IE11 flexbox fallback, only apply when using grid
  @supports (display: grid) {
    width: 100%;

    @media print {
      width: auto;
    }
  }

  .stat-grid & {
    height: 100%;
  }
}

.stat-icon-card__header {
  padding: rem(40);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &::after {
    content: "";
    width: calc(100% + 10px);
    height: 4px;
    background-color: $drexel-gold;
    position: absolute;
    top: 100%;
    left: 0;
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    display: inline !important;
    padding: 0 !important;

    &::after {
      display: none;
    }
  }
  /* stylelint-enable declaration-no-important */
}

.stat-icon-card__value-wrapper {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  line-height: 1.2875;

  @media print {
    display: inline;
    font-weight: 700;
    line-height: inherit;
  }
}

.stat-icon-card__value {
  font-size: rem(80);
  line-height: 1.2875;

  @media print {
    display: inline;
    font-size: inherit;
    line-height: inherit;
  }
}

.stat-icon-card__prefix,
.stat-icon-card__suffix {
  font-size: rem(42);

  @media (min-width: $bp-min-small-tablet) {
    font-size: rem(69);
  }

  @media print {
    font-size: inherit;
  }
}

.stat-icon-card__eyebrow {
  letter-spacing: 0.0625em;
  font-size: rem(16);
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
}

.stat-icon-card__body {
  padding: rem(40);

  @media print {
    display: inline;
    padding: 0;
  }
}

.stat-icon-card__description {
  font-weight: 400;
  font-size: rem(30);
  line-height: 1.1842;

  @media screen and (min-width: $bp-min-xlarge) {
    font-size: rem(38);
  }

  @media print {
    &,
    & p {
      display: inline;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.stat-icon-card__link {
  display: block;
  font-family: $stevie;
  border: none;
  font-weight: 400;
  padding-top: rem(28);
  color: $drexel-blue;
  font-size: rem(17);
  line-height: 1.47;

  &:focus,
  &:hover {
    text-decoration: underline;
    color: inherit;
  }

  &::after {
    content: " \2192";
  }

  .stat-item--clickable:hover & {
    text-decoration: underline;
  }
}

.stat-icon-card__icon {
  margin-left: rem(40);
  flex-basis: rem(100);
  height: auto;
  min-width: rem(20);

  svg {
    width: 100%;
    height: auto;
    max-height: rem(100);
  }

  @media print {
    display: none;
  }
}
