.media-promo {
  @include component-normalize();
  @include print-style-general();

  color: currentColor;
  text-decoration: none;
  transition: margin, 0.2s;

  a {
    border-bottom: rem(4) solid transparent;
    color: currentColor;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover,
  a:focus,
  &.clickable-card--whole-card:hover .media-promo__title a {
    border-bottom-color: rgba($drexel-grey-text, 0.5);

    @media print {
      border-bottom-color: transparent;
    }
  }
  
  img {
    display: block;
    height: auto;
    width: 100%;
  }

  .promo-grid &,
  .experience-grid & {
    margin-bottom: 0;
  }

  /* We do things a bit differently if this element is a component in its own right, rather than being a child of the promo grid */
  .container > & {
    @include componentize-wide();

    @include component-normalize();

    /* stylelint-disable declaration-no-important */
    @media print {
      margin-bottom: $print-standard-text-whitespace !important;
    }
    /* stylelint-enable declaration-no-important */
  }

  .container > & img {
    @supports (object-fit: cover) {
      height: rem(260);
      object-fit: cover;
      width: 100%;

      @media (min-width: $bp-min-small) {
        height: rem(399);
      }

      @media (min-width: $bp-min-small-tablet) {
        height: rem(530);
      }
    }
  }

  &.media-promo--bump-up {
    background-color: $white;

    .container--greyband & {
      background-color: $greyband-color;
    }

    @media (min-width: $bp-min-small-tablet) {
      @include overlap(up, -#{$base-double-component-whitespace});

      margin-bottom: -#{$base-double-component-whitespace};
      transition: margin-bottom 1s, top 1s;

      /* I think this is the only case where this happens; may need to revisit */
      .experience-grid & {
        @include overlap(up, rem(-70));

        margin-bottom: rem(-70);
      }

      .experience-grid.in-play-mode & {
        margin-bottom: 0;
        top: 0;
      }

      .experience-grid &::after {
        background-color: $white;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        width: rem(10);
      }
    }
  }

  @media print {
    @include print-list();
    top: auto !important;
    position: relative !important;
    display: block !important;
  }
}

.media-promo__image-wrapper {
  display: block;
  margin-bottom: rem(17);
  position: relative;
  border-bottom: rem(4) solid transparent;
  transition: border $standard-transition;

  img {
    transition: transform 0.5s;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover & {
    border-bottom-color: $drexel-gold;

    img {
      transform: scale(1.1, 1.1);
    }
  }

  @media print {
    display: none;
  }
}

.media-promo__image {
  overflow: hidden;
}

.media-promo__body {

  @media print{
    @include print-style-general();
  }


  .layout-columns--skinny-gutter &,
  .layout-columns--4-up & {
    @media (min-width: $bp-min-small-tablet) {
      max-width: 80%;
    }
    
  }

  /* We do things a bit differently if this element is a component in its own right, rather than being a child of the promo grid */
  .container > .media-promo & {
    background-color: $white;
    margin: 0 $standard-side-margin;

    @media (min-width: $bp-min-small-tablet) {
      @include overlap(up, rem(-77));

      margin: 0 0 rem(-77) $standard-side-margin;
      padding: rem(42) rem(21) 0 rem(42);

      & > * {
        max-width: rem(885);
      }
    }

    @media (min-width: $bp-min-xxlarge) {
      margin-left: calc((100% - #{$max-width-rems}) / 2);
    }

    /* stylelint-disable declaration-no-important */
    @media print {
      margin: 0 !important;
      padding: 0 !important;
    }
    /* stylelint-enable declaration-no-important */
  }

  .container.container--greyband > .media-promo & {
    background-color: $greyband-color;
  }

  .media-promo--uplap & {
    background-color: $white;

    @media (min-width: $bp-min-small-tablet) {
      @include overlap(up, rem(-77));

      margin: 0 0 rem(-77) rem(46);
      padding: rem(21) rem(70) 0 rem(46);
    }

    .container--greyband & {
      background-color: $greyband-color;
    }

    .container--stripe .page-segment__sidebar & {
      @media (min-width: $bp-min-small-tablet) {
        @include overlap(up, rem(-52));

        background-color: $greyband-color;
        margin: 0 0 rem(-26) rem(26);
        padding: rem(26);
      }
    }
  }

  .experience-grid .media-promo--bump-up & {
    padding-top: rem(30);

    @media (min-width: $bp-min-small-tablet) {
      max-width: 100%;
    }
  }

  .media-promo--icon & {
    @media (min-width: $bp-min-small-tablet) {
      margin-left: rem(38);
    }
  }
}

.media-promo__summary {
  @include font(stevie, book);

  font-size: rem(15);
  line-height: 1.733;

  .layout-columns--spacer-margin &:last-child {
    margin-bottom: rem(7);
  }

  .mobile-striper & {
    margin-left: rem(42);

    @media (min-width: $bp-min-small-tablet), print {
      margin-left: 0;
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-size: inherit !important;
    font-weight: inherit;
    line-height: inherit !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
  }
  /* stylelint-enable declaration-no-important */
}

.media-promo__title {
  @include font(stevie, semibold);

  color: $drexel-grey-headline;
  font-size: rem(20);
  line-height: 1.5;
  margin-bottom: rem(7);

  .layout-columns--spacer-margin &:last-child {
    margin-bottom: rem(42);

    @media print {
      margin-bottom: $print-standard-text-whitespace;
    }
  }

  .mobile-striper & {
    margin-left: rem(42);

    @media (min-width: $bp-min-small-tablet), print {
      margin-left: 0;
    }
  }

  .media-promo--uplap & {
    @media (min-width: $bp-min-small-tablet) {
      font-size: rem(36);
      line-height: 1.194;
      margin-top: rem(11);
    }
  }

  .page-segment__sidebar .media-promo--uplap & {
    @media (min-width: $bp-min-small-tablet) {
      font-size: rem(20);
      line-height: 1.35;
    }
  }

  .layout-columns--skinny-gutter & {
    @include font(futura, regular);

    font-size: rem(30);
    line-height: 1.267;
  }

  .layout-columns--4-up & {
    font-size: rem(26);
    line-height: 1.346;
  }

  /* We do things a bit differently if this element is a component in its own right, rather than being a child of the promo grid */
  .container > .media-promo & {
    @media (min-width: $bp-min-small-tablet) {
      @include font(stevie, regular);

      font-size: rem(36);
      line-height: 1.194;
      margin-bottom: rem(21);
    }
  }

  /* stylelint-disable declaration-no-important */
  @media print {
    font-family: "stevie-sans", Arial, sans-serif !important;
    font-size: inherit !important;
  }
  /* stylelint-enable declaration-no-important */
}

.media-promo__video-icon {
  height: rem(57);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: rem(57);

  svg {
    color: rgba($base-background-color, 0.7);
    height: 100%;
    width: 100%;
  }
}

.media-promo__icon {
  align-items: center;
  border: 4px solid currentColor;
  border-right: none;
  border-bottom: none;
  display: flex;
  justify-content: center;
  height: rem(124);
  margin-bottom: rem(17);
  width: rem(124);

  img {
    height: rem(60);
    transition: transform 0.5s;
    width: auto;
  }

  // Only add hover styles if it's a clickable card and JS initialized
  .clickable-card--whole-card:hover &,
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  @media (min-width: $bp-min-small-tablet) {
    margin-bottom: 0;
  }
}

a.media-promo__tag {
  @include font(stevie, bold);

  background-color: $drexel-grey-pale; /* Accessibility placeholder; override in theme SCSS */
  border-bottom: none;
  display: inline-block;
  font-size: rem(12);
  letter-spacing: 1px;
  margin-bottom: rem(21);
  padding: rem(7) rem(18);
  position: relative;
  text-transform: uppercase;
  transition: background-color 0.2s;

  // for extended mobile tap area (accessibility)
  &::before {
    background: rgba(255, 255, 255, 0.01);
    content: "";
    display: block;
    position: absolute;
    top: -6px;
    right: 0;
    bottom: -6px;
    left: 0;
  }

  &:hover,
  &:focus {
    background-color: $black; /* Accessibility placeholder; override in theme SCSS */
    color: $white; /* Accessibility placeholder; override in theme SCSS */
  }

  @media print {
    display: none;
  }
}

.media-promo__video-link {
  margin: rem(28) 0 rem(7);

  @media print {
    display: none;
  }
}

.media-promo__video-link-button {
  @include font(futurab, demi);

  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  font-size: rem(15);
  letter-spacing: 2.25px;
  text-decoration: none;
  text-transform: uppercase;
  justify-content: flex-start;
  width: auto;
}

.media-promo__button-text {
  border-bottom: rem(4) solid transparent;
  border-top: 4px solid transparent; /* bit of a hack to recenter things */
  display: inline-block;
  margin-left: rem(17);

  transition: border-color $standard-transition;
}

/* Animated Play Button */
.media-promo__play {
  display: block;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  border: 3px solid black;
  position: relative;
  transition: transform 0.3s, border-color $standard-transition;
}

.media-promo__play::after,
.media-promo__play::before,
.media-promo__play .media-promo__line {
  transition: height 0.3s, width 0.3s, border-width 0.3s, top 0.3s, left 0.3s,
    transform 0.3s, background-color $standard-transition;

  background-color: black;
  border-radius: 3px;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 25px;
  width: 3px;
  position: absolute;
  top: 7.5px;
  left: 12px;
}

.media-promo__play .media-promo__triangle {
  border-style: solid;
  border-width: 12px 0 12px 21px;
  border-color: transparent transparent transparent black;

  opacity: 1;
  position: absolute;
  top: 8px;
  left: 12px;
  transition: opacity 0.2s, border-color $standard-transition;
}

.media-promo__play::after {
  left: 22px;
  top: 2px;
  transform: rotate(120deg);
}

.media-promo__play::before {
  left: 22px;
  top: 13px;
  transform: rotate(60deg);
}

.in-play-mode .media-promo__play {
  transform: rotate(45deg);
}

.in-play-mode .media-promo__play .media-promo__line {
  height: 31px;
  left: 19px;
  top: 4px;
}

.in-play-mode .media-promo__play::after {
  height: 15px;
  transform: rotate(90deg);
  left: 12px;
  top: 12px;
}

.in-play-mode .media-promo__play::before {
  height: 15px;
  transform: rotate(270deg);
  left: 27px;
  top: 12px;
}

.in-play-mode .media-promo__play .media-promo__triangle {
  opacity: 0;
}
