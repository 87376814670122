.event-meta {
  @include componentize();
  @include component-normalize();

  padding: $base-component-whitespace 0 rem(126);
  position: relative;

  &:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    left: -20px;
    top: 0;
    width: calc(100% + 40px);

    @media (min-width: $bp-min-large) {
      left: -126px;
      width: calc(100% + 246px);
    }
  }

  hr {
    border: 1px solid $base-background-color;
    margin: rem(32) 0;
    width: 100%;
  }

  @media print {
    padding: 0;

    hr,
    &:before {
      display: none;
    }
  }
}

.event-meta__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.event-meta__date-time {
  font-size: rem(21);
  font-weight: 300;
  line-height: 1.556;
  margin-bottom: rem(21);
  width: 100%;

  @media (min-width: $bp-min-small) {
    font-size: rem(27);
  }

  @media (min-width: $bp-min-large) {
    width: 60%;
  }

  @media print {
    width: 100%;
    margin-bottom: $print-standard-listitem-whitespace;
  }
}

.event-meta__calendaring {
  font-weight: 500;
  width: 100%;

  @media (min-width: $bp-min-large) {
    text-align: right;
    width: 40%;
  }
}

.event-meta__cta {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: $bp-min-small) {
    margin-bottom: rem(11);
    width: auto;
  }
}

.event-meta__location-map-link {
  margin: rem(21) 0;

  @media (min-width: $bp-min-small) {
    margin-bottom: 0;
  }
}

.event-meta__location {
  margin-bottom: rem(21);

  @media (min-width: $bp-min-small) {
    margin-bottom: rem(11);
  }

  @media print {
    margin-bottom: 0;
  }
}
